import React, { useState, useEffect } from "react";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { isBlank } from "../../utils/utils.js";
import DescriptionIcon from "@mui/icons-material/Description";
import AiCompanionService from "../../services/AiCompanionService.js";
import TextField from "@mui/material/TextField";
import PromptsService from "../../services/PromptsService.js";
import ISO6391 from 'iso-639-1';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const promptsService = new PromptsService();
const aiCompanionService = new AiCompanionService();

const useStyles = makeStyles()((theme) => ({
   root: {
      width: "100%",
   },
   paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
   },
   button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
   },
   textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
   },
   flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(10),
   },
   flexBetweenTextField: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(4),
   }
}));

export default function PanelTools(props) {
   const { classes } = useStyles();

   const { t, showSpinner, openSnackbar } = props;

   const [to3WordsLanguages, setTo3WordsLanguages] = useState([]);

   useEffect(() => {
      showSpinner(true);

      let promises = [
         promptsService.fetchAllPromptsByType('review/to-3-words'),
      ];
  
      Promise.all(promises)
         .then(results => {
            setTo3WordsLanguages(results[0].data.hits.map(p => p.lang).sort());
         })
         .catch(() => {
            setTo3WordsLanguages([]);
            openSnackbar("error", t("react.codification.error.while.loading"));
         }).finally(() => {
            showSpinner(false);
         });
   }, []);

   // Reviews to R3M input file
   const [reviewsInputFile, setReviewsInputFile] = useState({ name: "" });
   const [reviewsInputFileLang, setReviewInputFileLang] = useState("");

   const handleSelectUploadFileWithReviews = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setReviewsInputFile(event.target.files[0]);
         } else {
            openSnackbar("error", t("react.codification.import.library.error.unsupported"));
         }
      }
   };

   const handleUploadFileWithReviews = (event) => {
      showSpinner(true);
      event.preventDefault();
      let formData = new FormData();
      formData.append("uploadedFile", reviewsInputFile);
      aiCompanionService
         .postReviewToR3m(reviewsInputFileLang, formData)
         .then(() => {
            openSnackbar("success", t("react.codification.toolbar.button.import.started"));
         })
         .catch(() => {
            openSnackbar("error", t("react.codification.toolbar.button.import.failed"));
         })
         .finally(() => {
            showSpinner(false);
            setReviewsInputFile({ name: "" });
            setReviewInputFileLang("");
         });
   };

   return (
      <Grid item xs={12}>
         <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
               <DescriptionIcon style={{ marginRight: "10px" }} />
               <Typography className={classes.heading}>
                  {t("react.aitools.panel.tools.r3mreviews.title")}
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                     <Typography variant="body2" style={{ fontStyle: "italic" }}>
                        {t("react.aitools.panel.tools.r3mreviews.desc")}
                     </Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                     <Grid item xs={4}>
                        <FormControl fullWidth>
                           <TextField
                              select
                              label="Langue"
                              id="reviewsInputFileLang"
                              value={reviewsInputFileLang}
                              data-testid="reviewsInputFileLang"
                              onChange={(e) => setReviewInputFileLang(e.target.value)}
                              helperText={t("react.aitools.panel.tools.r3mreviews.file.select.lang")}
                           >
                              {to3WordsLanguages.map((language) => {
                                 return (
                                    <MenuItem key={language} value={language}>
                                       {ISO6391.getNativeName(language.toLowerCase())}
                                    </MenuItem>
                                 );
                              })}
                           </TextField>
                        </FormControl>
                     </Grid>
                  </Grid>
                  <Grid item xs={6}>
                     <Typography>{t("react.aitools.panel.tools.r3mreviews.file.select.label")}</Typography>
                     <FormControl fullWidth>
                        <Input
                           disabled
                           id="reviewsInputFileName"
                           value={reviewsInputFile.name}
                           className={classes.textField}
                           placeholder={t("react.thematization.edit.selectfiles")}
                           fullWidth
                           endAdornment={
                              <InputAdornment position="end">
                                 <label htmlFor="contained-button-file_5">
                                    <Button variant="contained" color="primary" component="span">
                                       {t("react.dashboard.import.image.popup.chose")}
                                    </Button>
                                 </label>
                              </InputAdornment>
                           }
                        />
                        <input
                           key={Date.now()}
                           onChange={handleSelectUploadFileWithReviews}
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           style={{ display: "none" }}
                           id="contained-button-file_5"
                           data-testid="reviewsInputFile"
                           type="file"
                        />
                     </FormControl>
                     <Button
                        variant="contained"
                        className={classes.button}
                        startIcon={<AutoAwesomeIcon />}
                        data-testid="buttonReviews"
                        onClick={handleUploadFileWithReviews}
                        disabled={isBlank(reviewsInputFileLang) || isBlank(reviewsInputFile.name)}
                     >
                        {t("react.project.edit.collectforms.button.send")}
                     </Button>
                  </Grid>
               </Grid>
            </AccordionDetails>
         </Accordion>
      </Grid>
   );
}
