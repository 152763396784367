import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import DialogTitleWithCloseIcon from "../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Checkbox, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Toolbar, Tooltip, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {toolbarStyles} from "../../../common";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { isBlank } from "../../../utils/utils";
import InputAdornment from "@mui/material/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import ThematizationService from "../../../services/ThematizationService";
import { LoadData } from "../../../Constants";
import LinearProgress from "@mui/material/LinearProgress";
import { v4 as uuidv4 } from 'uuid';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const thematizationService = new ThematizationService();

const useStyles = makeStyles()((theme) => ({
	container: {
		width: "100%",
	},
	dialogContainer: {
		minHeight: "700px",
	},
	paper: {
		flexGrow: 1,
		width: '100%',
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
	},
	itemContainer: {
		width: "100%",
		height: "100%",
	},
	subItemContainer: {
		marginLeft: '35px',
		borderLeft: `2px solid ${theme.palette.divider}`,
	},
	scrollableList: {
		maxHeight: "700px",
		overflowY: "auto",
		paddingRight: theme.spacing(1),
	},
	listItem: {
		display: "flex",
		alignItems: "center",
		height: '44px',
	},
	sublistItem: {
		paddingLeft:'40px',
		display: "flex",
		alignItems: "center",
		height: '44px',
	},
	editField : {
		height: '44px',
		minWidth: '30%',
		maxWidth: "80%",
	},
	themeId: {
		marginLeft: '8px',
		marginRight: '8px',
		maxWidth: '100px',
		color: 'gray',
	},
	toolbar: {
		position: "sticky",
		top: 0,
		zIndex: 99,
		background: "whitesmoke",
	},
	divider: {
		height: '25px',
		margin: '0px 5px 0px 5px'
	}
}));

const OTHERTHEMELEVEL = 9000;
const OTHERTHEMEOTHERLEVEL = 9099;

export default function CodePlanDialog(props) {
	const { t, openState, callbackOnclose, openSnackbar, setLoadAllData, setOpenThematizationDialog,
		setGenerateCodePlanDialogOpen, editThematization, setImportDialogOpen, 
	 } = props;

	const { classes } = useStyles();
	const { classes: toolbarClasses } = toolbarStyles();

	const [codePlan, setCodePlan] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedSubItems, setSelectedSubItems] = useState([]);
	const [newlyCreatedItems, setNewlyCreatedItems] = useState([]);
	const [expandedIds, setExpandedIds] = useState(new Set());

	const [renameIdentifier, setRenameIdentifier] = useState([null, null]);
	const [renameText, setRenameText] = useState('');
	const [mouseOverIdentifier, setMouseOverIdentifier] = useState(null);

	const [needToSave, setNeedToSave] = useState(false);
	const [loadData, setLoadData] = useState(LoadData.Load);

	useEffect(() => {
		if (!openState) {
			setLoadData(LoadData.Load);
			return;
		}

		if (loadData !== LoadData.Load) return;

		setSelectedItems([]);
		setNewlyCreatedItems([]);
		setSelectedSubItems([]);
		setNeedToSave(false);
		setLoadData(LoadData.Loading);
		thematizationService.getThematization(editThematization.id)
			.then(response => {
				response.data.themes.forEach((item) => {
					item.uuid = uuidv4();
					if (item.subThemes) {
						item.subThemes.forEach((subItem) => {
							subItem.uuid = uuidv4();
						});
					}
				});
				setCodePlan(response.data.themes);
			})
			.catch(err => {
				openSnackbar('error', t("react.aitools.codeplan.error.loading"));
			}).finally(() => {
			setLoadData(LoadData.Loaded);
		});
	}, [loadData, openState]);

	const handleConfirm = () => {
		callbackOnclose();
		let payload = editThematization;
		payload.themes = codePlan.map(({ tempId, ...rest }) => rest);
		setLoadAllData(LoadData.Loading);
		if (codePlan) {
			thematizationService.saveThematization(payload)
				.then(response => {
				})
				.catch(err => {
					openSnackbar('error', t("react.aitools.thematization.load.error"));
				}).finally(() => {
				setLoadAllData(LoadData.Load);
			});
		}
	};

	const handleGenerateCodePlan = (e) => {
		setGenerateCodePlanDialogOpen(true);
	};

	const handleThematizeVerbatimsFromCodePlan = () => {
		setOpenThematizationDialog(true);
	};

	const updateLevel = (updatedCodePlan) => {
		updatedCodePlan.forEach((item, index) => {
			if (item) {
				if (!item.isDefaultOther) {
					item.level = 100 + index * 100;
				}
				if (item.subThemes) {
					item.subThemes.forEach((subItem, subIndex) => {
						if (!subItem.isDefaultOther){
							subItem.level = item.level + subIndex + 1;
						}
					});
				}
			}
		});
	};

	const handleDownward = () => {
		if (selectedItems.length > 0) {
			handleMoveItemsDown();
		} else if (selectedSubItems.length > 0) {
			handleMoveSubItemsDown();
		}
	};

	const handleUpward = () => {
		if (selectedItems.length > 0) {
			handleMoveItemsUp();
		} else if (selectedSubItems.length > 0) {
			handleMoveSubItemsUp();
		}
	};

	function isMovable(direction, itemType) {
		if (itemType === 'theme') {
			return selectedItems.length > 0 && selectedItems.every(itemId => {
				const themeIndex = codePlan.findIndex(item => item.uuid === itemId);
				const lastThemeIndex = codePlan.length - 1;
				const adjacentIndex = direction === 'down' ? themeIndex + 1 : themeIndex - 1;
				const isFixed = adjacentIndex >= 0 && adjacentIndex <= lastThemeIndex && codePlan[adjacentIndex].isDefaultOther;
				return direction === 'down'
					? themeIndex < lastThemeIndex && !isFixed
					: themeIndex > 0 && !isFixed;
			});
		}
		if (itemType === 'subtheme') {
			return selectedSubItems.length > 0 && selectedSubItems.every(selectedSubItem => {
				const parentThemeIndex = codePlan.findIndex(item => item.subThemes.some(subItem => subItem.uuid === selectedSubItem.id));
				const subThemes = codePlan[parentThemeIndex]?.subThemes || [];
				const subThemeIndex = subThemes.findIndex(subItem => subItem.uuid === selectedSubItem.id);
				const lastSubThemeIndex = subThemes.length - 1;
				const adjacentSubIndex = direction === 'down' ? subThemeIndex + 1 : subThemeIndex - 1;
				const isDefaultOther = adjacentSubIndex >= 0 && adjacentSubIndex <= lastSubThemeIndex && subThemes[adjacentSubIndex].isDefaultOther;
				return direction === 'down'
					? subThemeIndex < lastSubThemeIndex && !isDefaultOther
					: subThemeIndex > 0 && !isDefaultOther;
			});
		}
		return false;
	}

	const handleMoveItemsUp = () => {
		const newCodePlan = [...codePlan];
		const impactedItems = newCodePlan.filter(item => selectedItems.includes(item.uuid));

		if (impactedItems.length === 0) return;

		impactedItems.forEach(element => {
			const currentIndex = newCodePlan.findIndex(item => item.uuid === element.uuid);
			if (currentIndex > 0 && !element.isDefaultOther && !newCodePlan[currentIndex - 1].isDefaultOther) {
				const movedItem = newCodePlan[currentIndex];
				newCodePlan.splice(currentIndex, 1);
				newCodePlan.splice(currentIndex - 1, 0, movedItem);
			}
		});

		updateLevel(newCodePlan);
		setCodePlan(newCodePlan);
		setNeedToSave(true);
	};

	const handleMoveItemsDown = () => {
		const newCodePlan = [...codePlan];
		const impactedItems = newCodePlan.filter(item => selectedItems.includes(item.uuid));

		if (impactedItems.length === 0) return;

		for (let i = impactedItems.length - 1; i >= 0; i--) {
			const element = impactedItems[i];
			const currentIndex = newCodePlan.findIndex(item => item.uuid === element.uuid);
			if (currentIndex < newCodePlan.length - 1 && !element.isDefaultOther && !newCodePlan[currentIndex + 1].isDefaultOther) {
				const movedItem = newCodePlan[currentIndex];
				newCodePlan.splice(currentIndex, 1);
				newCodePlan.splice(currentIndex + 1, 0, movedItem);
			}
		}

		updateLevel(newCodePlan);
		setCodePlan(newCodePlan);
		setNeedToSave(true);
	};

	const handleMoveSubItemsUp = () => {
		const newCodePlan = [...codePlan];
		const impactedSubItems = newCodePlan.flatMap(item => item.subThemes.filter(subItem => selectedSubItems.some(selectedSubItem => selectedSubItem.id === subItem.uuid)));

		if (impactedSubItems.length > 0) {
			impactedSubItems.forEach(element => {
				const parentItem = newCodePlan.find(item => item.subThemes.some(sub => sub.uuid === element.uuid));
				const currentIndex = parentItem.subThemes.findIndex(sub => sub.uuid === element.uuid);
				if (currentIndex > 0 && !element.isDefaultOther) {
					const movedSubItem = parentItem.subThemes[currentIndex];
					parentItem.subThemes.splice(currentIndex, 1);
					parentItem.subThemes.splice(currentIndex - 1, 0, movedSubItem);
				}
			});
		}

		updateLevel(newCodePlan);
		setCodePlan(newCodePlan);
		setNeedToSave(true);
	};

	const handleMoveSubItemsDown = () => {
		const newCodePlan = [...codePlan];
		const impactedSubItems = newCodePlan.flatMap(item => item.subThemes.filter(subItem => selectedSubItems.some(selectedSubItem => selectedSubItem.id === subItem.uuid)));

		if (impactedSubItems.length > 0) {
			for (let i = impactedSubItems.length - 1; i >= 0; i--) {
				const element = impactedSubItems[i];
				const parentItem = newCodePlan.find(item => item.subThemes.some(sub => sub.uuid === element.uuid));
				const currentIndex = parentItem.subThemes.findIndex(sub => sub.uuid === element.uuid);

				if (currentIndex < parentItem.subThemes.length - 1
					&& !element.isDefaultOther
					&& !parentItem.subThemes[currentIndex + 1].isDefaultOther) {
					const movedSubItem = parentItem.subThemes[currentIndex];
					parentItem.subThemes.splice(currentIndex, 1);
					parentItem.subThemes.splice(currentIndex + 1, 0, movedSubItem);
				}
			}
		}

		updateLevel(newCodePlan);
		setCodePlan(newCodePlan);
		setNeedToSave(true);
	};

	const handleCreateItem = () => {
		const newCodePlan = [...codePlan];
		const isDefaultOtherIndex = newCodePlan.findIndex(item => item.isDefaultOther);

		const newItem = {
			uuid: uuidv4(),
			id: 0,
			name: t("react.aitools.codeplan.new.theme", { lng: editThematization.lang.toLowerCase() }),
			subThemes: [],
			isDefaultOther: false,
		};

		if (
			newCodePlan.length !== 0 &&
			isDefaultOtherIndex < newCodePlan.length &&
			newCodePlan[isDefaultOtherIndex]?.isDefaultOther
		) {
			newCodePlan.splice(newCodePlan.length - 1, 0, newItem);
		} else {
			newCodePlan.push(newItem);
		}

		updateLevel(newCodePlan);
		if (!expandedIds.has(newItem.uuid)) {
			handleExpand(newItem);
		}
		setCodePlan(newCodePlan);
		setNewlyCreatedItems([newItem.uuid]);
		setNeedToSave(true);

		setTimeout(() => {
			setNewlyCreatedItems(prev => prev.filter(id => id !== newItem.uuid));
		}, 5000);
	};

	const handleCreateOtherItem = () => {
		const newCodePlan = [...codePlan];

		const newItem = {
			uuid: uuidv4(),
			id: 0,
			name: t("react.aitools.codeplan.new.other.theme", { lng: editThematization.lang.toLowerCase() }),
			level: OTHERTHEMELEVEL,
			subThemes: [],
			isDefaultOther: true
		};

		newCodePlan.push(newItem);

		updateLevel(newCodePlan);
		if (!expandedIds.has(newItem.uuid)) {
			handleExpand(newItem);
		}
		setCodePlan(newCodePlan);
		setNeedToSave(true);

		setTimeout(() => {
			setNewlyCreatedItems(prev => prev.filter(id => id !== newItem.uuid));
		}, 5000);
	};

	const handleCreateSubItem = (itemIndex, item) => {
		const newCodePlan = [...codePlan];
		const currentSubThemes = newCodePlan[itemIndex].subThemes;
		const isDefaultOtherIndex = currentSubThemes.findIndex(item => item.isDefaultOther);

		const newSubItem = {
			uuid: uuidv4(),
			id: 0,
			themeId: item.id,
			name: t("react.aitools.codeplan.new.subtheme", { lng: editThematization.lang.toLowerCase() }),
			isDefaultOther: false,
		};

		if (
			currentSubThemes.length !== 0 &&
			isDefaultOtherIndex < currentSubThemes.length &&
			currentSubThemes[isDefaultOtherIndex]?.isDefaultOther
		) {
			currentSubThemes.splice(currentSubThemes.length - 1, 0, newSubItem);
		} else {
			currentSubThemes.push(newSubItem);
		}

		updateLevel(newCodePlan);
		if (!expandedIds.has(item.uuid)) {
			handleExpand(item);
		}
		setCodePlan(newCodePlan);
		setNewlyCreatedItems([newSubItem.uuid]);
		setNeedToSave(true);

		setTimeout(() => {
			setNewlyCreatedItems(prev => prev.filter(id => id !== newSubItem.uuid));
		}, 5000);
	};

	const handleCreateOtherSubItem = (itemIndex, item) => {
		const newCodePlan = [...codePlan];

		const newSubItem = {
			uuid: uuidv4(),
			id: 0,
			themeId: item.id,
			name: (item.level + 99) === OTHERTHEMEOTHERLEVEL
				? t("react.aitools.codeplan.new.unclassified", { lng: editThematization.lang.toLowerCase() })
				: t("react.aitools.codeplan.new.other", { lng: editThematization.lang.toLowerCase() }),
			level: item.level + 99,
			isDefaultOther: true
		};

		newCodePlan[itemIndex].subThemes.push(newSubItem);

		updateLevel(newCodePlan);
		if (!expandedIds.has(item.uuid)) {
			handleExpand(item);
		}
		setCodePlan(newCodePlan);
		setNeedToSave(true);

		setTimeout(() => {
			setNewlyCreatedItems(prev => prev.filter(id => id !== newSubItem.uuid));
		}, 5000);
	};

	const handleToggleSelectItem = (id, themeId, type) => {
		if (type === 'sub') {
			setSelectedSubItems((prev) => {
				const newSelectedSubItems = prev.filter(item => item.themeId === themeId);
				if (newSelectedSubItems.some(item => item.id === id)) {
					return newSelectedSubItems.filter(item => item.id !== id);
				} else {
					return [...newSelectedSubItems, { id, themeId }];
				}
			});
			setSelectedItems([]);
		}
		else {
			setSelectedItems((prev) => prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]);
			setSelectedSubItems([]);
		}
	};

	const handleDeleteSelectedItems = () => {
		const newCodePlan = codePlan.filter(item => !selectedItems.includes(item.uuid));
		newCodePlan.forEach(item => {item.subThemes = item.subThemes.filter(subItem => !selectedSubItems.some(selectedSubItem => selectedSubItem.id === subItem.uuid));});

		updateLevel(newCodePlan);
		setCodePlan(newCodePlan);
		setSelectedItems([]);
		setSelectedSubItems([]);
		setNeedToSave(true);
	};

	const handleMouseOver = (identifier, type) => {
		setMouseOverIdentifier([identifier, type]);
	};

	const handleStartRename = (event, identifier, initalText, type) => {
		event.preventDefault();
		setRenameIdentifier([identifier, type]);
		setRenameText(initalText);
		setMouseOverIdentifier([undefined, undefined]);
	};

	const handleValidRename = (event, type, idP) => {
		event.preventDefault();
		const newCodePlan = [...codePlan];

		newCodePlan.forEach((item) => {
			if ((item.uuid === idP)) {
				item.name = renameText;
			} else {
				const subItem = item.subThemes.find(sub => sub.uuid === idP);
				if (subItem) {
					subItem.name = renameText;
				}
			}
		});

		setCodePlan(newCodePlan);
		setRenameIdentifier([undefined, undefined]);
		setRenameText('');
		setMouseOverIdentifier(null);
		setNeedToSave(true);
	};

	const handleExpand = (item) => {
		let newExpandedIds = new Set(expandedIds);
		newExpandedIds.add(item.uuid);
		setExpandedIds(newExpandedIds);
	};

	const handleFold = (item) => {
		let newExpandedIds = new Set(expandedIds);
		newExpandedIds.delete(item.uuid);
		setExpandedIds(newExpandedIds);
	};

	const printName = (item) => {
		const isEditing = renameIdentifier[0] === (item.uuid) && renameIdentifier[1] === 'name';
		const isHovered = mouseOverIdentifier && mouseOverIdentifier[0] === (item.uuid) && mouseOverIdentifier[1] === 'name';

		return (
			<>
				{isEditing ? (
					<TextField
						type="text"
						value={renameText}
						className={classes.editField}
						onChange={e => setRenameText(e.target.value)}
						fullWidth={true}
						error={isBlank(renameText)}
						autoFocus
						onKeyDown={(e) => {
							if (e.key === 'Enter' && !isBlank(renameText)) {
								handleValidRename(e, 'name', item.uuid);
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Tooltip title={t("react.datamap.rename.save")}><span>
										<IconButton
											onClick={(e) => handleValidRename(e, 'name', item.uuid)}
											disabled={isBlank(renameText)}
										>
											<DoneIcon style={{ color: !isBlank(renameText) ? '#66bb6a' : '#eee' }} />
										</IconButton></span>
									</Tooltip>
								</InputAdornment>
							)
						}}
					/>
				) : (
					<span className={classes.listItem}>
                    {item.name}
						{isHovered && (
							<Tooltip title={t('react.datamap.rename.tooltip')} key={"item-id -" + item.uuid}>
								<IconButton
									onClick={(e) => handleStartRename(e, item.uuid, item.name, 'name')}
									style={{ marginLeft: '8px' }}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}
                </span>
				)}
			</>
		);
	};

	const renderCodePlan = () => {
		if (!codePlan) return null;

		return codePlan.map((item, itemIndex) => (
			<React.Fragment key={item.themeId + '-' + itemIndex}>
				<List className={classes.itemContainer} dense={true}>
					<ListItem
						style={{ backgroundColor: newlyCreatedItems.includes(item.uuid) ? '#e0f7fa' : 'inherit' }}
						onMouseOver={() => handleMouseOver(item.uuid, 'name')}
						onMouseOut={() => setMouseOverIdentifier(null)}
						className={classes.listItem}
					>
						{!expandedIds.has(item.uuid) && <Tooltip title={t('react.project.tab.filters.deploy.tooltip')}>
							<IconButton onClick={() => handleExpand(item)}>
								<KeyboardArrowRightIcon />
							</IconButton>
						</Tooltip>}
						{expandedIds.has(item.uuid) && <Tooltip title={t('react.project.tab.filters.close.tooltip')}>
							<IconButton onClick={() => handleFold( item)}>
								<KeyboardArrowDownIcon />
							</IconButton>
						</Tooltip>}
						<Checkbox
							checked={selectedItems.includes(item.uuid)}
							onChange={() => handleToggleSelectItem(item.uuid)}
							disabled={item.isDefaultOther}
						/>
						<Typography variant="body2" className={classes.themeId}>
							{item.level}
						</Typography>
						<ListItemText primary={printName(item)} />
						<ListItemSecondaryAction>
							{item.level !== OTHERTHEMELEVEL && (
								<Tooltip title={t('react.aitools.codeplan.create.subtheme')}>
									<IconButton 
										onClick={() => handleCreateSubItem(itemIndex, item)}
										disabled={editThematization.state === 'processing' || codePlan[itemIndex].subThemes.length >= 90}
									>
										<AddIcon />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip title={t('react.aitools.codeplan.create.subtheme.other')}><span>
								<IconButton
									onClick={() => handleCreateOtherSubItem(itemIndex, item)}
									disabled={editThematization.state === 'processing' || codePlan[itemIndex].subThemes.some(subitem => subitem.isDefaultOther)}
								>
									<PlaylistAddIcon />
								</IconButton></span>
							</Tooltip>
						</ListItemSecondaryAction>
					</ListItem>
					<Collapse in={expandedIds.has(item.uuid)} unmountOnExit>
						{item.subThemes.map((subItem, index) => (
							<div key={subItem.themeId + '-' + index} className={classes.subItemContainer}>
								<ListItem
									style={{ backgroundColor: newlyCreatedItems.includes(subItem.uuid) ? '#e0f7fa' : 'inherit' }}
									onMouseOver={() => handleMouseOver(subItem.uuid, 'name')}
									onMouseOut={() => setMouseOverIdentifier(null)}
									className={classes.sublistItem}
								>
									<Checkbox
										checked={selectedSubItems.some(selectedSubItem => selectedSubItem.id === subItem.uuid)}
										onChange={() => handleToggleSelectItem(subItem.uuid, subItem.themeId, "sub")}
										disabled={subItem.isDefaultOther}
									/>
									<Typography variant="body2" className={classes.themeId}>
										{subItem.level}
									</Typography>
									<ListItemText primary={printName(subItem)} />
								</ListItem>
							</div>
						))}
					</Collapse>
				</List>
				{itemIndex !== codePlan.length - 1 && (
					<Divider variant="inset" component="li" />
				)}
			</React.Fragment>
		));
	};

	if (!openState) return null;

	return (
		<Dialog open={openState} maxWidth="lg" fullWidth={true}>
			<DialogTitleWithCloseIcon
				title={t("react.aitools.codeplan.title") + " - " + editThematization.name}
				callbackOnclose={callbackOnclose}
			/>
			<DialogContent>
				{loadData !== LoadData.Loaded ? (
					<Paper className={classes.dialogContainer}>
						<LinearProgress />
					</Paper>
				) : (
					<Paper className={classes.dialogContainer}>
						<Grid item xs={12} className={classes.toolbar}>
							<Toolbar className={toolbarClasses.root}>
								<Typography variant="h6" className={toolbarClasses.title}>
									{t("react.aitools.codeplan.subtitle")}
								</Typography>

								<Tooltip title={t("react.aitools.thematization.from.codeplan")}><span>
									<IconButton
										onClick={handleThematizeVerbatimsFromCodePlan}
										disabled={editThematization.state !== 'completed' || needToSave}
									>
										<FormatListBulletedIcon/>
									</IconButton></span>
								</Tooltip>

								<Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />

								<Tooltip title={t('react.aitools.thematization.import.file')}>
									<IconButton 
										onClick={() => setImportDialogOpen(true)}
										disabled={editThematization.state === 'processing' || needToSave}
									>
										<UploadFileIcon/>
									</IconButton>
								</Tooltip>
								<Tooltip title={t('react.aitools.thematization.generate.codeplan')}>
									<IconButton 
										onClick={(e) => handleGenerateCodePlan(e)}
										disabled={editThematization.state === 'processing' || needToSave}
										>
										<AutoFixHighIcon/>
									</IconButton>
								</Tooltip>

								<Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />

								<Tooltip title={t("react.aitools.codeplan.create.theme")}><span>
									<IconButton 
										onClick={handleCreateItem} 
										disabled={editThematization.state === 'processing' || codePlan.length >= 90}
									>
										<Add />
									</IconButton></span>
								</Tooltip>
								<Tooltip title={t('react.aitools.codeplan.create.theme.other')}><span>
									<IconButton
										onClick={() => handleCreateOtherItem()}
										disabled={editThematization.state === 'processing' || codePlan.some(item => item.isDefaultOther)}
									>
										<PlaylistAddIcon />
									</IconButton></span>
								</Tooltip>

								<Tooltip title={t("react.collectors.datamap.downward")}><span>
									<IconButton
										onClick={handleDownward}
										disabled={editThematization.state === 'processing' || !isMovable('down', 'theme') && !isMovable('down', 'subtheme')}
									>
										<ArrowDownwardIcon />
									</IconButton></span>
								</Tooltip>
								<Tooltip title={t("react.collectors.datamap.upward")}><span>
									<IconButton
										onClick={handleUpward}
										disabled={editThematization.state === 'processing' || !isMovable('up', 'theme') && !isMovable('up', 'subtheme')}
									>
										<ArrowUpwardIcon />
									</IconButton></span>
								</Tooltip>
								<Tooltip title={t("react.button.delete")}><span>
									<IconButton 
										onClick={handleDeleteSelectedItems} 
										disabled={editThematization.state === 'processing' || selectedItems.length === 0 && selectedSubItems.length === 0}
									>
										<DeleteIcon />
									</IconButton></span>
								</Tooltip>
							</Toolbar>
						</Grid>
						<List className={classes.scrollableList}>
							{codePlan.length !== 0 ? (
								renderCodePlan()
							) : (
								<List style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
									<Typography>{t("react.aitools.codeplan.create.first.theme")}</Typography>
									<Button onClick={handleCreateItem}>
										<Add />
										<Typography>{t("react.aitools.codeplan.create.theme")}</Typography>
									</Button>
								</List>
							)}
						</List>
					</Paper>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={callbackOnclose} color="primary" startIcon={<CloseIcon />} variant={"outlined"}>
					{t("react.button.cancel")}
				</Button>
				<Button onClick={handleConfirm} color="primary" startIcon={<SaveIcon />} variant={"outlined"} disabled={loadData !== LoadData.Loaded || !needToSave}>
					{t("react.button.save")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}