import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionR3m from './QuestionR3m';
import Introduction from './Introduction';
import ThankYou from './ThankYou';
import EditGroup from './EditGroup';
import HotSpot from './HotSpot';
import Text from './Text';
import ProfileQuestion from './ProfileQuestion';
import OpenQuestion from './OpenQuestion';
import BatteryOfItems from './BatteryOfItems';
import PopupGroupsOfBlocks from './PopupGroupsOfBlocks';
import Params from './Params';
import ToolBar from './ToolBar';
import ListOfBlocks from './ListOfBlocks';
import MenuConditions from './MenuConditions';
import { AuthService } from '../../services/AuthService';
import SurveysService from '../../services/SurveysService';
import UserService from '../../services/UserService';
import { LoadData } from '../../Constants.js'
import LinearProgress from '@mui/material/LinearProgress';
import { SurveyContext } from './context';
import { indexOf, isBlank, getMimeType } from '../../utils/utils';
import { extractMinMaxOfInterval, extractListOfNumerics, isBlankHtml, IMAGE_WIDTH_AUTO, computeNextElementRef } from '../../utils/surveysUtils';
import ImageIcon from '@mui/icons-material/Image';
import { v4 as uuidv4 } from 'uuid';
import {Helmet} from "react-helmet";
import ConditionService from '../../services/ConditionService';
import TipsHelper from './TipsHelper.js';

const surveysService = new SurveysService();
const conditionService = new ConditionService();
const userService = new UserService();

const useStyles = makeStyles()(theme => ({
    marginBottomType: {
        marginBottom: '30px',
    },
    paper: {
        padding: '20px'
    },
    titleForm: {
        paddingTop: 10,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    disableElement: {
        color: 'grey',
    },
    elementsForm: {
        cursor: 'pointer',
        marginTop: 8
    },
    marginTopFormMenu: {
        marginTop: 10,
        maxWidth: '250px',
    },
    marginTopForm: {
        marginTop: 10,
    },
    marginParams: {
        marginBottom: 20,
        padding: 10,
    },
    formName: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    languageSelector: {
        marginBottom: theme.spacing(1),
        width: 100
    },
    paramButton: {
        padding: 10,
        width: '100%',
    },
    containerSticky: {
        position: 'sticky',
        top: '8em',
        zIndex: 1000,
        width: 900,
    },
    listItemIcon: {
        minWidth: '35px',
    },
    questionProfileIndex: {
        marginLeft: "5px",
        color: "#BBB",
        fontStyle: "italic"
    },
    styleGroups: {
        paddingTop: 5, 
        paddingBottom: 5, 
        paddingLeft: 20, 
        borderTop: '1px dashed #ddd'
    },
    listOverflow: {
        height: 'calc(100vh - 250px)', 
        overflowY: 'scroll'
    }
}));

export const isBlockInMiddleOfGroups = (allBlocks) => {
    var hasGroup = [];
    var hasNotGroup = []
    for (var i = 0; i < allBlocks.length; i++) {
        if (allBlocks[i].configuration.groupId !== 0) {
            hasGroup.push(i)
        } else {
            hasNotGroup.push(i)
        }
    }
    var minValueHasGroup = Math.min(...hasGroup);
    var maxValueHasGroup = Math.max(...hasGroup);
    return hasNotGroup.filter((nombre) => nombre > minValueHasGroup && nombre < maxValueHasGroup)
}

export default function Surveys(props) {

    const { formIdParameter } = useParams();

    const {
        t,
        preferredLanguage,
        openSnackbar,
        showSpinner
    } = props;

    const { classes } = useStyles();

    const [formConfiguration, setFormConfiguration] = useState({});
    const [blocksForm, setBlocksForm] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedIndexGroup, setSelectedIndexGroup] = useState(-1);
    const [mediaFiles, setMediaFiles] = useState({});
    const [conditionners, setConditionners] = useState([]);
    const [openPopupGroupsOfBlocks, setOpenPopupGroupsOfBlocks] = useState(false);

    const [needToSave, setNeedToSave] = useState(false);
    const [nbNotifications, notifyStructuralChange] = useReducer(x => x + 1, 0);

    const [allUsers, setAllUsers] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);

    const [loadData, setLoadData] = useState(LoadData.Load);

    useEffect(() => {
        if (loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        var promises = [];

        var usersDataIdx = -1;
        if (AuthService.isAdmin()) {
            usersDataIdx = promises.push(userService.searchUsers(0, 500, "lastname", "asc", "", false, false)) - 1;
        }

        var formDataIdx = -1;
        var conditionnersDataIdx = -1;
        if (formIdParameter > 0) {
            formDataIdx = promises.push(surveysService.fetchForm(formIdParameter)) - 1;
            conditionnersDataIdx = promises.push(conditionService.fetchConditionners(formIdParameter)) - 1;
        }
    
        Promise.all(promises)
            .then(function (results) {
                setAllUsers(usersDataIdx >= 0 ? results[usersDataIdx].data.hits : []);
                setAllCustomers(usersDataIdx >= 0 ? results[usersDataIdx].data.customers : [])

                if (formIdParameter > 0) {
                    var result = results[formDataIdx];
                    setFormConfiguration(result.data);
                    let allBlocks = result.data.blocks;
                    var allBlocksNew = [];
                    var thankYou = undefined;
                    var mediaFilesPromises = [];

                    for (var i = 0; i < allBlocks.length; i++) {
                        var component = createBlock(allBlocks[i].type, allBlocks[i], true);
                        if (allBlocks[i].type === "thankyou") {
                            thankYou = component;
                        } else if (allBlocks[i].type === "introduction") {
                            allBlocksNew.splice(0, 0, component);
                        } else {
                            allBlocksNew.push(component);
                        }
                        if (allBlocks[i].imageId > 0) {
                            mediaFilesPromises.push(surveysService.getFormFileUrl(formIdParameter, allBlocks[i].imageId));
                        } 
                        if (allBlocks[i].type === "battery") {
                            allBlocks[i].battery.items.forEach(it => {                             
                                if (it.imageId !== 0) {
                                    mediaFilesPromises.push(surveysService.getFormFileUrl(formIdParameter, it.imageId));
                                }
                            })
                            allBlocks[i].battery.answers.forEach(an => {                             
                                if (an.imageId !== 0) {
                                    mediaFilesPromises.push(surveysService.getFormFileUrl(formIdParameter, an.imageId));
                                }
                            })
                        }
                        if (allBlocks[i].type === "question") {
                            allBlocks[i].question.answers.forEach(it => {                             
                                if (it.imageId !== 0) {
                                    mediaFilesPromises.push(surveysService.getFormFileUrl(formIdParameter, it.imageId));
                                }
                            })
                        }
                    }

                    if (thankYou !== undefined) {
                        allBlocksNew.push(thankYou);
                    }

                    if (mediaFilesPromises.length !== 0) {
                        Promise.all(mediaFilesPromises).then(results => {
                            const newfiles = { ...mediaFiles };
                            results.forEach(res => {
                                newfiles[res.id] = res.url;
                            })
                            setMediaFiles(newfiles);
                        });
                    }

                    setBlocksForm(allBlocksNew);

                    // affect an uuid for manipulations
                    var newconditionners = results[conditionnersDataIdx].data;
                    newconditionners.forEach(c => c.uuid = uuidv4())
                    setConditionners(newconditionners);
                    setNeedToSave(false);
                } else {
                    resetAllFields(false, true);
                }
            })
            .catch(err => {
                openSnackbar('error', t("react.error.fetch.message"));
                resetAllFields();
            }).finally(() => {
                setLoadData(LoadData.Loaded);
            });
    }, [loadData])

    const resetAllFields = (genericFields = true, surveyFields = true) => {
        if(genericFields) {
            setAllUsers([]);
            setAllCustomers([]);
        }

        if(surveyFields) {
            setBlocksForm([]);
            setMediaFiles({});
            setSelectedIndex(-1);
            setSelectedIndexGroup(-1);
            setFormConfiguration({
                id: 0,
                userId: 0,
                name: '',
                clientName: '',
                description: '',
                lang: preferredLanguage,
                blocks: [],
                sequenceType: 'order',
                nbCellsToCarryOut: 1,
                active: false,
                nbMaxAnswers: 0,
                onOngoingPingUrl: '',
                onQuotafullPingUrl: '',
                onScreenoutPingUrl: '',
                onCompletedPingUrl: '',
                variables: [],
                groups: []
            });
            setConditionners([]);
            setOpenPopupGroupsOfBlocks(false);
            setNeedToSave(false);
        }
    }

    /**
     * README:
     * This useEffect() is used to update conditionners when something change in the form (delete a question, an answer, zone...) 
     */
    useEffect(() => {
        // do nothing if everything is not loaded
        if (loadData !== LoadData.Loaded) return;

        let newconditionners = [...conditionners];
        newconditionners = conditionService.removeObsoleteEntriesFromConditionners(newconditionners, blocksForm);
        setConditionners(newconditionners);
    }, [nbNotifications /* when a new modification occurred */]);

    const getDefaultBlockConfig = (uuid, type) => {
        const defaultConfig = {
            id: 0,
            formId: formIdParameter > 0 ? formIdParameter : 0,
            uuid: uuid,
            position: 0,
            title: '', 
            content: '', 
            active: true, 
            type: type, 
            imageId: 0, 
            timeout: 0, 
            transitionAuto: false, 
            openImageFullscreen: false,
            groupId: 0,
            ref: getNewBlockRef(type, blocksForm)
        };
        return defaultConfig;
    };

    const getNewBlockRef = (ofType, overAllBlocks) => {
        var elements = [];
        var withPrefix = undefined;

        if(ofType === "introduction" || ofType === "thankyou") {
            return ofType.toUpperCase();
        } else if(ofType === "text") {
            withPrefix = "T";
            elements = overAllBlocks
                // keep ONLY text blocks
                .filter(block => block.type === "text")
                .map(block => block.configuration);
        } else {
            withPrefix = "Q";
            elements = overAllBlocks
                // keep only true questions
                .filter(block => block.type !== "introduction" && block.type !== "thankyou" && block.type !== "text")
                .map(block => block.configuration);
        }

        return computeNextElementRef(elements, withPrefix);
    };

    const createBlock = (type, blockConfig = undefined, returnBlockOnly = false) => {
        var block;
        var uuid = blockConfig !== undefined && blockConfig.uuid !== undefined ? blockConfig.uuid : uuidv4();

        if (type === "introduction") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
            }

            block = {
                uuid: uuid,
                component: (<Introduction {...props} uuid={uuid} key={uuid} />),
                label: t('react.project.collectforms.block.introduction.title'),
                movable: false,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "thankyou") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
            }

            block = {
                uuid: uuid,
                component: (<ThankYou {...props} uuid={uuid} key={uuid} />),
                label: t('react.project.collectforms.block.thankyou.title'),
                movable: false,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "text") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
            }

            block = {
                uuid: uuid,
                component: (<Text {...props} uuid={uuid} key={uuid} />),
                label: t('react.project.collectforms.block.text.title'),
                movable: true,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "experience") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
                blockConfig.experience = { stimulusName: '', threeWordQuestion: t("react.project.collectforms.block.experience.placeholder") };
            }

            block = {
                uuid: uuid,
                component: (<QuestionR3m {...props} uuid={uuid} key={uuid} />),
                label: t("react.project.collectforms.block.experience"),
                movable: true,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "openQuestion") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
                blockConfig.openQuestion = { stimulusName: '', question: t("react.project.collectforms.openquestion.placeholder"), 
                    nbTextFields: 1, nbTextFieldRows: 8, minimumNumberOfCharacters: 0, minimumCharactersType: 'total', zonesLabels: [] };
            }

            block = {
                uuid: uuid,
                component: (<OpenQuestion {...props} uuid={uuid} key={uuid} />),
                label: t("react.project.collectforms.block.openQuestion"),
                movable: true,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "question") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
                blockConfig.question = { filter: '', question: '', answers: [], type: "single", shuffle: false, nbMinExpectedAnswers: 0, nbMaxExpectedAnswers: 0, bindFieldEnable: false, bindType: 'interval', nbCatalogColumns: 0, itemGrid: [], shuffleItemGrid: false, itemsStyle: 'classic' };
            }

            block = {
                uuid: uuid,
                component: (<ProfileQuestion {...props} uuid={uuid} key={uuid} />),
                label: t("react.project.collectforms.block.question.title"),
                movable: true,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "hotspot") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);

                const predefinedAnswers = [
                    { name: t("react.collectforms.hotspot.like"), uuid: uuidv4(), color: "#68BC0080", strokeWidth: 2, ref: "A1" }, 
                    { name: t("react.collectforms.hotspot.dislike"), uuid: uuidv4(), color: "#D3311580", strokeWidth: 2, ref: "A2" }
                ];

                blockConfig.hotspot = { 
                    zones: [], 
                    displayWidth: 800, 
                    nbMinExpectedAnswers: 0, 
                    nbMaxExpectedAnswers: 0, 
                    displayExclusiveCheckbox: false, 
                    exclusiveCheckboxText: '', 
                    answers: predefinedAnswers, 
                    type: 'multiple',
                };
            }

            block = {
                uuid: uuid,
                component: (<HotSpot {...props} uuid={uuid} key={uuid} />),
                label: t('react.project.collectforms.block.hotspot'),
                movable: true,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        } else if (type === "battery") {
            if (!blockConfig) {
                blockConfig = getDefaultBlockConfig(uuid, type);
        		blockConfig.battery = { mode: 'row', type: 'single', shuffleItems: false, nbMinExpectedAnswers: 0, nbMaxExpectedAnswers: 0, displayStepper: false, shuffleAnswers: false, instructions : '', nbCatalogColumns: 0, items: [], answers:[], itemsStyle: 'classic' };
            }

            block = {
                uuid: uuid,
                component: (<BatteryOfItems {...props} uuid={uuid} key={uuid} />),
                label: t("react.surveys.battery.title"),
                movable: true,
                incomplete: false,
                type: type,
                configuration: blockConfig,
            };
        }

        // just return the created block
        if (returnBlockOnly) {
            return block;
        }

        // compute where to push the new block
        let arrayBlocksForm = [...blocksForm];

        var newSelectedIndex = -1;

        if (type === "introduction") {
            arrayBlocksForm.splice(0, 0, block);
            newSelectedIndex = 0;
        } else if (type === "thankyou") {
            arrayBlocksForm.push(block);
            newSelectedIndex = arrayBlocksForm.length - 1;
        } else {
            let blocksFormLength = arrayBlocksForm.length;

            if (selectedIndex === -1 || selectedIndex === (blocksFormLength - 1)) {
                // if no block is selected or if it's the last
                if (arrayBlocksForm.length > 0 && arrayBlocksForm[blocksFormLength - 1].type === 'thankyou') {
                    // place before the thank you if any
                    arrayBlocksForm.splice(blocksFormLength - 1, 0, block);
                    newSelectedIndex = arrayBlocksForm.length - 2;
                } else {
                    // place at the end
                    arrayBlocksForm.push(block);
                    newSelectedIndex = arrayBlocksForm.length - 1;
                }
            } else {
                // place after the current one
                arrayBlocksForm.splice(selectedIndex + 1, 0, block);
                newSelectedIndex = selectedIndex + 1;
            }
        }

        setBlocksForm(arrayBlocksForm);
        setSelectedIndex(newSelectedIndex)
        setNeedToSave(true);
    };

    const getComponentConfiguration = (uuid) => {
        return blocksForm[indexOf(blocksForm, 'uuid', uuid)].configuration;
    };

    const isBlockCurrentlyDisplayed = (uuid) => {
        return indexOf(blocksForm, 'uuid', uuid) === selectedIndex;
    };

    const getAllBlocks = () => {
        return blocksForm;
    }

    const getFormConfiguration = () => {
        return formConfiguration;
    };

    const uploadFile = (file) => {
        return surveysService.uploadFormFile(formIdParameter, file)
            .then(response => {
                surveysService.getFormFileUrl(formIdParameter, response.data.id).then(res => {
                    const newfiles = { ...mediaFiles };
                    newfiles[res.id] = res.url;
                    setMediaFiles(newfiles);
                });
                return response;
            });
    }

    const generateSetImageButton = (uuid, setUploading) => {
        let title = t("react.project.collectforms.button.selectfile.tooltip");
        return (
            <span>
                <IconButton variant="outlined" color="primary" component="label" size="large" title={title}>
                    <ImageIcon />
                    <input type="file" accept="image/*" onChange={(event) => handleUploadMediaFile(event, uuid, setUploading)} style={{ display: "none" }} />
                </IconButton>
            </span>
        );
    };

    const generateDeleteImageButton = (uuid) => {
        if (getComponentConfiguration(uuid).imageId > 0) {
            let title = t("react.project.collectforms.button.deletefile.tooltip");
            return (
                <span>
                    <IconButton
                        variant="outlined"
                        color="primary"
                        component="label"
                        onClick={() => handleDeleteMediaFile(uuid, getComponentConfiguration(uuid).imageId)}
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </span>
            );
        } else {
            return '';
        }
    };

    const handleUploadMediaFile = (event, uuid, setUploading) => {
        // read the incoming file and detect its type
        const file = event.target.files[0];
        const mimeType = getMimeType(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            setUploading(true);

            // read asked ArrayBuffer
            const arrayBufferView = e.target.result;
                
            // create blob and image
            const blob = new Blob([arrayBufferView], {type: mimeType});
            const destfile = new File([blob], file.name, {
                    lastModified: new Date(),
                type: mimeType
            });

            // upload the file using our api
            uploadFile(destfile).then(response => {
                    getComponentConfiguration(uuid).imageId = response.data.id;
                    getComponentConfiguration(uuid).imageWidth = IMAGE_WIDTH_AUTO;
                    getComponentConfiguration(uuid).imagePosition = 'left';
                    getComponentConfiguration(uuid).openImageFullscreen = false;
                    setNeedToSave(true);
                }).catch((err) => {
                    console.error(err);
                }).finally(() => {
                    setUploading(false);
                });
        };

        // ask content as ArrayBuffer
        reader.readAsArrayBuffer(file);
    };

    /* This function is used to delete the image of a block */
    const handleDeleteMediaFile = (uuid, mediaFileId) => {
        getComponentConfiguration(uuid).imageId = 0;
        getComponentConfiguration(uuid).imageWidth = 0;
        getComponentConfiguration(uuid).imagePosition = 'left';
        getComponentConfiguration(uuid).openImageFullscreen = false;
        if (getComponentConfiguration(uuid).type === "hotspot") {
            getComponentConfiguration(uuid).hotspot.zones = [];
            getComponentConfiguration(uuid).hotspot.displayWidth = 800;
            notifyStructuralChange(/* when cleaning zones of an Hotspot => it's a structural change to notify */);
        }
        delete mediaFiles[mediaFileId];
        setNeedToSave(true);
    }

    const callBackConfirmManageGroups = (newGroups) => {
        formConfiguration.groups = newGroups;

        let tempArr = [...blocksForm];

        // reset all groupId to 0
        tempArr.forEach(element => element.configuration.groupId = 0);

        if (newGroups.length > 0) {
            // loop in each group
            newGroups.forEach((group) => {
                // loop in each value of group to associate the right groupId
                // NOTE: 'blockIndices'  contains the index of selected block for a group
                group.blockIndices.forEach((blockIndex) => {
                    tempArr[blockIndex].configuration.groupId = group.id;
                });
            });
        }

        setBlocksForm(tempArr);
        setOpenPopupGroupsOfBlocks(false);
        setNeedToSave(true);
        notifyStructuralChange();
    };

    const callBackCancelManageGroups = () => {
        setOpenPopupGroupsOfBlocks(false);
    };

    const saveAll = () => {
        showSpinner(true);

        if (isBlockInMiddleOfGroups(blocksForm).length > 0) {
            showSpinner(false);
            setNeedToSave(true);
            openSnackbar('warning', t("react.project.collectforms.warning.save.message.title.blockMiddleOfGroups"));
            return;
        }

        let formConfigurationToSave = { ...formConfiguration, blocks: [] };

        // at least a name is mandatory
        let containErr = formConfigurationToSave.name.length <= 0;

        for (var i = 0; i < blocksForm.length; i++) {
            if (!checkIfAllIsValid(blocksForm[i])) {
                blocksForm[i].incomplete = true;
                containErr = true;
            } else {
                blocksForm[i].incomplete = false;
            }
            let blockConfig = blocksForm[i].configuration;
            blockConfig.uuid = blocksForm[i].uuid;
            blockConfig.position = i;
            formConfigurationToSave.blocks.push(blockConfig);
        }

        for (var i = 0; i < formConfiguration.groups.length; i++) {
            if (isBlank(formConfiguration.groups[i].name)) {
                containErr = true;
            } 
        }

        if (containErr) {
            showSpinner(false);
            setNeedToSave(true);
            openSnackbar('warning', t("react.project.collectforms.warning.save.message.title"));
            return;
        }

        // save the form
        surveysService.saveForm(formConfigurationToSave.id, formConfigurationToSave)
            .then(result => {
                // if ok, save conditions
                let conditionnersToSave = conditionService.removeObsoleteEntriesFromConditionners(conditionners, blocksForm);
                conditionService.saveConditionners(result.data.id, conditionnersToSave)
                    .then(() => {
                        showSpinner(false);

                        // if it was a new form, redirect to the dedicated url
                        if (formConfigurationToSave.id === 0) {
                            props.navigate(`/surveys/${result.data.id}`, {replace: true});
                            return;
                        }

                        setNeedToSave(false);
                        setLoadData(LoadData.Load);
                    })
                    .catch(err => {
                        showSpinner(false);
                        openSnackbar('error', t("react.error.save.message.conditions"));
                    });
            })
            .catch(err => {
                showSpinner(false);
                openSnackbar('error', t("react.error.save.message"));
            });
    };

    const setFormActivityStatus = (newState) => {
        showSpinner(true);
        surveysService.setFormActivityStatus(formIdParameter, newState)
            .then(result => {
                if (result.data.active) {
                    formConfiguration.collectUuid = result.data.collectUuid;
                    openSnackbar('success', t("react.project.collectforms.now.isactive"));
                } else {
                    formConfiguration.collectUuid = undefined;
                    openSnackbar('info', t("react.project.collectforms.now.disabled"));
                }
            })
            .catch(err => {
                openSnackbar('error', t("react.error.save.message"));
            }).finally(() => {
                showSpinner(false);
            });
    };

    const computeTitle = (element) => {
        if (element === undefined || element.type === undefined) return undefined;

        if (element.type === 'introduction' || element.type === 'thankyou') {
            return element.label;
        } else if (element.type === 'experience') {
            if (!isBlank(element.configuration.experience.stimulusName)) {
                return element.configuration.experience.stimulusName;
            }
        } else if (element.type === 'openQuestion') {
            if (!isBlank(element.configuration.openQuestion.stimulusName)) {
                return element.configuration.openQuestion.stimulusName;
            }
        } else if (element.type === 'question') {
            if (!isBlank(element.configuration.question.filter)) {
                return element.configuration.question.filter;
            }
        } else if (element.type === 'text') {
            if (!isBlank(element.configuration.title)) {
                return element.configuration.title;
            }
        } else if (element.type === 'hotspot') {
            if (!isBlank(element.configuration.title)) {
                return element.configuration.title;
            }
        } else if (element.type === 'battery') {
            if (!isBlank(element.configuration.title)) {
                return element.configuration.title;
            }
        }

        // else compute a default name
        let index = indexOf(blocksForm.filter(e => e.type === element.type), 'uuid', element.uuid);
        return `${element.label} ${index + 1}`;
    };

    const checkIfAllIsValid = (element) => {
        if (element === undefined || element.type === undefined) return false;

        if(formIdParameter === 'add') return true;

        // check if the REF is not used more than 1 time
        if(isBlank(element.configuration.ref) || blocksForm.filter(b => b.configuration.ref === element.configuration.ref).length > 1) {
            return false;
        }

        if (element.type === 'introduction' || element.type === 'thankyou') {
            return !isBlankHtml(element.configuration.content) || element.configuration.imageId !== 0;
        } else if (element.type === 'text') {
            return !isBlankHtml(element.configuration.content) || element.configuration.imageId !== 0;
        } else if (element.type === 'experience') {
            return (!isBlank(element.configuration.experience.stimulusName) && !isBlankHtml(element.configuration.experience.threeWordQuestion));
        } else if (element.type === 'openQuestion') {
            return (!isBlank(element.configuration.openQuestion.stimulusName) && !isBlankHtml(element.configuration.openQuestion.question));
        } else if (element.type === 'question') {
            var wrongData = false;
            var noProblemWithImages = true;

            if (element.configuration.question.bindFieldEnable) {            
                if(element.configuration.question.bindType === 'interval') {
                    wrongData = getWrongBindIntervals(element.configuration.question).length > 0;
                } else if (element.configuration.question.bindType === 'list') {
                    wrongData = getWrongBindLists(element.configuration.question).length > 0;
                }
            } 

            if (element.configuration.question.displayOnlyAnswerImages) {
                noProblemWithImages = element.configuration.question.answers.every(a => a.imageId > 0);
            }

            return (
                !isBlank(element.configuration.question.filter) && 
                (!isBlankHtml(element.configuration.question.question) || element.configuration.question.type === "automatic") && 
                element.configuration.question.answers.length > 0 && 
                !wrongData && noProblemWithImages
            );
        } else if (element.type === 'hotspot') {
            const isImageOk = element.configuration.imageId !== 0;
            const titleAndContentOk = !isBlank(element.configuration.title) && !isBlankHtml(element.configuration.content);
            const hasZones = element.configuration.hotspot.zones.length > 0;
            const hasAnswers = element.configuration.hotspot.answers.length > 0;
            const hasNoZonesWithProblem = element.configuration.hotspot.zones
                .filter(zone => {
                    // count number of answer having at least 1 error
                    return isBlank(zone.coordinates) || zone.isFinished === false || isBlank(zone.color) || isBlank(zone.name);
                })
                // we must have 0 error ;-)
                .length === 0;
            const hasNoAnswersWithProblem = element.configuration.hotspot.answers
                .filter(answer => {
                    return isBlank(answer.name) || isBlank(answer.color);
                }).length === 0;
            return (titleAndContentOk && isImageOk && hasAnswers && hasZones && hasNoZonesWithProblem && hasNoAnswersWithProblem);
        } else if (element.type === 'battery') {
            var noProblemWithImages = true;
            if (element.configuration.battery.displayOnlyAnswerImages) {
                noProblemWithImages = element.configuration.battery.answers.every(a => a.imageId > 0);
            }
            var noEmptyItems = false;
            if (element.configuration.battery.itemsStyle === "thisOrThat") {
                noEmptyItems = element.configuration.battery.items.every(item => !isBlankHtml(item.textLeft) && !isBlankHtml(item.textRight));
            } else {
                noEmptyItems = element.configuration.battery.items.every(item => !isBlankHtml(item.text));
            }

            return (
                !isBlank(element.configuration.title) &&
                element.configuration.battery.items.length > 0 && element.configuration.battery.answers.length > 0 &&
                noProblemWithImages && noEmptyItems
            );
        }

        return false;
    };

    const getWrongBindIntervals = (question) => {
        if(!question.bindFieldEnable || question.bindType !== 'interval') return [];

        var wrongData = [];
                var listIntervals = [];
        
        for (var i = 0; i < question.answers.length; i++ ) {
            let data = question.answers[i].answer;
            const numbers = extractMinMaxOfInterval(data);
        
                    if (numbers === null) {
                wrongData.push(i);
                        continue;
                    }

                    listIntervals.push({ firstValue: numbers[0], secondValue: numbers[1], index: i });
                }
        
                let sortedValues = listIntervals.sort((a, b) => a.firstValue - b.firstValue);
                for (var i = 1; i < sortedValues.length; i++) {
                    if (sortedValues[i].firstValue <= sortedValues[i-1].secondValue) {
                wrongData.push(sortedValues[i-1].index);
                wrongData.push(sortedValues[i].index);
                    }
                }

            return wrongData;
    };

    const getWrongBindLists = (question) => {
        if(!question.bindFieldEnable || question.bindType !== 'list') return [];

        var wrongData = [];
        var lists = [];

        for (var i = 0; i < question.answers.length; i++ ) {
            let data = question.answers[i].answer;
            const numbers = extractListOfNumerics(data);

            if (numbers === null) {
                wrongData.push(i);
                continue;
        }   

            lists.push({ values: numbers, index: i });
        }

        for (var i = 0; i < lists.length; i++) {
            for (var j = 0; j < lists.length; j++) {
                if(i === j) continue;

                const hasCommonValues = lists[i].values.filter(n => lists[j].values.includes(n)).length > 0;
                if(hasCommonValues) {
                    wrongData.push(lists[i].index);
                    wrongData.push(lists[j].index);
                }
            }
        }

        return wrongData;
    };

    const numberOfAvailableCellsToCarryOut = useMemo(() => {
        // base on number of groups (for the moment)
        return new Set(blocksForm
            .map(b => b.configuration.groupId)
            .filter(id => id > 0))
            .size;
    }, [blocksForm]);

    if (loadData !== LoadData.Loaded) {
        return (
            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
        );
    }

    const helmetTitle = formIdParameter > 0 ? 
        getFormConfiguration().name : 
        t("react.surveys.add.new.survey.title");

    return (
        <SurveyContext.Provider SurveyContext value={{
            generateSetImageButton, generateDeleteImageButton, formIdParameter, isBlockCurrentlyDisplayed, 
            needToSave, setNeedToSave, getComponentConfiguration, getAllBlocks, getFormConfiguration, mediaFiles, setMediaFiles,
            computeTitle, setFormActivityStatus, getWrongBindIntervals, getWrongBindLists, notifyStructuralChange
        }}>
            <Helmet title={helmetTitle} />
            <Grid container spacing={2}>
               <ListOfBlocks
                    {...props}
                    selectedIndexGroup={selectedIndexGroup}
                    setSelectedIndexGroup={setSelectedIndexGroup}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    blocksForm={blocksForm}
                    setBlocksForm={setBlocksForm}
                    isBlockInMiddleOfGroups={isBlockInMiddleOfGroups}
                    openPopupGroupsOfBlocks={openPopupGroupsOfBlocks}
                    setOpenPopupGroupsOfBlocks={setOpenPopupGroupsOfBlocks}
                    createBlock={createBlock}
                    surveysService={surveysService}
                    conditionners={conditionners}
                    setConditionners={setConditionners}
                    getNewBlockRef={getNewBlockRef}
                    conditionService={conditionService}
                />
                <Grid item xs={6} className={classes.marginTopForm}>
                    <div className={classes.containerSticky}>
                        <ToolBar
                            {...props}
                            saveAll={saveAll}
                            hasIntroduction={indexOf(blocksForm, 'type', 'introduction') >= 0}
                            hasThankYou={indexOf(blocksForm, 'type', 'thankyou') >= 0}
                            createBlock={createBlock}
                            setLoadData={setLoadData}
                        />
                    </div>
                    <div>
                        {selectedIndex === -1 && selectedIndexGroup === -1 &&
                            <Params 
                                {...props} 
                                allUsers={allUsers} 
                                allCustomers={allCustomers} 
                                numberOfAvailableCellsToCarryOut={numberOfAvailableCellsToCarryOut} 
                            />
                        }
                        {selectedIndex === -1 && selectedIndexGroup !== -1 &&
                            <EditGroup 
                                {...props}
                                selectedIndexGroup={selectedIndexGroup}
                                conditionners={conditionners}
                                conditionService={conditionService}
                            />
                        }
                        {blocksForm.map((element, index) => (
                            <div key={`component-${index}`} style={{ display: (index === selectedIndex ? 'block' : 'none') }}>{element.component}</div>
                        ))}
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.marginTopForm}>
                    <MenuConditions
                        {...props}
                        conditionService={conditionService}
                        selectedIndex={selectedIndex}
                        currentBlock={blocksForm[selectedIndex]}
                        conditionners={conditionners}
                        setConditionners={setConditionners}
                    />
                    <TipsHelper 
                        {...props}
                    />
                </Grid>
            </Grid >
            <PopupGroupsOfBlocks
                {...props}
                surveysService={surveysService}
                openPopupGroupsOfBlocks={openPopupGroupsOfBlocks}
                callBackConfirmManageGroups={callBackConfirmManageGroups}
                callBackCancelManageGroups={callBackCancelManageGroups}
                selectedIndexGroup={selectedIndexGroup}
                setSelectedIndexGroup={setSelectedIndexGroup}
            />
        </SurveyContext.Provider>
    );
}
