import React, { useContext, useEffect, useState, useMemo } from 'react';
import { CollectorContext, CollectorTestContext } from './context';
import Button from '@mui/material/Button';
import { cloneDeep, isBlank, appendSuffixIfGreaterThan } from '../../utils/utils';
import Divider from '@mui/material/Divider';
import { Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getBlockIcon } from '../../utils/surveysUtils';
import ReplayIcon from '@mui/icons-material/Replay';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CollectorsTestPanel(props) {

    const { t, block, setBlocksToDisplay, setActualBlock } = props;

    const {
        blocksToDisplay, actualBlock, userHistory
    } = useContext(CollectorContext);

    const {
        survey, generateAnswerAutomatically
    } = useContext(CollectorTestContext);

    // -- we have to keep an unmodified copy of all blocks of the survey ---------------------------------

    const [originalBlocksToDisplay, setOriginalBlocksToDisplay] = useState(undefined);
    const [originalPairingBlocksToDisplay, setOriginalPairingBlocksToDisplay] = useState(undefined);

    useEffect(() => {
        if(!blocksToDisplay) return;

        // // keep an initial copy of the original load
        if(!originalBlocksToDisplay) {
            setOriginalBlocksToDisplay(cloneDeep(blocksToDisplay));
            return;
        }

        // if the original version (untouched backup) contains a Pairing block 
        // AND if we receive a new version of blocksToDisplay
        // That's BECAUSE we are receiving the blocks for the Pairing
        if(!originalPairingBlocksToDisplay) {
            const hasPairingBlock = originalBlocksToDisplay.some(b => b.type === 'pairing');
            if(hasPairingBlock) {
                // DO NOT BACKUP ALL FROM blocksToDisplay, because it's already modified
                const pairingBlocks = blocksToDisplay
                    // get ONLY blocks that doesn't exist in the original
                    .filter(incomingb => !originalBlocksToDisplay.find(existingb => existingb.id === incomingb.id));
                setOriginalPairingBlocksToDisplay(cloneDeep(pairingBlocks));
                return;
            }
        }
    }, [blocksToDisplay /* everytime the blocksToDisplay change */]);
    // ---------------------------------------------------------------------------------------------------

    const isFirstBlock = useMemo(() => {
        return blocksToDisplay[0].id === block.id;
    }, [blocksToDisplay, block]);

    const isAutomaticButtonDisabled = useMemo(() => {
        return block && ['introduction', 'thankyou', 'text'].includes(block.type);
    }, [block]);

    const handleRestartSurvey = () => {
        window.location.reload();
    };

    const handleStepBack = () => {
        // always exist (with empty values)
        let currentBlockInHistoryIdx = userHistory.findIndex(uh => uh.blockId === block.id);

        // get back in the history before the current block
        let previousBlockId = userHistory[currentBlockInHistoryIdx - 1].blockId;

        // get its index in the list of all blocks
        let newActualBlockIdx = blocksToDisplay.findIndex(b => b.id === previousBlockId);

        handleSetNewActualBlock(newActualBlockIdx);
    };

    /**
     * if the newActualBlockIdx is before the current, we have to:
     * reset all answers / zones / items into blocks after new the expected block.
     * 
     * @param {
     * } newActualBlockIdx 
     */
    const handleSetNewActualBlock = (newActualBlockIdx) => {
        var rebuildedBlocksToDisplay = undefined;

        const pairingBlockIdx = originalBlocksToDisplay.findIndex(b => b.type === 'pairing');
        if(pairingBlockIdx === -1 /* no pairing block */ || newActualBlockIdx < pairingBlockIdx /* before the pairing */) {
            // restore only futur elements from originalBlocksToDisplay
            const elementsBefore = blocksToDisplay.slice(0, newActualBlockIdx + 1);
            const elementsAfter = originalBlocksToDisplay.slice(newActualBlockIdx + 1);
            rebuildedBlocksToDisplay = elementsBefore.concat(elementsAfter);
        } else {
            // there is a pairing block we have to rebuild a complete survey and take what we need after
            const rebuildPartsBefore = originalBlocksToDisplay.slice(0, pairingBlockIdx);
            const rebuildPartsAfter = originalBlocksToDisplay.slice(pairingBlockIdx + 1);
            const rebuildedCompletSurvey = rebuildPartsBefore.concat(originalPairingBlocksToDisplay).concat(rebuildPartsAfter);

            const elementsBefore = blocksToDisplay.slice(0, newActualBlockIdx + 1);
            const elementsAfter = rebuildedCompletSurvey.slice(newActualBlockIdx + 1);
            rebuildedBlocksToDisplay = elementsBefore.concat(elementsAfter);
        }

        setBlocksToDisplay(rebuildedBlocksToDisplay);
        setActualBlock(newActualBlockIdx);
    };

    const findAnsweredQuestions = (userHistoryEntry) => {
        var answerToReturn = '';

        const blockToWork = blocksToDisplay.find(b => b.id === userHistoryEntry.blockId);
        if(!blockToWork) return answerToReturn;

        if (blockToWork.type === "introduction") {
            answerToReturn = t('react.project.collectforms.block.introduction.title');
        } else if (blockToWork.type === "text") {
            answerToReturn = t('react.project.collectforms.block.text.title');
        } else if (blockToWork.type === "openQuestion") {
            // @see OpenQuestion.js pushAnswersToUserHistory()
            answerToReturn = userHistoryEntry.elements.map(abqr => abqr.answers).flat().map(a => a.text).join(' / ');
        } else if (blockToWork.type === "experience") {
            // @see QuestionR3m.js pushAnswersToUserHistory()
            answerToReturn = userHistoryEntry.elements.map(abqr => abqr.answers).flat().map(a => a.text).join(', ');
        } else if (blockToWork.type === "question") {
            // @see ProfileQuestion.js pushAnswersToUserHistory()
            answerToReturn = userHistoryEntry.elements.map(abqr => abqr.answers)
                .flat()
                .map(a => a.text)
                .join(' / ');
            let enteredValue = userHistoryEntry.elements.map(e => e.enteredValue /* only one normaly */ ).join(', ');
            if (!isBlank(enteredValue)) {
                answerToReturn += ` / entered value = ${enteredValue}`;
            }
        } else if (blockToWork.type === "battery") {
            // @see BatteryOfItems.js pushAnswersToUserHistory()
            answerToReturn = blockToWork.battery.items.map(item => {
                    const userAnswer = userHistoryEntry.elements.find(abqr => abqr.objectRef === `${blockToWork.ref}.${item.ref}`);
                    if (userAnswer) {
                        let text = `${item.ref}: ${userAnswer.answers.map(a => a.text /* only one normaly */).join(' / ')}`;
                        if (!isBlank(userAnswer.enteredValue)) {
                            text += ` / entered value = ${userAnswer.enteredValue}`;
                        }
                        return text;
                    } else {
                        return `${item.ref}: -`;
                    };
                })
                .join(' / ');
        } else if (blockToWork.type === "hotspot") {
            // @see HotSpot.js pushAnswersToUserHistory()
            answerToReturn = blockToWork.hotspot.zones.map(zone => {
                    const userAnswer = userHistoryEntry.elements.find(abqr => abqr.objectRef === `${blockToWork.ref}.${zone.ref}`);
                    if (userAnswer) {
                        return `${zone.ref}: ${userAnswer.answers.map(a => a.text /* only one normaly */).join(' / ')}`;
                    } else {
                        return `${zone.ref}: -`;
                    };
                })
                .join(' / ');
        }

        return `${blockToWork.ref}: ${!isBlank(answerToReturn) ? answerToReturn : '-'}`;
    };

    const getBlockTitle = (blockId) => {
        var title = undefined;
        var ablock = survey.blocks.find(s => s.id === blockId);
        if(!ablock) return 'SCREENOUT / QUOTAFULL'; /* it can append when create a screenout or quotafull fake block */
        switch(ablock.type) {
            case 'introduction':
            case 'thankyou':
                title = ablock.ref;
                break;
            case 'question':
                title = `${ablock.ref}: ${ablock.question.filter}`;
                break;
            case 'openQuestion':
                title = `${ablock.ref}: ${ablock.openQuestion.stimulusName}`;
                break;
            case 'experience':
                title = `${ablock.ref}: ${ablock.experience.stimulusName}`;
                break;
            case 'hotspot':
            case 'battery':
            case 'text':
                    title = `${ablock.ref}: ${ablock.title}`;
                break;
        } 

        return title;
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={1} style={{ position: 'relative', marginTop: 60, padding: "0px 20px 0px 20px" }}>
            <Grid item xs={12}>
                <Divider style={{ marginTop: 20 }} />
            </Grid>
            <Grid item xs={2}>
                <Button style={{ height: 48 }} variant="outlined" onClick={handleRestartSurvey} startIcon={<ReplayIcon />}>{t('react.collectors.testmode.restart')}</Button>
            </Grid>
            <Grid item xs={2}>
                <Button style={{ height: 48 }} disabled={isFirstBlock} variant="outlined" onClick={handleStepBack} startIcon={<ArrowBackIcon />}>{t('react.collectors.testmode.back')}</Button>
            </Grid>
            <Grid item xs={3}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>{t('react.collectors.testmode.navigation')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: 280, maxHeight: 450, overflowY: 'auto' }}>
                        {blocksToDisplay.map((block, index) => {
                            let title = getBlockTitle(block.id);
                            let formBlock = survey.blocks.find(b => b.id === block.id);
                            return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ width: 20 }}>{index + 1}</Typography>
                                <Tooltip title={title} placement="right">
                                <Button
                                    variant={index === actualBlock ? "contained" : "outlined"}
                                    onClick={() => handleSetNewActualBlock(index)}
                                    style={{ margin: 5, width: 240 }}
                                    startIcon={getBlockIcon(formBlock)}
                                    sx={{
                                        width: "100%",
                                        "& .MuiButton-endIcon": {
                                            position: "absolute",
                                            right: "1rem",
                                        },
                                        "& .MuiButton-startIcon": {
                                            position: "absolute",
                                            left: "1rem",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "20px",
                                        },
                                    }}
                                >
                                    {appendSuffixIfGreaterThan(title, '...', 15)}
                                </Button>
                                </Tooltip>
                            </div>
                        )})}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={3}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>{t('react.collectors.testmode.path.traveled')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: 440, maxHeight: 450, overflowY: 'auto', fontSize: '11px' }}>
                        {userHistory.map((userHistoryEntry, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="ql-view" dangerouslySetInnerHTML={{ __html: findAnsweredQuestions(userHistoryEntry) }} />
                            </div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={2}>
                <Button style={{ height: 48 }} disabled={isAutomaticButtonDisabled} variant="outlined" onClick={generateAnswerAutomatically} startIcon={<AutoFixHighIcon />} >{t('react.collectors.testmode.automatic.answer')}</Button>
            </Grid>
        </Grid>
  );
}
