import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function CreateGroupDialog(props) {

  const {
    t, open, handleClose, handleApply
  } = props;

  const [title, setTitle] = useState('');

  const save = (e) => {
      if (title) {
          handleApply(title);
      }
  }

    return (
        <form noValidate autoComplete="off">
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={open}
                onClose={handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.grouping.creategroupdialog.title')}
                    callbackOnclose={handleClose}
                />
                <DialogContent>
                    <TextField fullWidth onChange={e => { setTitle(e.target.value) }} label="Label" autoFocus/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('react.button.close')}
                    </Button>
                    <Button onClick={save} autoFocus disabled={!title}>
                        {t('react.button.creategroup')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    )
};
