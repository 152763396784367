import { RestClientService } from './RestClientService';
require('dotenv').config();

class ProjectService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async createProject(projectBody) {
        return this.getRestClient().post('/api/projects/v1.0/', projectBody);
    }

    async updateProject(projectId, projectBody) {
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}`, projectBody);
    }

    async fetchProject(projectId) {
        return this.getRestClient().get(`/api/projects/v1.0/${projectId}`);
    }

    async deleteProject(projectId) {
        return this.getRestClient().delete(`/api/projects/v1.0/${projectId}`);
    }

    async fetchWelcomeProjects() {
        return this.getRestClient().get('/api/projects/v1.0/?size=3&sort=id&order=desc');
    }

    async fetchFilesInDefaultSequence(category, projectId, from = 0, size = 25) {
        return this.getRestClient().get(`/api/files/v1.0/project/${projectId}/default-sequence/${category}?from=${from}&size=${size}`);
    }

    async fetchImagesInDefaultSequence(category, projectId, from = 0, size = 25) {
        return this.getRestClient().get(`/api/files/v1.0/project/${projectId}/default-sequence/${category}?from=${from}&size=${size}&keywords=image/`);
    }

    async renameProjectFile(newName, projectFileId) {
        return this.getRestClient().put(`/api/files/v1.0/${projectFileId}/rename?newName=${newName}`);
    }

    async uploadProjectFileInDefaultSequence(projectId, category, file, ignoreConflicts) {
        var formData = new FormData();
        formData.append("ignoreConflicts", ignoreConflicts);
        formData.append("uploadedFile", file);

        return this.getRestClient().post(`/api/files/v1.0/project/${projectId}/default-sequence/${category}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async deleteProjectFile(category, fileId) {
        return this.getRestClient().delete(`/api/files/v1.0/${category}/${fileId}`);
    }

    async downloadProjectFile(category, fileId) {
      let isOk = false;
      await this.getRestClient().get(`/api/files/v1.0/${category}/${fileId}`, {responseType: 'blob'})
      .then(response => {
        RestClientService.processBinaryResponse(response);
        isOk = true;
      })
      .catch(err => {
        isOk = false;
      });
      return isOk;
    }

    async updateStimulusImageId(stimulusId, projectFileId) {
        return this.getRestClient().put(`/api/files/v1.0/stimulus/${stimulusId}/image/${projectFileId}`);
    }
    
    async deleteStimulusImageId(stimulusId) {
        return this.getRestClient().delete(`/api/files/v1.0/stimulus/${stimulusId}/image`);
    }
    
    async openProjectFile(category, fileId) {
      let url = undefined;
      await this.getRestClient().get(`/api/files/v1.0/${category}/${fileId}`, {responseType: 'blob'})
      .then(response => {
        url = window.URL.createObjectURL(new Blob([response.data]));
      });
      return url;
    }

    async fetchProjects(from = 0, size = 25, sort = "id", order = "asc", keywords) {
        return this.getRestClient().get(`/api/projects/v1.0/?from=${from}&size=${size}&sort=${sort}&order=${order}&name=${encodeURIComponent(keywords)}`);
    }

    async setCodificationLibraryId(projectId, libraryId) {
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/codification/library/${libraryId}`);
    }

    /*
     * This method MUST be only call when a project is finished, and by an Administrator
     */
    async fireForcedCodification(projectId) {
      return this.getRestClient().put(`/api/projects/v1.0/${projectId}/fire-forced-codification`);
    }

    /*
     * This method MUST be only call when a project is finished, and by an Administrator
     */
    async fireForcedScoring(projectId) {
      return this.getRestClient().put(`/api/projects/v1.0/${projectId}/fire-forced-scoring`);
    }

    async getProjectErrors(projectId, from, size) {
      return this.getRestClient().get(`/api/projects/v1.0/${projectId}/errors?from=${from}&size=${size}`);
    }

    async fetchProjectVisitorsPrivileges(projectId) {
        return this.getRestClient().get(`/api/privileges/v1.0/visitors/project/${projectId}`);
    }

    async updateProjectVisitorsPrivileges(projectId, data) {
        return this.getRestClient().post(`/api/privileges/v1.0/visitors/project/${projectId}`, data);
    }

    async sendProjectVisitorInvitationByEmail(projectId, email) {
        return this.getRestClient().post(`/api/privileges/v1.0/visitors/project/${projectId}/send-invitations/by-email?email=${email}`);
    }

    async getProjectFilters(projectId) {
        return this.getRestClient().get(`/api/projects/v1.0/${projectId}/filters`);
    }

    async applyFilterActions(projectId, payloadActions) {
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async renameFilter(projectId, filterId, newName) {
        const payloadActions = [];
        payloadActions.push({
            "action": "renameFilter", 
            "id": filterId, 
            "name": newName
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async mergeFilters(projectId, filterIds, inFilterId) {
        const payloadActions = [];
        payloadActions.push({
            "action": "mergeFilters", 
            "filterIds": filterIds, 
            "inFilterId": inFilterId
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async deleteFilters(projectId, filterIds) {
        const payloadActions = [];
        payloadActions.push({
            "action": "deleteFilters", 
            "ids": filterIds
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async setFiltersHidden(projectId, filterIds, hidden) {
        const payloadActions = [];
        payloadActions.push({
            "action": "setFiltersHidden", 
            "ids": filterIds,
            "hidden": hidden
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async renameFilterValue(projectId, filterValueId, newValue) {
        const payloadActions = [];
        payloadActions.push({
            "action": "renameFilterValue", 
            "id": filterValueId, 
            "value": newValue
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async mergeFilterValues(projectId, filterValueIds, inFilterValueId) {
        const payloadActions = [];
        payloadActions.push({
            "action": "mergeFilterValues", 
            "filterValueIds": filterValueIds,
            "inFilterValueId": inFilterValueId
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async deleteFilterValues(projectId, filterValueIds) {
        const payloadActions = [];
        payloadActions.push({
            "action": "deleteFilterValues", 
            "ids": filterValueIds
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async setFilterValuesHidden(projectId, filterValueIds, hidden) {
        const payloadActions = [];
        payloadActions.push({
            "action": "setFilterValuesHidden", 
            "ids": filterValueIds,
            "hidden": hidden
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async setFilterPositions(projectId, orderedFilterIds) {
        const payloadActions = [];
        payloadActions.push({
            "action": "setFilterPositions", 
            "orderedFilterIds": orderedFilterIds
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async setFilterValuesOrder(projectId, filterId, orderedFilterValuesIds) {
        const payloadActions = [];
        payloadActions.push({
            "action": "setFilterValuesOrder", 
            "filterId": filterId,
            "orderedFilterValuesIds": orderedFilterValuesIds
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }

    async setFilterDisplayType(projectId, filterId, newDisplayType) {
        const payloadActions = [];
        payloadActions.push({
            "action": "setFilterDisplayType", 
            "id": filterId, 
            "displayType": newDisplayType
        });
        return this.getRestClient().put(`/api/projects/v1.0/${projectId}/filters/apply-actions`, payloadActions);
    }
}

export default ProjectService;
