import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import {Trans} from "react-i18next";
import DialogContentText from "@mui/material/DialogContentText";

const useStyles = makeStyles()((theme) => ({
	dropzoneArea: {
		backgroundColor: '#F0F0F0',
		width: "100%",
	},
	container: {
		width: "800px",
	},
	flexBetween: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: theme.spacing(3),
	},
	titleIcon: {
		marginRight: '12px',
		verticalAlign: 'sub'
	},
	colorCheckbox: {
		color: '#f59e0b'
	},
	formControlCheckbox: {
		marginTop: 5,
		color: '#92400e',
		marginBottom: '-20px'
	}
}));

export default function UploadGroupingFileDialog(props) {

	const { t, openState, callbackAfterCancel, callbackAfterConfirm, openSnackbar, setLoadData } = props;

	const { classes } = useStyles();

	const [groupingInputFile, setGroupingInputFile] = useState([]);
	const [checkboxConfirmDelete, setCheckboxConfirmDelete] = useState(false);

	useEffect(() => {
		if(!openState) return;

		setGroupingInputFile([]);
		setCheckboxConfirmDelete(false)
	}, [openState]);

	const handleSelectUploadGroupingFile = (event) => {
		event.preventDefault();
		if (event.target.files.length === 1) {
			let fnlc = event.target.files[0].name.toLowerCase();
			if (fnlc.endsWith(".xlsx")) {
				setGroupingInputFile(event.target.files[0]);
			} else {
				openSnackbar("error", t("react.grouping.import.excel.file.error"));
			}
		}
	};

	if (!openState) return null;

	return (
		<Dialog open={openState} onClose={callbackAfterCancel}>
			<DialogTitle>
				<WarningIcon color='secondary' className={classes.titleIcon} />
				{t("react.grouping.toolbar.button.upload.excel")}
			</DialogTitle>
			<Grid container spacing={0} >
				<Grid item xs={12} className={classes.container}>
					<DialogContent>
						<DialogContentText component={'div'}>{t("react.grouping.upload.excel.warning")}</DialogContentText>
						<Grid item xs={12} paddingBlock={2} paddingTop={4} className={classes.flexBetween}>
							<FormControl fullWidth>
								<Input
									disabled
									id="groupingInputFileName"
									value={groupingInputFile.name}
									className={classes.textField}
									placeholder={t("Sélectionner un fichier")}
									fullWidth
									endAdornment={
										<InputAdornment position="end">
											<label htmlFor="groupingInputFile">
												<Button variant="contained" color="primary" component="span">
													{t("Choisir")}
												</Button>
											</label>
										</InputAdornment>
									}
								/>
								<input
									key={Date.now()}
									onChange={handleSelectUploadGroupingFile}
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									style={{ display: "none" }}
									id="groupingInputFile"
									type="file"
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} paddingBottom={2} className={classes.flexBetween}>
							<FormControlLabel
								className={classes.formControlCheckbox}
								control={<Checkbox
									color="warning"
									className={classes.colorCheckbox}
									checked={checkboxConfirmDelete}
									onChange={() => setCheckboxConfirmDelete(!checkboxConfirmDelete)} />
								}
								label={t("react.grouping.upload.excel.warning.checkbox")}
							/>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							size="large"
							startIcon={<CloseIcon />}
							onClick={callbackAfterCancel}
							color="primary"
						>
							{t("react.button.cancel")}
						</Button>
						<Button
							size="large"
							startIcon={<UploadFileIcon />}
							color="primary"
							disabled={groupingInputFile.length === 0 || !checkboxConfirmDelete}
							onClick={() => {callbackAfterConfirm(groupingInputFile)}}
						>
							{t("react.translationtool.importexportdialog.button.import.title")}
						</Button>
					</DialogActions>
				</Grid>
			</Grid>
		</Dialog>
	);
}
