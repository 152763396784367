import React, { useState, useEffect, useReducer } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AuthService } from '../../services/AuthService';
import ProjectService from '../../services/ProjectService';
import UserService from '../../services/UserService';
import SequenceService from '../../services/SequenceService';
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SecurityIcon from '@mui/icons-material/Security';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import PanelMultiAnswers from './PanelMultiAnswers';
import PanelFiles from './PanelFiles';
import PanelAdmin from './PanelAdmin';
import PanelVisitors from './PanelVisitors';
import PanelFilters from './PanelFilters';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TimelapseSharpIcon from '@mui/icons-material/TimelapseSharp';
import StarIcon from '@mui/icons-material/Star';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import WarningIcon from '@mui/icons-material/Warning';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BarChartIcon from '@mui/icons-material/BarChart';
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Coefficients from '../shared/Coefficients/Coefficients';
import PanelCodif from './PanelCodif';
import { LoadData } from '../../Constants.js'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WebIcon from '@mui/icons-material/Web';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CircularProgress from '@mui/material/CircularProgress';
import dateFormat from 'dateformat';
import { cloneDeep, isBlank, orderBy, areObjectsEqual } from '../../utils/utils.js';
import { red } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import {Helmet} from "react-helmet";
import DeleteIcon from '@mui/icons-material/Delete';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';

const projectService = new ProjectService();
const sequenceService = new SequenceService();
const userService = new UserService();

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),

  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  typographyIcon: {
    verticalAlign: 'sub',
  },
  card: {
    backgroundColor: red[50],
  },
  dividerSpace: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  SomeSpaceUnder: {
    marginBottom: theme.spacing(2),
  },
  rotate180: {
    transform: 'rotate(-180deg)',
  },
}));

const emptyProject = {
  "id": 0,
  "userId": 0,
  "name": "",
  "clientName": "",
  "caseStudy": "",
  "description": "",
  "sectorTypeId": 0,
  "stimulusTypeId": 0,
  "state": "draft",
  "sequenceMode": "single",
  "creationDate": new Date(),
  "updateDate": new Date(),
  "coefficientsId": 0,
  "codificationLibraryId": 0,
  "startupCreditsConsumptionDate": undefined,
};

const emptySubscriptionForecast = {
  'loaded': false,
  'nbObservationsForecast': 0,
  'nbRemainingCustomerObservations': 0,
  'nbProjectsForecast': 0,
  'nbRemainingCustomerProjects': 0,
  'forecastValid': false
};

export default function ProjectEdit(props) {

  const { t, openSnackbar, showSpinner } = props;

  // URL parameter
  const { projectIdParameter } = useParams();
  const { activeTabParameter } = useParams();

  // -- SECURITY CHECKPOINT -------------------------------------------
  if (activeTabParameter === 'admin' && (!(projectIdParameter > 0) || !AuthService.isAdmin())) {
    openSnackbar('error', t("react.generic.error.unauthorized"));
    props.navigate(`/projects`);
  }
  // ------------------------------------------------------------------

  const { classes } = useStyles();

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [project, setProject] = useState(cloneDeep(emptyProject));
  const [defaultSequence, setDefaultSequence] = useState(undefined);
  const [defaultSequenceHasAnswerFile, setDefaultSequenceHasAnswerFile] = useState(undefined);

  const [subscriptionForecast, setSubscriptionForecast] = useState(cloneDeep(emptySubscriptionForecast));

  const [allUsers, setAllUsers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [displayErrors, setDisplayErrors] = useState({});

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    props.navigate('/projects');
  };

  const handleClickEditDialogConfirm = (event) => {
    let errorsInFields = { ...displayErrors };
    errorsInFields.name = isBlank(project.name);
    errorsInFields.clientName = isBlank(project.clientName);
    errorsInFields.caseStudy = isBlank(project.caseStudy);
    errorsInFields.coefficientsId = project.coefficientsId === 0;

    setDisplayErrors(errorsInFields);

    let nbErrors = Object.entries(errorsInFields).filter(value => value[1] === true).length;
    if (nbErrors > 0) {
      return;
    }

    if (project.id === 0) {
      projectService.createProject(project)
        .then(result => {
          props.navigate(`/projects/${result.data.id}`, {replace: true});
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.while.saving"));
        });
    } else {
      projectService.updateProject(project.id, project)
        .then(result => {
          setLoadData(LoadData.Load);
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.while.saving"));
        });
    }
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    // reset errors
    setDisplayErrors({ name: false, clientName: false, caseStudy: false, coefficientsId: false });

    if (projectIdParameter > 0) {
      var promises = [
        projectService.fetchProject(projectIdParameter),
        sequenceService.fetchProjectDefaultSequence(projectIdParameter),
        projectService.fetchFilesInDefaultSequence('answers', projectIdParameter, 0, 50)
      ];

      if (AuthService.isAdmin()) {
        promises.push(userService.searchUsers(0, 500, "lastname", "asc", "", false, false));
      }

      Promise.all(promises)
        .then(results => {
          setProject(results[0].data);
          setDefaultSequence(results[1].data);
          setDefaultSequenceHasAnswerFile(results[2].data.total > 0);
          setAllUsers(AuthService.isAdmin() ? results[3].data.hits : []);
          setAllCustomers(AuthService.isAdmin() ? results[3].data.customers : [])
          setLoadData(LoadData.Loaded);
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.while.loading"));

          setProject(cloneDeep(emptyProject, { 'userId' : AuthService.getUser().id }));
          setDefaultSequence(undefined);
          setDefaultSequenceHasAnswerFile(undefined);
          setAllUsers([]);
          setAllCustomers([]);
          setLoadData(LoadData.Loaded);
        });
    } else {
      setProject(cloneDeep(emptyProject, { 'userId' : AuthService.getUser().id }));
      setDefaultSequence(undefined);
      setDefaultSequenceHasAnswerFile(undefined);
      setAllUsers([]);
      setAllCustomers([]);
      setLoadData(LoadData.Loaded);
    }
  }, [loadData, projectIdParameter]);

  useEffect(() => {
    // do nothing if the project is not loaded
    if (loadData !== LoadData.Loaded) return;

    if(defaultSequence === undefined) {
      setSubscriptionForecast(cloneDeep(emptySubscriptionForecast, { loaded: true }));
      return;
    }

    sequenceService.fetchSequenceSubscriptionForecast(defaultSequence.id)
      .then(result => {
        setSubscriptionForecast(cloneDeep(result.data, { loaded: true }));
      })
      .catch(err => {
        setSubscriptionForecast(cloneDeep(emptySubscriptionForecast, { loaded: true }));
        openSnackbar('warning', t("react.subscription.error.no.subscription"));
      });
  }, [loadData]);

  const handleReloadProjectPage = () => {
    setLoadData(LoadData.Load);
  };

  const changeField = (fieldName, value) => {
    project[fieldName] = value;
    forceUpdate();
  };

  const handleClickSetDefaultSequenceNewState = (event, newState) => {
    event.preventDefault();
    // the state of the project will follow the state of the default sequence
    sequenceService.updateSequenceState(defaultSequence.id, newState)
      .then(result => {
        setLoadData(LoadData.Load);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.while.changing.state"));
      });
  };

  const [tabValue, setTabValue] = useState(activeTabParameter === 'admin' ? 666 : 0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCoefficientsIdChange = (newValue) => {
    changeField('coefficientsId', newValue);
  };

  // -------------------------------------------------------------------------------
  // detect project changes every 30s
  const [delay, setDelay] = useState(1000);
  const [reloadProject, setReloadProject] = useState(false);
  useEffect(() => {
    // security if disabled or to avoid to many calls
    if (loadData !== LoadData.Loaded || isNaN(projectIdParameter) || delay < 1000) return;

    const interval = setInterval(() => {
      const defaultInterval = 30000;
      // request just 1 notification because we just need the size
      projectService.fetchProject(projectIdParameter)
        .then(result => {
          let needReload = !areObjectsEqual(project, result.data);
          setReloadProject(needReload);
          if (needReload) {
            openSnackbar('warning', t("react.project.new.state.need.reload"));
          }

          // on success keep (or reset after error) delay to defaultInterval
          setDelay(defaultInterval);
        })
        .catch(err => {
          // double delay on error and disable if >= 30 min
          let newDelay = delay < defaultInterval /* after first run */ ? defaultInterval /* delay to 1 min */ : delay * 2 /* else double */;
          setDelay(newDelay < 1800000 /* 30 min */ ? newDelay : 0);
          openSnackbar('error', t("react.project.error.while.loading"));
        });
    }, delay);
    return () => clearInterval(interval);
  }, [delay, loadData, projectIdParameter, project]);

  const handleClickRefresh = (newValue) => {
    setReloadProject(false);
    setLoadData(LoadData.Load);
  };
  // -------------------------------------------------------------------------------

  const [projectErrors, setProjectErrors] = useState({ 'total': 0 });
  const [projectErrorsFrom, setProjectErrorsFrom] = useState(0);
  const [projectErrorsSize] = useState(50);
  const [loadErrors, setLoadErrors] = useState(true);
  useEffect(() => {
    if (!loadErrors || isNaN(projectIdParameter)) return;

    projectService.getProjectErrors(projectIdParameter, projectErrorsFrom, projectErrorsSize)
      .then(result => {
        setProjectErrors(result.data);
        setLoadErrors(false);
      })
      .catch(err => {
        setLoadErrors(false);
        openSnackbar('error', 'An error occurred while loading data');
      });
  }, [loadErrors, projectIdParameter, projectErrorsFrom, projectErrorsSize]);

  const handleClickPreviousErrors = (event) => {
    event.preventDefault();
    setProjectErrorsFrom(projectErrorsFrom - projectErrorsSize);
    setLoadErrors(true);
  };

  const handleClickNextErrors = (event) => {
    event.preventDefault();
    setProjectErrorsFrom(projectErrorsFrom + projectErrorsSize);
    setLoadErrors(true);
  };

  const canIChangeSequenceMode = () => {
    return project.state === 'draft' || project.state === 'validation' ||
      /** For the moment: only admin users can change a 'single' to 'multi' **/
      (AuthService.isAdmin() && project.sequenceMode === 'single');
  };

  const [showConfirmDeleteProjectPopup, setShowConfirmDeleteProjectPopup] = useState(false);
  const handleConfirmDeleteProject = () => {
    setShowConfirmDeleteProjectPopup(false);
    showSpinner(true);
    projectService.deleteProject(projectIdParameter)
        .then(response => {
            openSnackbar('success', t("react.project.edit.success.delete", {name: project.name}));
            props.navigate('/projects', {replace: true});
            return;
        })
        .catch(err => {
            openSnackbar('error', t("react.project.edit.error.delete"));
        })
        .finally(function () {
            showSpinner(false);
        });
  };

  const handleCancelDeleteProjectCollectedData = () => {
      setShowConfirmDeleteProjectPopup(false);
  };

  if (loadData !== LoadData.Loaded) {
    return (
      <LinearProgress />
    );
  }

  const helmetTitle = project.id > 0 ? 
      project.name : 
      t("react.project.add.new.project");

  const pageTitle = project.id > 0 ? 
      t("react.project.modify.project", { 'name': project.name, 'clientName': project.clientName }) : 
      t("react.project.add.new.project");

  return (
    <div className={classes.root}>
      <Helmet title={helmetTitle} />
      <Paper className={classes.paper}>
        <Typography variant="h5" component="h3" paragraph>
          {pageTitle}
          {project.id > 0 && reloadProject &&
          <Tooltip title={t('react.button.refresh')}>
            <IconButton onClick={handleClickRefresh} size="large">
              <CachedIcon style={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
          }
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab value={0} icon={<SubtitlesIcon />} label={t("react.project.tab.properties")} />
                {(project.userId === AuthService.getUser().id || AuthService.isManager()) && <Tab value={1} icon={<AttachFileIcon />} label={t("react.project.tab.files")} disabled={project.id === 0} />}
                {(project.userId === AuthService.getUser().id || AuthService.isManager()) && <Tab value={2} icon={<FileCopyIcon />} label={t("react.project.tab.data")} disabled={project.id === 0} />}
                {(project.userId === AuthService.getUser().id || AuthService.isManager()) && <Tab value={5} icon={<FormatListNumberedIcon />} label={t("react.project.tab.filters")} disabled={project.id === 0}/>}
                {AuthService.hasRightToManageVisitor() && <Tab value={3} icon={<SupervisedUserCircleIcon />} label={t("react.project.tab.visitors")} disabled={project.id === 0 || project.state !== 'completed'} />}
                {(project.id > 0 && AuthService.isAdmin()) && <Tab value={4} icon={<AccountTreeIcon />} label={t("react.project.tab.codif")} disabled={project.codificationLibraryId === 0} />}
                {(project.id > 0 && AuthService.isAdmin()) && <Tab value={666} icon={<SecurityIcon />} label={t("react.project.tab.admin")} />}
              </Tabs>
            </AppBar>
          </Grid>
          {tabValue === 0 &&
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("react.project.edit.name")}
                    type="text"
                    value={project.name}
                    autoComplete="off"
                    onChange={e => changeField('name', e.target.value)}
                    fullWidth
                    className={classes.textField}
                    error={displayErrors.name}
                    helperText={displayErrors.name && t("react.validation.name.empty")}
                  />
                  <TextField
                    margin="dense"
                    id="clientName"
                    label={t("react.project.edit.clientname")}
                    type="text"
                    value={project.clientName}
                    autoComplete="off"
                    onChange={e => changeField('clientName', e.target.value)}
                    fullWidth
                    className={classes.textField}
                    error={displayErrors.clientName}
                    helperText={displayErrors.clientName && t("react.validation.clientname.empty")}
                  />
                  <TextField
                    select
                    disabled={!canIChangeSequenceMode()}
                    label={t("react.project.edit.sequencemode")}
                    value={project.sequenceMode}
                    autoComplete="off"
                    onChange={e => changeField('sequenceMode', e.target.value)}
                    inputProps={{
                      name: 'sequenceMode',
                      id: 'filled-sequencemode-native-simple',
                    }}
                  >
                    <MenuItem value='single'>{t("react.project.edit.sequencemode.single")}</MenuItem>
                    <MenuItem value='multi'>{t("react.project.edit.sequencemode.multi")}</MenuItem>
                  </TextField>
                  <TextField
                    margin="dense"
                    id="caseStudy"
                    label={t("react.project.edit.casestudy")}
                    type="text"
                    value={project.caseStudy}
                    autoComplete="off"
                    onChange={e => changeField('caseStudy', e.target.value)}
                    fullWidth
                    className={classes.textField}
                    error={displayErrors.caseStudy}
                    helperText={displayErrors.caseStudy && t("react.validation.casestudy.empty")}
                  />
                  <TextField 
                    margin="dense" 
                    id="description"
                    label={t("react.project.edit.description")}
                    variant='outlined'
                    multiline={true}
                    maxRows={5}
                    minRows={5}
                    type="text"
                    value={project.description}
                    autoComplete="off"
                    onChange={e => changeField('description', e.target.value)}
                    fullWidth
                    className={classes.textField}
                    helperText={project.description.length + '/1024'}
                    inputProps={{ maxLength: 1024 }}
                  />
                  <Coefficients
                    {...props}
                    coefficientsId={project.coefficientsId}
                    error={displayErrors.coefficientsId}
                    callbackAfterSelection={(handleCoefficientsIdChange)}
                    disabled={!['draft', 'inspectAnswers', 'validation'].includes(project.state)}
                  />
                  <TextField margin="dense" id="creationDate" label={t("react.project.edit.creation.date")} type="datetime-local" value={dateFormat(project.creationDate, "UTC:yyyy-mm-dd'T'HH:MM")} disabled={true} InputLabelProps={{ shrink: true, }} fullWidth className={classes.textField} />
                  <TextField margin="dense" id="updateDate" label={t("react.project.edit.update.date")} value={dateFormat(project.updateDate, "UTC:yyyy-mm-dd'T'HH:MM")} type="datetime-local" disabled={true} InputLabelProps={{ shrink: true, }} fullWidth className={classes.textField} />
                  {project.id > 0 && AuthService.isAdmin() &&
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom>
                          <WarningIcon className={classes.typographyIcon} color="error" /> {t("react.project.edit.admin.option")}
                        </Typography>
                        <TextField
                          select
                          fullWidth
                          label={t("react.project.edit.owner")}
                          value={project.userId}
                          onChange={e => changeField('userId', e.target.value)}
                          className={classes.SomeSpaceUnder}
                          inputProps={{
                            name: 'userIdForAdmin',
                            id: 'filled-userIdForAdmin-native-simple',
                          }}
                        >
                          <MenuItem value='0'>--- Undefined ---</MenuItem>
                          {allUsers
                            .filter(oneUser => oneUser.id === project.userId || oneUser.expirationDate > Date.now())
                            .map(oneUser => {
                              const name = `${oneUser.lastname} ${oneUser.firstname} (${allCustomers.find(c => c.id == oneUser.customerId).name})`
                              return (
                            <MenuItem key={'auou' + oneUser.id} value={oneUser.id}>{name}</MenuItem>
                          )})}
                        </TextField>
                        <TextField
                          select
                          fullWidth
                          label={t("react.project.edit.state")}
                          value={project.state}
                          onChange={e => changeField('state', e.target.value)}
                          inputProps={{
                            name: 'projectState',
                            id: 'filled-projectState-native-simple',
                          }}
                        >
                          <MenuItem value='draft'>draft</MenuItem>
                          <MenuItem value='validation'>validation</MenuItem>
                          <MenuItem value='codification'>codification</MenuItem>
                          <MenuItem value='grouping'>grouping</MenuItem>
                          <MenuItem value='completed'>completed</MenuItem>
                          <MenuItem value='cancelled'>cancelled</MenuItem>

                          <MenuItem value='666'>--- Do not change manually to states below ---</MenuItem>

                          <MenuItem value='inspectAnswers'>inspectAnswers (automatic state)</MenuItem>
                          <MenuItem value='inspectCodification'>inspectCodification (automatic state)</MenuItem>
                          <MenuItem value='scoring'>scoring (automatic state)</MenuItem>
                          <MenuItem value='error'>error (automatic state)</MenuItem>
                        </TextField>
                        <Typography style={{ marginTop: '10px' }}>
                          {t("react.project.edit.admin.option.note")}
                        </Typography>
                      </CardContent>
                    </Card>
                  }

                  {project.id > 0 && (project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                  <>
                    <PopupTwoButtons
                        variant='warning'
                        openState={showConfirmDeleteProjectPopup}
                        callbackOnclose={handleCancelDeleteProjectCollectedData}
                        callbackOnclickLeftButton={handleCancelDeleteProjectCollectedData}
                        callbackOnclickRightButton={handleConfirmDeleteProject}
                        title={t('react.project.edit.delete.confirm.title')}
                        content={t('react.project.edit.delete.confirm.content')}
                        leftButton={t('react.button.cancel')}
                        rightButton={t('react.button.delete')}
                        unlockCheckboxLabel={t('react.popuptwobuttons.delete.help.for.the.disabled')}
                    />
                    <Button variant="outlined" size="large" onClick={() => setShowConfirmDeleteProjectPopup(true)} color="primary" startIcon={<DeleteIcon />} className={classes.button} > {t("react.button.delete")} </Button>
                  </>
                  }

                  <Button variant="outlined" size="large" onClick={handleClickEditDialogCancel} color="primary" startIcon={<CloseIcon />} className={classes.button} > {t("react.button.cancel")} </Button>

                  {/* It's not possible for the user to change the project after validation - Admin cannot set an automatic state */}
                  {(['draft', 'validation'].includes(project.state) || (AuthService.isAdmin() && !['inspectAnswers', 'inspectCodification', 'scoring'].includes(project.state))) &&
                    <Button onClick={handleClickEditDialogConfirm} variant="outlined" size="large" color="primary" startIcon={<SaveIcon />} className={classes.button} > {t("react.button.save")} </Button>
                  }
                </Grid>
                <Grid item xs={6}>
                  { /* --- Label and buttons when the defaultSequence is in state 'draft' --- */ }

                  {defaultSequence !== undefined && defaultSequence.state === 'draft' && (project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                    <>
                      {/* be carefull: 'defaultSequenceHasAnswerFile' can be undefined */}
                      {defaultSequenceHasAnswerFile === false &&
                        <Box>
                          <Typography variant="h6" >
                            <NavigateNextIcon className={classes.typographyIcon} color="secondary" /> {t("react.project.edit.next.step")}
                            <Typography>
                              {t("react.project.edit.step.upload.data")}
                            </Typography>
                          </Typography>
                        </Box>
                      }
                      {defaultSequenceHasAnswerFile === true &&
                        <>
                        <Box>
                          <Typography variant="h6" >
                            <NavigateNextIcon className={classes.typographyIcon} color="secondary" />  {t("react.project.edit.next.step")}
                            <Typography>
                              {t("react.project.edit.step.start")}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box style={{ float: "right" }}>
                          <Tooltip title={t("react.project.edit.button.start.project")}>
                            <Button title="" variant="outlined" size="large" color="primary" startIcon={<PlayCircleFilledIcon />} className={classes.button}
                              onClick={(e) => handleClickSetDefaultSequenceNewState(e, 'inspectAnswers')}
                            > {t("react.project.edit.button.start.project")} </Button>
                          </Tooltip>
                        </Box>
                        </>
                      }
                    </>
                  }

                  { /* --- Label and buttons when the defaultSequence is in state 'validation' --- */ }

                  {defaultSequence !== undefined && defaultSequence.state === 'validation' && (project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                    <>
                      <Box>
                        <Typography variant="h6" >
                          <MonetizationOnIcon className={classes.typographyIcon} color="secondary" /> {t("react.project.edit.forecast")}
                        </Typography>
                        {!subscriptionForecast.loaded &&
                          <CircularProgress size={24}/>
                        }
                        {subscriptionForecast.loaded && subscriptionForecast.forecastValid &&
                          <Typography>
                            {t("react.project.edit.forecast.details", subscriptionForecast)}
                          </Typography>
                        }
                        {subscriptionForecast.loaded && !subscriptionForecast.forecastValid &&
                          <Typography>
                            {t("react.project.edit.forecast.problem")}
                          </Typography>
                        }
                      </Box>

                      {projectErrors.total > 0 &&
                        <Box>
                          <Divider className={classes.dividerSpace} />
                          <Typography variant="h6" >
                            <WarningIcon className={classes.typographyIcon} color="error" /> {t("react.project.edit.error.detected")} ({projectErrorsFrom + '-' + (projectErrorsFrom + projectErrors.hits.length)}/{projectErrors.total})
                          </Typography>
                          <Typography className={classes.SomeSpaceUnder}>
                            {t("react.project.edit.error.check.request")}
                          </Typography>
                          {projectErrors.hits.map(projectError => (
                            <Typography key={projectError.id}>
                              {t('react.' + projectError.errorKey, { 'offsetId': projectError.offsetId })}
                            </Typography>
                          ))}
                          <Tooltip title={t('react.button.previous')}>
                            <span>
                              <IconButton
                                disabled={projectErrorsFrom === 0}
                                onClick={e => handleClickPreviousErrors(e)}
                                size="large">
                                <KeyboardArrowLeftIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={t('react.button.next')}>
                            <span>
                              <IconButton
                                disabled={(projectErrorsFrom + projectErrorsSize) >= projectErrors.total}
                                onClick={e => handleClickNextErrors(e)}
                                size="large">
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      }

                      {subscriptionForecast.loaded && subscriptionForecast.forecastValid &&
                        <Box style={{ float: "right" }}>
                          <Tooltip title={t("react.project.edit.button.correct.project")}>
                            <Button title="" variant="outlined" size="large" color="primary" startIcon={<PlayCircleFilledIcon className={classes.rotate180} />} className={classes.button}
                              onClick={(e) => handleClickSetDefaultSequenceNewState(e, 'draft')}
                            > {t("react.project.edit.button.correct.project")} </Button>
                          </Tooltip>
                          <Tooltip title={t("react.project.edit.button.valid.project")}>
                            <Button title="" variant="outlined" size="large" color="primary" endIcon={<PlayCircleFilledIcon />} className={classes.button}
                              onClick={(e) => handleClickSetDefaultSequenceNewState(e, 'codification')}
                            > {t("react.project.edit.button.valid.project")} </Button>
                          </Tooltip>
                        </Box>
                      }
                    </>
                  }

                  { /* --- Label and buttons when the defaultSequence is in 'admin' states --- */ }

                  {defaultSequence !== undefined && ['inspectAnswers', 'codification', 'inspectCodification', 'scoring', 'grouping'].includes(defaultSequence.state) && (project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                    <Typography variant="h6" >
                      <TimelapseSharpIcon className={classes.typographyIcon} color="secondary" /> {t("react.project.edit.state.inprogress")}
                    </Typography>
                  }

                  { /* --- Label and buttons when the defaultSequence is in state 'completed' --- */ }

                  {defaultSequence !== undefined && defaultSequence.state === 'completed' && (project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                    <Box>
                      <Typography variant="h6" >
                        <StarIcon className={classes.typographyIcon} color="secondary" /> {t("react.project.edit.state.completed")}
                      </Typography>

                      <Tooltip title={t("react.project.edit.view.results")}>
                        <Button title="" variant="outlined" size="large" startIcon={<BarChartIcon />} className={classes.button} color="secondary"
                          onClick={() => props.navigate(`/projects/${project.id}/dashboard`)}
                        > {t("react.project.edit.view.results")} </Button>
                      </Tooltip>

                      <Tooltip title={t("react.project.edit.view.results.analytics")}>
                        <Button title="" variant="outlined" size="large" startIcon={<WebIcon />} className={classes.button} color="secondary"
                          onClick={() => props.navigate(`/projects/${project.id}/dashboard/analytics`)}
                        > {t("react.project.edit.view.results.analytics")} </Button>
                      </Tooltip>
                    </Box>
                  }

                  { /* --- Label and buttons when the defaultSequence is in state 'error' --- */ }

                  {defaultSequence !== undefined && defaultSequence.state === 'error' && (project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                    <Box>
                      <Typography variant="h6" >
                        <WarningIcon className={classes.typographyIcon} color="error" /> {t("react.project.edit.state.inerror")}
                      </Typography>
                    </Box>
                  }
                </Grid>
              </Grid>
            </Grid>
          }
          {tabValue === 1 && <PanelFiles {...props} project={project} />}
          {tabValue === 2 && <PanelMultiAnswers {...props} project={project} handleReloadProjectPage={handleReloadProjectPage} setDefaultSequenceHasAnswerFile={setDefaultSequenceHasAnswerFile} />}
          {tabValue === 3 && <PanelVisitors {...props} project={project} />}
          {tabValue === 4 && <PanelCodif {...props} project={project} handleClickSetDefaultSequenceNewState={handleClickSetDefaultSequenceNewState} />}
          {tabValue === 5 && <PanelFilters {...props} project={project} />}
          {tabValue === 666 && <PanelAdmin {...props} project={project} handleClickSetDefaultSequenceNewState={handleClickSetDefaultSequenceNewState} />}
        </Grid>
      </Paper>
    </div>
  );
}
