import React, { useState, useContext, useEffect,useReducer } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles, withStyles } from 'tss-react/mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { red } from '@mui/material/colors';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import MenuItem from '@mui/material/MenuItem';
import { SurveyContext } from './context';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import i18next from 'i18next';
import { Divider, IconButton, List, ListItem, ListSubheader } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { appendSuffixIfGreaterThan } from '../../utils/utils.js';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ParamsVariablesDialog from './ParamsVariablesDialog.js';
import MoreIcon from '@mui/icons-material/More';
import { AuthService } from '../../services/AuthService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import WarningIcon from '@mui/icons-material/Warning';
import { localeNamesAndCodes, renderLocaleNames } from '../../utils/surveysUtils.js';

const useStyles = makeStyles()(theme => ({
    paper: {
        padding: '20px'
    },
    marginParams: {
        marginBottom: 20,
        padding: 15,
    },
    formName: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    languageSelector: {
        marginBottom: theme.spacing(1),
        width: 300
    },
    marginTopMaxAnswers: {
        marginTop: 20,
    },
    boxAccordion: {
        fontWeight: 400,
        marginTop: 10,
        marginLeft: -15,
        marginRight: -15,
    },
    textFieldUrl: {
        marginTop: 15
    },
    addDefaultMsg: {
        position: 'absolute', 
        bottom: 25, 
        right: 2, 
        zIndex: 1, 
        color: "#015463"
    },
    addNewAnswerButton: {
        color: '#003945',
        width: 35,
        marginLeft: 5
    },
    deleteNewAnswerButton: {
        color: "rgba(0, 57, 69, 0.8)",
        width: 35,
        marginLeft: 5,
        marginTop: -2
    },
    editNewAnswerButton: {
        color: "rgba(0, 57, 69, 0.8)",
        width: 35,
        marginLeft: 0,
        marginRight: -8,
        marginTop: -2
    },
    editNewAnswerButtonCheckIcon: {
        color: "rgba(0, 57, 69, 0.8)",
        width: 35,
        marginLeft: 0,
        marginRight: -8,
        marginTop: -2
    },
    arrowGlobalVariablesTextField: {
        marginLeft: 10, 
        marginRight: 10,
        marginTop: 6,
        color: '#015463'
    },
    arrowGlobalVariablesDiv: {
        marginLeft: 10, 
        marginRight: 10,
        marginTop: 1,
        color: '#015463'
    },
    listSubheader: {
        marginTop: 15,
        marginBottom: 10,
        paddingLeft: 0,
    },
    listItem: {
        paddingLeft: 5,
        paddingRight: 8,
        paddingTop: 3,
        paddingBottom: 3,
        width: '100%',
    },
    listItemText1: {
        width: '15%'
    },
    listItemText2: {
        width: '80%'
    },
    paddingLeft: {
        marginLeft: 10,
    },
    listItemSecondaryAction: {
        margin: 0,
        padding: 0,
        right: 2,
    },
    typographyIcon: {
        verticalAlign: 'sub',
    },
    adminZone: {
        marginTop: 30,
        backgroundColor: red[50],
    },
}));

const RedSwitch = withStyles(Switch, (_theme, _params, classes) => ({
    switchBase: {
        [`&.${classes.checked}`]: {
            color: red[500],
        },
        [`&.${classes.checked} + .${classes.track}`]: {
            backgroundColor: red[500],
        },
    },
    checked: {},
    track: {},
}));

export default function Params(props) {

    const { classes } = useStyles();

    const {
        t,
        allUsers,
        allCustomers,
        numberOfAvailableCellsToCarryOut
    } = props;
    
    const { getFormConfiguration, setNeedToSave, setFormActivityStatus } = useContext(SurveyContext);

    const [switchMaxAnswers, setSwitchMaxAnswers] = useState(getFormConfiguration().nbMaxAnswers === 0 ? false : true)
    const [displayMessages, setDisplayMessages] = useState(false);
    const [displayRedirectionUrls, setDisplayRedirectionUrls] = useState(false);
    const [openDialogVariables, setOpenDialogVariables] = useState(false);
    const [editingGlobalValue, setEditingGlobalValue] = useState(-1);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [isNewVariable, setIsNewVariable] = useState(false);

    const getTranslationForLanguage = (key, language) => {
        return i18next.t(key, { lng: language });
    };

    useEffect(() => {
        if (numberOfAvailableCellsToCarryOut <= 1) {
            getFormConfiguration().nbCellsToCarryOut = numberOfAvailableCellsToCarryOut;
        } else if (getFormConfiguration().nbCellsToCarryOut === 0 || getFormConfiguration().nbCellsToCarryOut > numberOfAvailableCellsToCarryOut) {
            getFormConfiguration().nbCellsToCarryOut = numberOfAvailableCellsToCarryOut;
        } 
    }, [numberOfAvailableCellsToCarryOut])

    const changeField = (fieldName, value) => {
        switch (fieldName) {
            case "active":
                let newActiveState = !getFormConfiguration().active;
                getFormConfiguration().active = newActiveState;
                forceUpdate();
                // directly call the API for change status (no call to setNeedToSave(true))
                setFormActivityStatus(newActiveState);
                return;
                // NOTE: as there is an immediate return, the break below is not necessary
                // break;
            case "name":
                getFormConfiguration().name = value;
                break;
            case "clientName":
                getFormConfiguration().clientName = value;
                break;
            case "description":
                getFormConfiguration().description = value;
                break;
            case "sequenceType":
                getFormConfiguration().sequenceType = value;
                break;
            case "nbCellsToCarryOut":
                getFormConfiguration().nbCellsToCarryOut = value;
                break;
            case "lang":
                const translationQuotafull = getTranslationForLanguage('react.surveys.parameters.quotafullMessage.placeholder', getFormConfiguration().lang);
                const translationScreenout = getTranslationForLanguage('react.surveys.parameters.screenoutMessage.placeholder', getFormConfiguration().lang);
                if (getFormConfiguration().quotafullMessage === translationQuotafull) {
                    // If the quotafull message is the same as default in previous language, update the message
                    getFormConfiguration().quotafullMessage = getTranslationForLanguage('react.surveys.parameters.quotafullMessage.placeholder', value)
                }
                if (getFormConfiguration().screenoutMessage === translationScreenout) {
                    // If the screenout message is the same as default in previous language, update the message
                    getFormConfiguration().screenoutMessage = getTranslationForLanguage('react.surveys.parameters.screenoutMessage.placeholder', value)
                }
                getFormConfiguration().lang = value;
                break;
            case "answersMaxNumber":
                getFormConfiguration().nbMaxAnswers = value;
                break;
            case "quotafullMessage":
                getFormConfiguration().quotafullMessage = value;
                break;
            case "screenoutMessage":
                getFormConfiguration().screenoutMessage = value;
                break;
            case "onOngoingPingUrl":
                getFormConfiguration().onOngoingPingUrl = value;
                break;
            case "onQuotafullPingUrl":
                getFormConfiguration().onQuotafullPingUrl = value;
                break;
            case "onScreenoutPingUrl":
                getFormConfiguration().onScreenoutPingUrl = value;
                break;
            case "onCompletedPingUrl":
                getFormConfiguration().onCompletedPingUrl = value;
                break;
            case "switchMaxNumber":
                var isNewSwitchMaxAnswersActive = !switchMaxAnswers;
                setSwitchMaxAnswers(isNewSwitchMaxAnswersActive);
                if(!isNewSwitchMaxAnswersActive) {
                    // reset nbMaxAnswers to 0 when switch turn off
                    getFormConfiguration().nbMaxAnswers = 0;
                }
                break;
            case "deleteVariableForm": 
                getFormConfiguration().variables.splice(editingGlobalValue, 1);
                break;
            case "userId":
                getFormConfiguration().userId = value;
                break;
            case "displayProgressBar":
                getFormConfiguration().displayProgressBar = !getFormConfiguration().displayProgressBar;
                break;
            }
        setNeedToSave(true);
        forceUpdate();
    };

    const addDefaultQuotafull = () => {
        getFormConfiguration().quotafullMessage = getTranslationForLanguage('react.surveys.parameters.quotafullMessage.placeholder', getFormConfiguration().lang);
        setNeedToSave(true);
        forceUpdate();
    }

    const addDefaultScreenout = () => {
        getFormConfiguration().screenoutMessage = getTranslationForLanguage('react.surveys.parameters.screenoutMessage.placeholder', getFormConfiguration().lang);
        setNeedToSave(true);
        forceUpdate();
    }

    const selectVariable = (subindex) => {
        setEditingGlobalValue(subindex);
    };

    const openParamsVariable = (isNew) => {
        setIsNewVariable(isNew);
        setOpenDialogVariables(true);
    };

    return (
        <Paper className={classes.marginParams}>
            {(editingGlobalValue !== -1 || isNewVariable) &&
                <ParamsVariablesDialog
                    {...props}
                    answerIndex={editingGlobalValue}
                    openDialogVariables={openDialogVariables}
                    setOpenDialogVariables={setOpenDialogVariables}
                    isNewVariable={isNewVariable}
                    setIsNewVariable={setIsNewVariable}
                />
            }
            <Typography variant="h6" component="h6" style={{ marginBottom: '10px' }}>
                {t("react.project.collectforms.parameters.title")}
            </Typography>
            <FormControl className={classes.formName}>     
                <TextField
                    id="formName"
                    value={getFormConfiguration().name}
                    onChange={e => changeField('name', e.target.value)}
                    label={t("react.project.collectforms.parameters.formName")}
                    type='text'
                    fullWidth
                    autoComplete="off"
                    inputProps={{ max: 128, min: 1 }}
                    error={getFormConfiguration().name.length <= 0}
                />
            </FormControl>
            <br />
            <FormControl className={classes.formName}>     
                <TextField
                    id="clientName"
                    value={getFormConfiguration().clientName}
                    onChange={e => changeField('clientName', e.target.value)}
                    label={t("react.project.collectforms.parameters.form.clientname")}
                    type='text'
                    fullWidth
                    autoComplete="off"
                    inputProps={{ max: 128, min: 1 }}
                  />
            </FormControl>
            <br />
            <FormControl style={{ marginBottom: '10px', width: '100%' }}>
                <TextField 
                    margin="dense" 
                    id="description"
                    label={t("react.project.collectforms.parameters.form.description")}
                    variant='outlined'
                    multiline={true}
                    maxRows={5}
                    minRows={5}
                    type="text"
                    value={getFormConfiguration().description}
                    autoComplete="off"
                    onChange={e => changeField('description', e.target.value)}
                    helperText={getFormConfiguration().description ? getFormConfiguration().description.length + '/1024' : '0/1024'}
                    inputProps={{ maxLength: 1024 }}
                  />
            </FormControl>
            <br/>
            <FormControlLabel style={{ marginLeft: '0px', marginBottom: '15px' }}
                control={<RedSwitch
                    checked={getFormConfiguration().active}
                    color="secondary"
                    onChange={e => changeField('active', e.target.value)}
                    disabled={getFormConfiguration().id <= 0}
                />}
                label={t("react.project.collectforms.parameters.form.isactive")}
                labelPlacement="start"
            />
            <br />
            <FormControlLabel style={{ marginLeft: '0px', marginBottom: '15px' }}
                control={<RedSwitch
                    checked={getFormConfiguration().displayProgressBar}
                    color="secondary"
                    onChange={e => changeField('displayProgressBar', e.target.value)}
                    disabled={getFormConfiguration().id <= 0}
                />}
                label={t("react.project.collectforms.parameters.form.displayProgressBar")}
                labelPlacement="start"
            />
            <br />
            <FormControl className={classes.languageSelector} style={{ marginBottom: '30px' }}>
                <TextField
                    select
                    label="Language"
                    id="formLanguage-select"
                    value={getFormConfiguration().lang}
                    onChange={e => changeField('lang', e.target.value)}
                >
                    {localeNamesAndCodes.map(language => (
                        <MenuItem key={language.code} value={language.code} >
                            {renderLocaleNames(language.code)}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <FormLabel component="legend" sx={{color: '#000'}}>{t("react.project.collectforms.parameters.sequencetype")}</FormLabel>
            <RadioGroup row name="sequenceType" value={getFormConfiguration().sequenceType}
                onChange={e => changeField('sequenceType', e.target.value)}
            >
                <FormControlLabel value="order" control={<Radio />} label={t("react.project.collectforms.parameters.sequencetype.order")} disabled={numberOfAvailableCellsToCarryOut <= 1}/>
                {/* [R3MSCORE-556] Hide "rotate" mode and replace by experience plan in the future */}
                {/*<FormControlLabel value="rotate" control={<Radio />} label={t("react.project.collectforms.parameters.sequencetype.rotate")} />*/}
                <FormControlLabel value="random" control={<Radio />} label={t("react.project.collectforms.parameters.sequencetype.random")} disabled={numberOfAvailableCellsToCarryOut <= 1}/>
                <FormControlLabel value="pairing" control={<Radio />} label={t("react.project.collectforms.parameters.sequencetype.pairing")} disabled={numberOfAvailableCellsToCarryOut <= 1}/>
            </RadioGroup>
            {getFormConfiguration().sequenceType !== "order" &&
                <div>
                    <FormLabel component="legend" sx={{ marginTop: '15px',color: '#000' }}>{t("react.project.collectforms.parameters.nbCellsToCarryOut")}</FormLabel>
                    <TextField
                        error={getFormConfiguration().nbCellsToCarryOut <= 0}
                        inputProps={{ max: numberOfAvailableCellsToCarryOut, min: 1 }}
                        style={{ width: '50px', marginTop: '5px' }}
                        type='number'
                        value={getFormConfiguration().nbCellsToCarryOut}
                        onChange={e => changeField('nbCellsToCarryOut', e.target.value)}
                        disabled={numberOfAvailableCellsToCarryOut <= 1}
                    />
                </div>
            }
            <div className={classes.marginTopMaxAnswers}>
                <FormControlLabel style={{ marginLeft: '0px' }}
                    control={<Switch
                        checked={switchMaxAnswers}
                        color="secondary"
                        onChange={e => changeField('switchMaxNumber', e.target.value)}
                    />}
                    label={t("react.project.collectforms.parameters.switch.maximumNumber")}
                    labelPlacement="start"
                />
                <br />
                {switchMaxAnswers &&
                    <TextField
                        value={getFormConfiguration().nbMaxAnswers}
                        onChange={e => changeField('answersMaxNumber', e.target.value)}
                        type="number"
                        error={getFormConfiguration().nbMaxAnswers == 0}
                        placeholder='1000'
                        helperText={getFormConfiguration().nbMaxAnswers == 0 ? t("react.project.collectforms.parameters.error.maximumNumber") : ""}
                    />
                }
            </div>

            <MenuItem className={classes.boxAccordion} onClick={() => setDisplayMessages(!displayMessages)}>
                <span>{t("react.surveys.parameters.display.messages")}</span>
                {!displayMessages ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </MenuItem>

            <Collapse in={displayMessages}>
                <div style={{position: 'relative'}}>
                    <TextField
                        key='quotafullMessage'
                        variant='outlined'
                        label={t("react.surveys.parameters.quotafullMessage")}
                        fullWidth
                        multiline={true}
                        minRows='8'
                        className={classes.marginTopMaxAnswers}
                        value={getFormConfiguration().quotafullMessage}
                        onChange={e => changeField('quotafullMessage', e.target.value)}
                        inputProps={{ maxLength: 255 }}
                        InputLabelProps={{ shrink: true, }}
                        helperText={`${(getFormConfiguration().quotafullMessage || '').length} / 255`}
                    />
                    <Tooltip title={t("react.surveys.parameters.tooltip.quotafullMessage")}><IconButton onClick={() => addDefaultQuotafull()} className={classes.addDefaultMsg}><AddCircleIcon/></IconButton></Tooltip>
                </div>
                <div style={{position: 'relative'}}>
                    <TextField
                        key='screenoutMessage'
                        variant='outlined'
                        label={t("react.surveys.parameters.screenoutMessage")}
                        fullWidth
                        multiline={true}
                        minRows='8'
                        className={classes.marginTopMaxAnswers}
                        value={getFormConfiguration().screenoutMessage}
                        onChange={e => changeField('screenoutMessage', e.target.value)}
                        inputProps={{ maxLength: 255 }}
                        InputLabelProps={{ shrink: true, }}
                        helperText={`${(getFormConfiguration().screenoutMessage || '').length} / 255`}
                    />
                    <Tooltip title={t("react.surveys.parameters.tooltip.screenoutMessage")}><IconButton onClick={() => addDefaultScreenout()} className={classes.addDefaultMsg}><AddCircleIcon/></IconButton></Tooltip>
                </div>
            </Collapse>

            <MenuItem className={classes.boxAccordion} onClick={() => setDisplayRedirectionUrls(!displayRedirectionUrls)}>
                <span>{t("react.surveys.parameters.display.redirection.urls")}</span>
                {!displayRedirectionUrls ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </MenuItem>

            <Collapse in={displayRedirectionUrls}>
                <div className={classes.textFieldUrl}>
                    <TextField
                        value={getFormConfiguration().onOngoingPingUrl}
                        onChange={e => changeField('onOngoingPingUrl', e.target.value)}
                        label={t("react.surveys.parameters.onOngoingPingUrl")}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
                <div className={classes.textFieldUrl}>
                    <TextField
                        value={getFormConfiguration().onQuotafullPingUrl}
                        onChange={e => changeField('onQuotafullPingUrl', e.target.value)}
                        label={t("react.surveys.parameters.onQuotafullPingUrl")}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
                <div className={classes.textFieldUrl}>
                    <TextField
                        value={getFormConfiguration().onScreenoutPingUrl}
                        onChange={e => changeField('onScreenoutPingUrl', e.target.value)}
                        label={t("react.surveys.parameters.onScreenoutPingUrl")}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
                <div className={classes.textFieldUrl}>
                    <TextField
                        value={getFormConfiguration().onCompletedPingUrl}
                        onChange={e => changeField('onCompletedPingUrl', e.target.value)}
                        label={t("react.surveys.parameters.onCompletedPingUrl")}
                        fullWidth
                        variant="outlined"
                        size="small"
                    />
                </div>
            </Collapse>

            <Divider style={{paddingTop: '10px'}}/>
            <div>
                <List dense subheader={<ListSubheader className={classes.listSubheader}>
                    <Typography variant="subtitle2">{t("react.surveys.parameters.globalVariables")}</Typography>
                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                        <Tooltip title={t('react.project.collectforms.addvariable.tooltip')}>
                            <IconButton
                                component='span'
                                onClick={() => openParamsVariable(true)}
                                size='small'>
                                <AddIcon fontSize='small' color='action'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.editvariable.tooltip")}>
                            <IconButton
                                component='span'
                                onClick={() => openParamsVariable('')}
                                disabled={editingGlobalValue === -1}
                                size='small'>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("react.project.collectforms.deletevariable.tooltip")}>
                            <IconButton
                                component='span'
                                onClick={e => changeField('deleteVariableForm')}
                                disabled={editingGlobalValue === -1}
                                size='small'>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListSubheader>}>
                {getFormConfiguration().variables.length === 0 && <Typography variant='body2' >{t('react.surveys.no-variable')}</Typography>}
                {getFormConfiguration().variables.map((variable, subindex) => (
                    <ListItem
                        key={`var-subindex-${subindex}`}
                        onClick={() => selectVariable(subindex)}
                        selected={editingGlobalValue === subindex}
                        button
                        className={classes.listItem}
                    >
                        <ListItemText id={variable.code} primary={`[${variable.code}]`} className={classes.listItemText1} />
                        <ListItemText id={variable.code + subindex} primary={appendSuffixIfGreaterThan(variable.value, '...', 55)} className={classes.listItemText2} />
                        <Tooltip title={variable.description}>
                            <MoreIcon fontSize='small' color='disabled' className={classes.paddingLeft}/>
                        </Tooltip>
                    </ListItem>
                ))}

                {getFormConfiguration().id > 0 && AuthService.isAdmin() &&
                    <Card className={classes.adminZone}>
                      <CardContent>
                        <Typography gutterBottom>
                          <WarningIcon className={classes.typographyIcon} color="error" /> {t("react.survey.edit.admin.option")}
                        </Typography>
                        <TextField
                          select
                          fullWidth
                          label={t("react.survey.edit.owner")}
                          value={getFormConfiguration().userId}
                          onChange={e => changeField('userId', e.target.value)}
                          className={classes.SomeSpaceUnder}
                          inputProps={{
                            name: 'userIdForAdmin',
                            id: 'filled-userIdForAdmin-native-simple',
                          }}
                        >
                          <MenuItem value='0'>--- Undefined ---</MenuItem>
                          {allUsers
                            .filter(oneUser => oneUser.id === getFormConfiguration().userId || oneUser.expirationDate > Date.now())
                            .map(oneUser => {
                              const name = `${oneUser.lastname} ${oneUser.firstname} (${allCustomers.find(c => c.id == oneUser.customerId).name})`
                              return (
                            <MenuItem key={'auou' + oneUser.id} value={oneUser.id}>{name}</MenuItem>
                          )})}
                        </TextField>
                        <Typography style={{ marginTop: '10px' }}>
                          {t("react.survey.edit.admin.option.note")}
                        </Typography>
                      </CardContent>
                    </Card>
                  }
                </List>
            </div>
        </Paper >
    )
}