import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { RestClientService } from '../../services/RestClientService';
import SequenceService from '../../services/SequenceService';
import ProjectService from '../../services/ProjectService';
import CodificationService from '../../services/CodificationService';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import { AuthService } from '../../services/AuthService';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Box from '@mui/material/Box';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';
import SaveIcon from '@mui/icons-material/Save';
import TimelapseSharpIcon from '@mui/icons-material/TimelapseSharp';
import StarIcon from '@mui/icons-material/Star';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LockIcon from '@mui/icons-material/Lock';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadData, MIN_SEQUENCE_LENGTH } from '../../Constants.js'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import CircularProgress from '@mui/material/CircularProgress';
import dateFormat from 'dateformat';
import { cloneDeep, sleep } from '../../utils/utils.js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { red } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Switch from '@mui/material/Switch';
import Dropzone from "../shared/Dropzone";

const useStyles = makeStyles()(theme => ({
  helpButton: {
    color: '#66bb6a',
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: '15px 15px 15px 0px',
  },
  adminZone: {
    backgroundColor: red[50],
    padding: '20px 20px 20px 20px',
  },
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    padding: theme.spacing(2),
  },
  rotate180: {
    transform: 'rotate(-180deg)',
  },
  SomeSpaceUnder: {
    marginBottom: theme.spacing(2),
  },
  SomeSpaceLeft: {
    marginLeft: theme.spacing(2),
  },
  typographyIcon : {
    verticalAlign: 'sub',
  },
  invalidNameError: {
    color: 'red',
    fontSize: '0.7em',
    opacity: '0.8',
    display: 'flex',
    alignItems: 'center',
  },
  flexRowSequenceName: {
    display: 'flex',
  },
  dropzoneArea: {
    backgroundColor: '#F0F0F0',
  },
  select: {
    minWidth: '350px'
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    float: 'right',
    marginBottom: theme.spacing(2),
  },
  formControlLabel: {
    marginLeft: 0,
    marginTop: '10px',
},
}));

const sequenceService = new SequenceService();
const projectService = new ProjectService();
const codificationService = new CodificationService();

const emptySubscriptionForecast = {
  'loaded': false,
  'nbObservationsForecast': 0 ,
  'nbRemainingCustomerObservations': 0,
  'nbProjectsForecast': 0,
  'nbRemainingCustomerProjects' : 0,
  'forecastValid': false
};

export default function Sequence(props) {

    const { sequenceNumber, sequence, isExpanded, project, t, openSnackbar, showSpinner,
      setDefaultSequenceHasAnswerFile, handleReloadSequencesPanel, handleClickCheckBox, handleReloadProjectPage
    } = props;

    const { classes } = useStyles();

    const [isDefaultSequence] = useState(sequenceNumber === 1);
    const [existingFiles, setExistingFiles] = useState([]);
    const [dropzoneFiles, setDropzoneFiles] = useState([]);

    const [existingFilterFiles, setExistingFilterFiles] = useState([]);
    const [dropzoneFilterFiles, setDropzoneFilterFiles] = useState([]);

    const [sequenceName, setSequenceName] = useState(sequence.name);
    const [sequenceStateForAdmin, setSequenceStateForAdmin] = useState(sequence.state);

    const [subscriptionForecast, setSubscriptionForecast] = useState(cloneDeep(emptySubscriptionForecast));

    const [codificationLibraries, setCodificationLibraries] = useState({});
    const [selectedCodificationLibraryId, setSelectedCodificationLibraryId] = useState(project.codificationLibraryId);

    const isUploadButtonDisabled = dropzoneFiles.length === 0;

    const [openConfirmDelete, setOpenConfirmDelete] = useState(0);

    const [loadData, setLoadData] = useState(LoadData.Load);
    useEffect(() => {
      if(loadData !== LoadData.Load) return;

      setLoadData(LoadData.Loading);

      if(sequence.id > 0) {
        var promises = [];
        promises.push(sequenceService.fetchAnswersFile(sequence.id));
        promises.push(sequenceService.fetchFiltersFile(sequence.id));

        if(AuthService.isAdmin()) {
          promises.push(codificationService.fetchLibraries());
        }

        Promise.all(promises)
        .then(results => {
          var result = results[0];
          setExistingFiles(result.data.hits);
          if(result.data.total > 0 && isDefaultSequence) {
            // maintains number of uploaded files in order to display the "start my project" button
            setDefaultSequenceHasAnswerFile(true);
          }

          var result = results[1];
          setExistingFilterFiles(result.data.hits);

          if(AuthService.isAdmin()) {
            result = results[2];
            var _paths = Object.entries(result.data.paths).sort((a, b) => {return a[1].localeCompare(b[1])});
            setCodificationLibraries(_paths);
          }
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.while.loading"));
        }).finally(() => {
          setLoadData(LoadData.Loaded);
        });
      } else {
        setLoadData(LoadData.Loaded);
      }
    }, [loadData, sequence, isDefaultSequence]);

    useEffect(() => {
      // do nothing if the project is not loaded
      if (loadData !== LoadData.Loaded) return;

      if(sequence.id > 0 && sequence.state === 'validation') {
        sequenceService.fetchSequenceSubscriptionForecast(sequence.id)
        .then(result => {
          setSubscriptionForecast(cloneDeep(result.data, { loaded: true }));
        })
        .catch(err => {
          setSubscriptionForecast(cloneDeep(emptySubscriptionForecast, { loaded: true, reason: 'project.error.subscription.not.available'} ));
        });
      } else {
        setSubscriptionForecast(cloneDeep(emptySubscriptionForecast));
      }
    }, [loadData]);

    const handleClickRenameSequence = (event) => {
      event.preventDefault();
      showSpinner(true);

      // Get the sequence, change the name and save
      sequenceService.fetchSequence(sequence.id)
      .then(getResult => {
        const cloneSequence = cloneDeep(getResult.data, {'name': sequenceName});
        sequenceService.saveSequence(cloneSequence)
        .then(saveResult => {
          sequence.name = saveResult.data.name;
          setSequenceName(saveResult.data.name);
          sequence.state = saveResult.data.state;
          setSequenceStateForAdmin(saveResult.data.state);
          openSnackbar('success', t("react.project.success.rename.sequence"));
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.rename.sequence"));
        });
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.rename.sequence"));
      })
      .finally(function() {
        showSpinner(false);
      });
    };

    const handleClickReplaceSequenceState = (event) => {
      event.preventDefault();
      showSpinner(true);

      // Get the sequence, change the state and save
      sequenceService.fetchSequence(sequence.id)
      .then(getResult => {
        const cloneSequence = cloneDeep(getResult.data, {'state': sequenceStateForAdmin});
        sequenceService.saveSequence(cloneSequence)
        .then(saveResult => {
          sequence.name = saveResult.data.name;
          setSequenceName(saveResult.data.name);
          sequence.state = saveResult.data.state;
          setSequenceStateForAdmin(saveResult.data.state);
          openSnackbar('success', t("react.project.success.changestate.sequence"));
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.changestate.sequence"));
        });
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.changestate.sequence"));
      })
      .finally(function() {
        showSpinner(false);
      });
    };

    const handleOnChangeInDropzoneArea = (files) => {
      setDropzoneFiles(files);
    };

    const handleOnChangeInDropzoneFilterArea = (files) => {
      setDropzoneFilterFiles(files);
    };

    const handleClickUploadFilesInDropzoneArea = async (event) => {
      event.preventDefault();
      showSpinner(true);

      let reloadPanel = false;
      let sequenceId = sequence.id;

      if(sequenceId === 0) {
        reloadPanel = true;
        // we need to create the sequence before
        const cloneSequence = cloneDeep(sequence, {'name': sequenceName});
        // wait for the creation
        let saveResult = await sequenceService.saveSequence(cloneSequence);
        sequenceId = saveResult.data.id;

        sequence.name = saveResult.data.name;
        setSequenceName(saveResult.data.name);
        sequence.state = saveResult.data.state;
        setSequenceStateForAdmin(saveResult.data.state);
      }

      var promises = [];
      for(var i = 0; i < dropzoneFiles.length; i++) {
        promises.push(sequenceService.uploadAnswersFile(sequenceId, dropzoneFiles[i]));
      }

      Promise.all(promises)
      .then(function(results) {
        if(reloadPanel) {
          handleReloadSequencesPanel();
        } else {
          setLoadData(LoadData.Load);
        }
      })
      .catch(err => {
        // message si le nom est le meme et existe déjà
        openSnackbar('error', t("react.project.error.upload.file"));
      })
      .finally(function() {
        showSpinner(false);
      });
    };

    const handleClickUploadFilesInDropzoneFilterArea = async (event) => {
      event.preventDefault();
      showSpinner(true);

      var promises = [];
      for(var i = 0; i < dropzoneFilterFiles.length; i++) {
        promises.push(sequenceService.importFiltersFile(sequence.id, dropzoneFilterFiles[i]));
      }

      Promise.all(promises)
      .then(function(results) {
        openSnackbar('success', t("react.project.success.import.filter.file"));
        setLoadData(LoadData.Load);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.upload.file"));
      })
      .finally(function() {
        showSpinner(false);
      });
    };

    const handleDeleteProjectFile = () => {
      let projectFileId = openConfirmDelete;
      setOpenConfirmDelete(0);
  
      showSpinner(true);

      sequenceService.deleteAnswersFile(projectFileId)
      .then(result => {
        setLoadData(LoadData.Load);

        if(isDefaultSequence) {
          setDefaultSequenceHasAnswerFile(false);
        }
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.delete.file"));
      })
      .finally(function() {
        showSpinner(false);
      });
    };

    const handleClickDownloadAnswerFile = (event, answerFileId) => {
      event.preventDefault();
      let restClient = RestClientService.getClient();
      restClient.get('/api/files/v1.0/answers/' + answerFileId, {responseType: 'blob'})
        .then(response => {
          var regExp = new RegExp('filename="([^"]+)"', 'i');
          let filename = regExp.exec(response.headers['content-disposition'])[1];
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.download.file"));
        });
    };

    const handleClickFireNewSequenceState = (event, newState) => {
      event.preventDefault();

      if(sequence.id === 0 || newState === undefined || newState === '') {
        // security point
        return;
      }

      showSpinner(true);

      sequenceService.updateSequenceState(sequence.id, newState)
      .then(result => {
        handleReloadSequencesPanel();
        openSnackbar('success', t("react.project.success.update.sequence.state"));
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.update.sequence.state"));
      })
      .finally(function() {
        showSpinner(false);
      });
    };

    const handleClickRunCodification = async (event) => {
      event.preventDefault();

      showSpinner(true);

      // attach the codification library to the project
      if(isDefaultSequence && project.codificationLibraryId === 0) {
        try {
          const response = await projectService.setCodificationLibraryId(project.id, selectedCodificationLibraryId);
          if (response.status !== 204) {
            throw Error(response.statusText);
          }
        } catch (e) {
          showSpinner(false);
          openSnackbar('error', t("react.project.edit.codiftab.error.while.firing.codif"));

          return;
        }
      }

      sequenceService.updateSequenceState(sequence.id, 'inspectCodification')
        .then(response => {
          openSnackbar('info', t("react.project.edit.codiftab.codif.inprogress"));

          // wait 10s for updates
          sleep(10000 /* in millis */).then(() => {
            showSpinner(false);
            handleReloadProjectPage();
          });
        }).catch(err => {
          showSpinner(false);
          openSnackbar('error', t("react.project.edit.codiftab.error.while.firing.codif"));
        });
    };

    const handleClickFormControlCheckBox = (event) => {
      event.stopPropagation();
      handleClickCheckBox(sequence.id);
    };

    const handleClickFormControlLabel = (event) => {
      event.preventDefault();
    };

    const handleClickPrecodeNonImpactingUnknownWords = async (event) => {
      event.preventDefault();

      showSpinner(true);

      const newValue = !sequence.precodeNonImpactingUnknownWords;

      sequenceService.updatePrecodeNonImpactingUnknownWords(sequence.id, newValue)
        .then(response => {
          sequence.precodeNonImpactingUnknownWords = newValue;
          showSpinner(false);
        }).catch(err => {
          showSpinner(false);
          openSnackbar('error', t("react.generic.error.while.saving"));
        });
    };

    const handleClickPrecodeMisspelledUnknownWords = async (event) => {
      event.preventDefault();

      showSpinner(true);

      const newValue = !sequence.precodeMisspelledUnknownWords;

      sequenceService.updatePrecodeMisspelledUnknownWords(sequence.id, newValue)
        .then(response => {
          sequence.precodeMisspelledUnknownWords = newValue;
          showSpinner(false);
        }).catch(err => {
          showSpinner(false);
          openSnackbar('error', t("react.generic.error.while.saving"));
        });
    };

    if(isDefaultSequence && loadData !== LoadData.Loaded) {
      return (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      );
    }

    if(loadData !== LoadData.Loaded) {
      return (
        <Grid container>
        </Grid>
      );
    }

    const listExistingFiles = (listOfFile, deletable) => {
      return listOfFile.map((f) =>
        <ListItem button key={f.id}>
          <ListItemAvatar onClick={(e) => handleClickDownloadAnswerFile(e, f.id)}>
            <Avatar title={t("react.project.edit.datatab.button.download")}>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={f.name}
            secondary={f.description}
            onClick={(e) => handleClickDownloadAnswerFile(e, f.id)}
          />
          <ListItemSecondaryAction>
            {deletable &&
            <IconButton
              title={t("react.project.edit.datatab.button.delete")}
              edge="end"
              onClick={(e) => setOpenConfirmDelete(f.id)}
              size="large">
              <DeleteIcon />
            </IconButton>
            }
          </ListItemSecondaryAction>
        </ListItem>
      );
    };

    return (
      <div className={classes.root} >
        <PopupTwoButtons
            variant='question'
            openState={openConfirmDelete !== 0}
            callbackOnclose={() => setOpenConfirmDelete(0)}
            callbackOnclickLeftButton={() => setOpenConfirmDelete(0)}
            callbackOnclickRightButton={handleDeleteProjectFile}
            title={t('react.confirm.delete.file.title')}
            content={t('react.confirm.delete.file.body')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.delete')}
        />

      <Accordion defaultExpanded={isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="additional-actions1-header"
        >
          {/** IT'S NOT POSSIBLE TO DELETE THE FIRST SEQUENCE BECAUSE various files are attached to it **/}
          <FormControlLabel
              onFocus={(event) => event.stopPropagation()}
              control={isDefaultSequence ? <LockIcon style={{marginLeft: '9px', marginRight: '9px'}}/> : <Checkbox onClick={handleClickFormControlCheckBox} />}
              label={
                <Typography variant="body1" style={{color: '#000'}} onClick={handleClickFormControlLabel}>
                  {t("react.project.edit.datatab.sequence.title", {'sequenceNumber': sequenceNumber})}
                  {AuthService.isAdmin() && sequence.id > 0 && 
                  <>
                    {` (id = ${sequence.id})`}
                    {!sequence.freeOfCharge && <PaidOutlinedIcon color='error' fontSize='small' sx={{marginLeft:'9px', verticalAlign: 'sub'}}/>}
                  </>
                  }
                </Typography>}
          />
      </AccordionSummary>
      <AccordionDetails style={{padding : 0}}>
      <Grid container className={classes.gridContainer} style={{paddingTop : 0}}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={classes.SomeSpaceUnder}>
            <TextField
              id={`seq${sequenceNumber}name`}
              type="text"
              value={sequenceName}
              label={t("react.project.edit.datatab.sequence.name")}
              onChange={e => setSequenceName(e.target.value)}
              fullWidth={true}
              placeholder={t("react.project.edit.datatab.sequence.name")}
              error={sequenceName.trim().length < MIN_SEQUENCE_LENGTH}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  <Tooltip title={t("react.project.edit.datatab.sequence.name.save")}>
                  <span className={classes.flexRowSequenceName}>
                  {sequenceName.trim().length < MIN_SEQUENCE_LENGTH && <Box className={classes.invalidNameError}>{t("react.project.edit.datatab.sequence.name.invalid.message")}</Box>}
                    <IconButton
                      onClick={handleClickRenameSequence}
                      disabled={sequence.id === 0 || sequence.name === sequenceName || sequenceName.trim().length < MIN_SEQUENCE_LENGTH}
                      size="large">
                      <DoneIcon style={{color : (sequence.id !== 0 && sequence.name !== sequenceName && sequenceName.trim().length >= MIN_SEQUENCE_LENGTH) ? '#66bb6a' : '#eee'}} />
                    </IconButton>
                    </span>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          <TextField
            id={`seq${sequenceNumber}date`}
            type="datetime-local"
            value={dateFormat(sequence.creationDate, "UTC:yyyy-mm-dd'T'HH:MM")}
            label={t("react.project.edit.datatab.sequence.creationdate")}
            margin="dense"
            disabled
            fullWidth
          />
        </Grid>

        {/** Ask to upload answers file **/}
        {sequence.state === 'draft' && existingFiles.length === 0 &&
          <Grid item xs={12} className={classes.SomeSpaceUnder}>
          {/* https://github.com/Yuvaleros/material-ui-dropzone */}
            <Dropzone
              {...props}
              id={`seq${sequenceNumber}dza`}
              dropzoneText={t("react.project.edit.datatab.select.answersfile")}
              acceptedFiles={['sheet']}
              onChange={handleOnChangeInDropzoneArea}
              filesLimit={1}
              className={classes.dropzoneArea}
            />
            <Button
              id={`seq${sequenceNumber}uploadbutton`}
              title=""
              disabled={isUploadButtonDisabled}
              onClick={handleClickUploadFilesInDropzoneArea}
              variant="outlined"
              size="large"
              color="primary"
              startIcon={<CloudUploadIcon />}
              className={classes.uploadButton}
            >
              {t("react.project.edit.datatab.button.send")}
            </Button>
          </Grid>
        }

        {/** Display cost of a sequence only in multi-mode for sequence that are not the First one **/}
        {sequence.state === 'validation' && project.sequenceMode === 'multi' && !isDefaultSequence &&
        <Grid item xs={12} sm={6}>
          <Box className={classes.SomeSpaceLeft}>
            <Typography variant="h6" >
              <MonetizationOnIcon className={classes.typographyIcon} color="secondary"/> {t("react.project.sequence.forecast")}
            </Typography>
            {!subscriptionForecast.loaded &&
              <CircularProgress size={24}/>
            }
            {subscriptionForecast.loaded && subscriptionForecast.forecastValid &&
            <Typography>
              {t("react.project.sequence.forecast.details", subscriptionForecast)}
            </Typography>
            }
            {subscriptionForecast.loaded && !subscriptionForecast.forecastValid &&
              <Typography>
                {t("react.project.sequence.forecast.problem")}
              </Typography>
            }
          </Box>
        </Grid>
        }

        {/** Display that sequence is in progress, only in multi-mode for sequence that are not the First one **/}
        {['inspectAnswers', 'codification', 'inspectCodification', 'scoring', 'grouping'].includes(sequence.state) && project.sequenceMode === 'multi' &&
        <Grid item xs={12} sm={6}>
          <Box className={classes.SomeSpaceLeft}>
            <Typography variant="h6" >
              <TimelapseSharpIcon className={classes.typographyIcon} color="secondary"/> {t("react.project.edit.sequence.state.inprogress")}
            </Typography>
          </Box>
        </Grid>
        }

        {/** Display that sequence is finished, only in multi-mode **/}
        {sequence.state === 'completed' && project.sequenceMode === 'multi' &&
        <Grid item xs={12} sm={6}>
          <Box className={classes.SomeSpaceLeft}>
            <Typography variant="h6" >
              <StarIcon className={classes.typographyIcon} color="secondary"/> {t("react.project.edit.sequence.state.completed")}
            </Typography>
          </Box>
        </Grid>
        }

        {/** Else, List answers files **/}
        { existingFiles.length > 0 &&
        <Grid item xs={12}>
          <List dense={false}>
            {listExistingFiles(existingFiles, sequence.state === 'draft')}
          </List>
        </Grid>
        }

        {/** Else, List additional filters files **/}
        {sequence.state === 'completed' &&
        <Grid item xs={12}>
          <List dense={false}>
          <Box style={{width: '100%'}}>
              {listExistingFiles(existingFilterFiles, true)}
              </Box>
              <br/>
            <Accordion>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />} id="panel1a-header">
                <Typography className={classes.heading}>{t("react.project.edit.sequence.expand.filtersfile.accordion")}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block'}}>
              {/* https://github.com/Yuvaleros/material-ui-dropzone */}
              <Dropzone
                  id={`seq${sequenceNumber}dza-filters`}
                  dropzoneText={t("react.project.edit.datatab.select.filtersfile")}
                  acceptedFiles={['sheet']}
                  onChange={handleOnChangeInDropzoneFilterArea}
                  filesLimit={1}
                  className={classes.dropzoneArea}
                  {...props}
                />
                <Button
                  id={`seq${sequenceNumber}uploadbutton-filters`}
                  title=""
                  disabled={dropzoneFilterFiles.length === 0}
                  onClick={handleClickUploadFilesInDropzoneFilterArea}
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  className={classes.uploadButton}
                >
                  {t("react.project.edit.datatab.button.send.filtersfile")}
                </Button>
              </AccordionDetails>
            </Accordion>
          </List>
        </Grid>
        }

        {/** user or manager can start sequence here, in multi-mode, except for the First one **/}
        {sequence.state === 'draft' && project.sequenceMode === 'multi' && !isDefaultSequence && existingFiles.length > 0 && (sequence.userId === AuthService.getUser().id || AuthService.isManager()) &&
        <Grid item xs={12} className={classes.SomeSpaceUnder}>
          <Box style={{float: "right"}}>
            <Tooltip title={t("react.project.edit.button.start.sequence")}>
              <Button title="" variant="outlined" size="large" color="primary" startIcon={<PlayCircleFilledIcon />} className={classes.button}
                onClick={(e) => handleClickFireNewSequenceState(e, 'inspectAnswers')}
              > {t("react.project.edit.button.start.sequence")} </Button>
            </Tooltip>
          </Box>
        </Grid>
        }

        {/** user or manager can correct/validate sequence here, in multi-mode, except for the First one **/}
        {sequence.state === 'validation' && project.sequenceMode === 'multi' && !isDefaultSequence && subscriptionForecast.loaded && subscriptionForecast.forecastValid && (sequence.userId === AuthService.getUser().id || AuthService.isManager()) &&
        <Grid item xs={12} className={classes.SomeSpaceUnder}>
          <Box style={{float: "right"}}>
            <Tooltip title={t("react.project.edit.button.correct.sequence")}>
              <Button title="" variant="outlined" size="large" color="primary" startIcon={<PlayCircleFilledIcon className={classes.rotate180} />} className={classes.button}
                onClick={(e) => handleClickFireNewSequenceState(e, 'draft')}
              > {t("react.project.edit.button.correct.sequence")} </Button>
            </Tooltip>
            <Tooltip title={t("react.project.edit.button.valid.sequence")}>
              <Button title="" variant="outlined" size="large" color="primary" endIcon={<PlayCircleFilledIcon />} className={classes.button}
                onClick={(e) => handleClickFireNewSequenceState(e, 'codification')}
              > {t("react.project.edit.button.valid.sequence")} </Button>
            </Tooltip>
          </Box>
        </Grid>
        }

        {/** >>> ONLY FOR ADMIN <<< **/}
        {AuthService.isAdmin() && sequence.id > 0 &&
        <Grid item xs={12} className={[classes.SomeSpaceUnder, classes.adminZone].join(' ')}>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
              <WarningIcon className={classes.typographyIcon} color="error"/> {t("react.project.edit.admin.option")}
            </Typography>
            <TextField
              select
              label={t("react.project.edit.datatab.sequence.state")}
              value={sequenceStateForAdmin}
              onChange={e => setSequenceStateForAdmin(e.target.value)}
              className={classes.select}
            >
              <MenuItem value='draft'>draft</MenuItem>
              <MenuItem value='validation'>validation</MenuItem>
              <MenuItem value='codification'>codification</MenuItem>
              <MenuItem value='grouping'>grouping</MenuItem>
              <MenuItem value='completed'>completed</MenuItem>
              <MenuItem value='cancelled'>cancelled</MenuItem>

              <MenuItem value='666'>--- Do not change manually to states below ---</MenuItem>

              <MenuItem value='inspectAnswers'>inspectAnswers (automatic state)</MenuItem>
              <MenuItem value='inspectCodification'>inspectCodification (automatic state)</MenuItem>
              <MenuItem value='scoring'>scoring (automatic state)</MenuItem>
              <MenuItem value='error'>error (automatic state)</MenuItem>
            </TextField>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              style={{marginLeft : '8px'}}
              disabled={sequence.state === sequenceStateForAdmin}
              onClick={handleClickReplaceSequenceState}
            > {t('react.button.save')}
            </Button>
          </Grid>

          {/* Codification using our internal libraries: avaible for the 1rst sequence */}
          {['codification', 'inspectCodification', 'scoring', 'grouping', 'completed'].includes(sequence.state) &&
          <Grid item xs={12} style={{clear: "both"}}>
            <Typography variant='h6'>
              {isDefaultSequence && sequence.state === 'codification' && project.codificationLibraryId === 0 ? 'Codifier la séquence avec une librairie' : 'Librairie de codification'}
            </Typography>
            <TextField
              select
              value={selectedCodificationLibraryId}
              disabled={!isDefaultSequence || sequence.state !== 'codification' || project.codificationLibraryId > 0 /* it's NOT allowed to modify this */}
              onChange={e => setSelectedCodificationLibraryId(parseInt(e.target.value))}
              className={classes.select}
              inputProps={{
                name: 'codifPathId',
                id: 'filled-codifPathId-native-simple',
              }}
            >
              <MenuItem value="0" >
                {t("react.project.edit.codiftab.select.value")}
              </MenuItem>
              {
                codificationLibraries.filter((k, v) => parseInt(k) > 0).map(([key, value]) => (
                  <MenuItem key={`listItemPath${key}`} value={parseInt(key)}>{value}</MenuItem>
                  ))
              }
            </TextField>
            <br/>
            <FormControlLabel
                disabled={sequence.state !== 'codification'}
                className={classes.formControlLabel}
                control={<Switch checked={sequence.precodeNonImpactingUnknownWords} color="secondary" onClick={handleClickPrecodeNonImpactingUnknownWords}/>}
                label={t("react.project.edit.codiftab.precodeNonImpactingUnknownWords")}
                labelPlacement="start"
            />
            <br/>
            <FormControlLabel
                disabled={sequence.state !== 'codification'}
                className={classes.formControlLabel}
                control={<Switch checked={sequence.precodeMisspelledUnknownWords} color="secondary" onClick={handleClickPrecodeMisspelledUnknownWords}/>}
                label={t("react.project.edit.codiftab.precodeMisspelledUnknownWords")}
                labelPlacement="start"
            />
            {sequence.state === 'codification' &&
            <Tooltip title={t("react.project.edit.codiftab.fire.codif.tooltip")} style={{float: "right"}}>
              <span>
              <Button
                id='firecodif'
                title=""
                disabled={selectedCodificationLibraryId === 0}
                onClick={handleClickRunCodification}
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<DynamicFeedIcon />}
                className={classes.button}
              >
                {t("react.project.edit.codiftab.fire.codif.button")}
              </Button>
              </span>
            </Tooltip>
            }
          </Grid>
          }

          {/** Admin valid the sequence to "completed" state **/}
          {sequence.state === 'grouping' &&
          <Grid item xs={12}>
            <Box style={{float: "right"}}>
              <Tooltip title={t("react.project.edit.button.complete.sequence")}>
                <Button title="" variant="outlined" size="large" color="primary" startIcon={<CheckBoxIcon style={{ color: '#F00' }}/>} className={classes.button}
                  onClick={(e) => handleClickFireNewSequenceState(e, 'completed')}
                > {t("react.project.edit.button.complete.sequence")} </Button>
              </Tooltip>
            </Box>
          </Grid>
          }
        </Grid>
        }
      </Grid>
      </AccordionDetails>
    </Accordion>
    </div>
    );
}
