import React, { useState, useEffect, useContext } from 'react';
import { Box, Table, TableCell, TableBody, TableHead, TableContainer, TableRow, Dialog, DialogTitle, DialogActions, DialogContentText,
     DialogContent, Button, Select, MenuItem, Typography, TextField, IconButton, Tooltip,  List, ListItem, ListItemText, Paper, Checkbox, 
     FormControlLabel, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { SurveyContext } from './context.js';
import { appendSuffixIfGreaterThan, isBlank, stripHtmlTags, stripHtmlTagsPreserveSpaces } from '../../utils/utils.js';
import { localeNamesAndCodes, renderLocaleNames } from '../../utils/surveysUtils.js';
import PopupTwoButtons from "../shared/PopupTwoButtons/PopupTwoButtons.js";
import AddIcon from '@mui/icons-material/Add';
import SurveysService from '../../services/SurveysService.js';
import PromptsService from '../../services/PromptsService.js';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from 'tss-react/mui';
import {LoadData} from '../../Constants.js';
import TranslateIcon from '@mui/icons-material/Translate';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon.js';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TranslationEditDialog from './TranslationEditDialog.js';
const _ = require("lodash");

const surveysService = new SurveysService();
const promptsService = new PromptsService();

const useStyles = makeStyles()(theme => ({
    titleIcon: {
        marginRight: '12px',
        verticalAlign: 'sub'
    },
    windowPrimaryText: {
        border: '1px solid rgb(200,200,200)',
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 10,
        borderRadius: 5,
        minHeight: 400
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100px' 
    },
    primaryLanguage: {
        fontSizz: 0.9, 
        fontStyle: 'italic', 
        color: 'grey'
    },
    selectFallback: {
        width: 200,
        marginRight: 15,
        height: 25
    },
    paperAllLanguagesAvailable: {
        position: 'absolute',
        top: 56,
        width: '100%',
        zIndex: 1
    },
    containerCheckbox: {
        float: 'right',
        display: 'flex'
    },
    selectSelectedTranslation: {
        width: 200,
        marginRight: 15,
        height: 25,
        marginLeft: 5
    }
  }));

export default function TranslationsDialog(props) {

    const { open, setOpen, t, openSnackbar, showSpinner } = props;

    const { formIdParameter } = useContext(SurveyContext);

    const { classes } = useStyles();

    const [selectedTranslation, setSelectedTranslation] = useState(null);
    const [allTranslations, setAllTranslations] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentEditTranslation, setCurrentEditTranslation] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [generateTranslationsDialogOpen, setGenerateTranslationsDialogOpen] = useState(false);
    const [localeLangToDelete, setLocaleLangToDelete] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [inputValueCode, setInputValueCode] = useState('');
    const [filteredLanguages, setFilteredLanguages] = useState([]);
    const [hasSelectedLanguage, setHasSelectedLanguage] = useState(false);
    const [selectedTranslationForGeneration, setSelectedTranslationForGeneration] = useState('');
    const [showSpinnerButton, setShowSpinnerButton] = useState(false);
    const [checkboxFilter, setCheckboxFilter] = useState({all: true, missings: false, obsolets: false});
    const [mouseOverIdentifier, setMouseOverIdentifier] = useState(undefined /* can be an ID in case of primary or uniqueKey in case of secondary */);

    const [loadData, setLoadData] = useState(LoadData.Load);
    useEffect(() => {
        if (!open || loadData !== LoadData.Load) return;

        showSpinner(true);

        setLoadData(LoadData.Loading);
    
        setInputValue('');
        setInputValueCode('');
        setCheckboxFilter({all: true, missings: false, obsolets: false});
        setHasSelectedLanguage(false);
        setMouseOverIdentifier(undefined);
        setFilteredLanguages([]);

        surveysService.getFormTranslations(formIdParameter)
            .then(result => {
                initialize(result.data);
            })
            .catch(err => {
                setAllTranslations([]);
                openSnackbar('error', t("react.error.fetch.message"));
            }).finally(() => {
                setLoadData(LoadData.Loaded);
                showSpinner(false);
            });
    }, [open, loadData]);

    // ----------------------------------------------------------------------------------------

    const initialize = (incomingTranslations) => {
        // get the primary language
        var primaryLanguage = incomingTranslations.find(t => t.primary);

        // prepare, control & update
        primaryLanguage.elements.forEach((tradElement) => {
            tradElement.uniqueKey = makeUniqueKey(tradElement.itemUuid, tradElement.itemSection);

            // loop over all other translations
            incomingTranslations.filter(it => !it.primary).forEach(translation => {
                // generate a unique key for all incoming elements of the translation
                translation.elements.forEach(te => {
                    te.uniqueKey = makeUniqueKey(te.itemUuid, te.itemSection);
                });

                // check if exists in the current translation
                var idx = translation.elements.findIndex(e => e.uniqueKey === tradElement.uniqueKey);
                if (idx === -1) translation.elements.push(createTranslationElement('', tradElement.itemUuid, tradElement.itemSection));
            });
        });

        // retains only existing elements by translation vs the Primary ones
        incomingTranslations.filter(it => !it.primary).forEach(translation => {
            translation.elements = translation.elements
                .filter(te => primaryLanguage.elements.find(dte => dte.uniqueKey === te.uniqueKey));
        });

        setAllTranslations(incomingTranslations);
        setSelectedTranslation(incomingTranslations.find(t => !t.primary)?.locale || undefined);
    };

    const createTranslationElement = (content, itemUuid, itemSection, itemRef /* only for primary entries */) => {
        return {id: 0, itemUuid: itemUuid, itemSection: itemSection, content: content, uniqueKey: makeUniqueKey(itemUuid, itemSection), itemRef: itemRef};
    };

    const makeUniqueKey = (itemUuid, itemSection) => {
        return `${!isBlank(itemUuid) ? itemUuid : 'UNDEFINED-ITEM-UUID'}|${!isBlank(itemSection) ? itemSection : 'UNDEFINED-ITEM-SECTION'}}`;
    };

    // ----------------------------------------------------------------------------------------

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setHasSelectedLanguage(false);
        if (value.length >= 1) {
            const filtered = localeNamesAndCodes.filter((lang) =>
                lang.name.toLowerCase().startsWith(value.toLowerCase()) || lang.nativeName.toLowerCase().startsWith(value.toLowerCase())
            );
            setFilteredLanguages(filtered);
        } else {
            setFilteredLanguages([]);
        }
    };

    const handleSelectLanguage = (lang) => {
        setInputValue(lang.name);
        setInputValueCode(lang.code);
        setFilteredLanguages([]);
        if (allTranslations.find(t => t.locale === lang.code)) {
            setHasSelectedLanguage(false);
        } else {
            setHasSelectedLanguage(true);
        }
    };  

    const handleClose = (event, reason) => {
        // avoid clickaway to close
        if (reason && reason === "backdropClick") {
            return;        
        }
        setOpen(false);
        setLoadData(LoadData.Load);
    };

    const handleEditTranslation = (element) => {
        var ot = allTranslations.find(t => t.primary).elements.find(e => e.uniqueKey === element.uniqueKey).content;
        setCurrentEditTranslation({...element, originalTranslation: ot});
        setOpenEditDialog(true);
    };

    const deleteLang = (localeParam) => {
        setDeleteDialogOpen(true);
        setLocaleLangToDelete(localeParam);
    };

    const handleClickSaveAll = () => {
        showSpinner(true);

        // use the LoadData status to hide while saving
        setLoadData(LoadData.Loading);

        surveysService.saveFormTranslations(formIdParameter, allTranslations)
            .then(() => {
                handleClose();
            })
            .catch(() => {
                // say it's loaded to get back the popup and don't loose changes
                setLoadData(LoadData.Loaded);
                openSnackbar('error', t("react.error.save.message"));
            })
            .finally(() => {
                showSpinner(false);
            });
    };

    const countMissingsTranslations = (langName) => {
        const primaryNonEmptyCount = allTranslations
            .find(t => t.primary).elements
            .filter(e => e.content && !isBlank(stripHtmlTags(e.content))).length;

        const targetNonEmptyCount = allTranslations
            .find(t => t.locale === langName).elements
            .filter(e => e.content && !isBlank(stripHtmlTags(e.content))).length;
        return primaryNonEmptyCount - targetNonEmptyCount;
    };

    const countObsoletsTranslations = (langName) => {
        const primaryObsoletsCount = allTranslations
            .find(t => t.primary).elements
            .filter(e => e.obsolet).length;
        return primaryObsoletsCount;
    };

    const renderPrimaryTranslationTableCell = (element) => {
        return (<TableCell>
            <Box
                sx={{ verticalAlign: 'middle', height: '36px', lineHeight: '36px' }}
                onMouseOver={e => setMouseOverIdentifier(element.id)}
                onMouseOut={e => setMouseOverIdentifier(undefined)}
            >
                {appendSuffixIfGreaterThan(stripHtmlTagsPreserveSpaces(element.content), "...", 50)}
                {element.editable && <Tooltip title={t('react.project.tab.filters.rename.tooltip')}>
                    <IconButton onClick={(e) => handleEditTranslation(element)}>
                        <EditOutlinedIcon fontSize="small" sx={{ verticalAlign: 'sub', display: mouseOverIdentifier === element.id ? 'inline-flex' : 'none' }} />
                    </IconButton>
                </Tooltip>}
            </Box>
        </TableCell>);
    };

    const renderTranslationSecondaryTableCell = (element) => {
        var currentTranslation = selectedTranslation ? allTranslations.find(l => l.locale === selectedTranslation) : undefined;
        var findElement = currentTranslation ? currentTranslation.elements.find(e => e.uniqueKey === element.uniqueKey) : undefined;
        if (!findElement) return (<TableCell style={{ width: '33%' }}/>);

        return (
            <TableCell
                onMouseOver={() => setMouseOverIdentifier(findElement.uniqueKey)}
                onMouseOut={() => setMouseOverIdentifier(undefined)}
            >            <div style={{ display: 'flex', alignItems: 'center', height: 10 }}>
                    <span>{appendSuffixIfGreaterThan(stripHtmlTagsPreserveSpaces(findElement.content), "...", 50)}</span>
                    <IconButton style={{ transform: 'scale(0.9)' }} onClick={() => handleEditTranslation(findElement)}>
                        <EditOutlinedIcon fontSize="small" style={{ display: mouseOverIdentifier === findElement.uniqueKey ? 'inline-flex' : 'none' }} />
                    </IconButton>
                </div>
            </TableCell>
        )
    };

    const getFilteredElements = () => {
        const primaryTranslation = allTranslations.find(t => t.primary);
        if (!primaryTranslation) return [];

        var dataToReturn = [];
        const secondaryTranslation = allTranslations.find(t => t.locale === selectedTranslation);

        if (checkboxFilter.missings && secondaryTranslation) {
            primaryTranslation.elements.forEach(pe => {
                let translation = secondaryTranslation.elements.find(se => se.uniqueKey === pe.uniqueKey);
                if (!stripHtmlTags(translation?.content)) {
                    dataToReturn.push(pe);
                }
            });
        } else if (checkboxFilter.obsolets && secondaryTranslation) {
            dataToReturn = primaryTranslation.elements
                .filter(pe => pe.obsolet);
        } else {
            dataToReturn = primaryTranslation.elements;
        }

        return dataToReturn.sort((e1, e2) => e1.itemRef.localeCompare(e2.itemRef));
    };

    const handlefallbackChange = (lang, newCode) => {
        let updatedArray = [...allTranslations];
        updatedArray.find(l => l.locale === lang.locale).fallbackLocale = newCode;
        setAllTranslations(updatedArray);
    };

    const changeSelectedTranslation = (event) => {
        const findTranslation = allTranslations.find(t => t.locale === event.target.value);
        setSelectedTranslation(findTranslation.locale);
    };

    const handleAddLanguage = () => {
        const newLang = {
            locale: inputValueCode,
            primary: false,
            elements: allTranslations.find(t => t.primary).elements.map(e => createTranslationElement('', e.itemUuid, e.itemSection)),
            formId: formIdParameter,
            fallbackLocale: ''
        };
        setAllTranslations([...allTranslations, newLang]);
        setHasSelectedLanguage(false);
        setInputValue('');
        setInputValueCode('');
        if (!selectedTranslation) {
            setSelectedTranslation(newLang.locale);
        };
        clickOnGenerateTranslationAutomatic(inputValueCode);
        setSelectedTranslationForGeneration(inputValueCode);
    };

    const handleSaveTranslation = (editedLocale, editedElement, newValue) => {
        var isPrimaryChanged = false;
        var updatedTraductions = [...allTranslations];

        let tradIdx = updatedTraductions.findIndex(t => t.locale === editedLocale);
        if (tradIdx === -1) return;

        isPrimaryChanged = updatedTraductions[tradIdx].primary;
        let elementIdx = updatedTraductions[tradIdx].elements.findIndex(e => e.id === editedElement.id);
        if (elementIdx >= 0) {
            updatedTraductions[tradIdx].elements[elementIdx].content = newValue;
        };

        // remove obsolet status into primary
        let primaryTradIdx = updatedTraductions.findIndex(t => t.primary);
        if(primaryTradIdx >= 0) {
            let primaryTradElementIdx = updatedTraductions[primaryTradIdx].elements.findIndex(e => e.uniqueKey === editedElement.uniqueKey);
            if(primaryTradElementIdx >= 0) {
                updatedTraductions[primaryTradIdx].elements[primaryTradElementIdx].obsolet = isPrimaryChanged;
            }
        }

        setAllTranslations(updatedTraductions);
        setOpenEditDialog(false);
    };

    const handleDeleteDialogCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleGenerateTranslationsDialogCancel = () => {
        setGenerateTranslationsDialogOpen(false);
    };

    const handleDeleteDialogConfirm = () => {
        var newTranslations = allTranslations
            .filter(lang => lang.locale !== localeLangToDelete)
            .map(t => {
                return {...t, fallbackLocale: (t.fallbackLocale === localeLangToDelete ? '' : t.fallbackLocale)};
            });

        if (selectedTranslation === localeLangToDelete) {
            handleChangeCheckboxFilters('all');
            setSelectedTranslation(newTranslations.find(t => !t.primary)?.locale);
        }

        setAllTranslations(newTranslations);
        setDeleteDialogOpen(false);
    };

    const clickOnGenerateTranslationAutomatic = (lang) => {
        setSelectedTranslationForGeneration(lang.locale);
        setGenerateTranslationsDialogOpen(true);
    };

    const handleGenerateTranslationsDialogConfirm = () => {
        setShowSpinnerButton(true);

        const primaryTranslation = allTranslations.find(t => t.primary);
        const destTranslation = allTranslations.find(t => t.locale === selectedTranslationForGeneration);

        const contentToTranslate = primaryTranslation.elements
            .filter(pte => !isBlank(pte.content))
            .filter(pte => destTranslation.elements.find(dte => dte.uniqueKey === pte.uniqueKey && isBlank(dte.content)))
            .map(pte => pte.content);
   
        const data = {
            "from": primaryTranslation.locale,
            "to": selectedTranslationForGeneration,
            "words": _.uniq(contentToTranslate)
        };
    
        promptsService.translate(data)
            .then(result => {
                let updatedTraductions = [...allTranslations];
                let targetLanguageIdx = updatedTraductions.findIndex(t => t.locale === selectedTranslationForGeneration);

                primaryTranslation.elements.forEach(pe => {
                    let translatedContent = result.data[pe.content];
                    let elementIndex = updatedTraductions[targetLanguageIdx].elements.findIndex(te => te.uniqueKey === pe.uniqueKey);

                    if (elementIndex >= 0) {
                        if (translatedContent) {
                            updatedTraductions[targetLanguageIdx].elements[elementIndex].content = translatedContent;
                        }
                    } else {
                        updatedTraductions[targetLanguageIdx].elements.push(createTranslationElement(translatedContent||'', pe.itemUuid, pe.itemSection));
                    }
                });
    
                setAllTranslations(updatedTraductions);
            })
            .catch(err => {
                openSnackbar('error', t("react.error.fetch.message"));
            })
            .finally(f => {
                setShowSpinnerButton(false);
                setGenerateTranslationsDialogOpen(false);
            });
    };

    const handleChangeCheckboxFilters = (field) => {
        var fieldToSetAtTrue = field;

        const updatedCheckboxFilters = { ...checkboxFilter };
        const currentStateOfField = updatedCheckboxFilters[field];
        if(currentStateOfField /* current status is SELECTED */) {
            // it is not possible to UNSELECT, keep 'all' to TRUE
            fieldToSetAtTrue = 'all';
        }

        for (const key in updatedCheckboxFilters) {
            updatedCheckboxFilters[key] = (key === fieldToSetAtTrue ? true : false);
        }

        setCheckboxFilter(updatedCheckboxFilters);
    };

    if(!open || loadData !== LoadData.Loaded) return null;

    return (
        <>
        {/* main window */}
        <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullWidth={true} disableEscapeKeyDown={true}>
            <DialogTitleWithCloseIcon startIcon={<TranslateIcon />} title={t('react.collectforms.translations.button')} callbackOnclose={handleClose} />
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('react.survey.translations.languages')}</TableCell>
                                <TableCell>{t('react.survey.translations.fallback')}</TableCell>
                                <TableCell>{t('react.survey.translations.total')}</TableCell>
                                <TableCell>{t('react.survey.translations.missings')}</TableCell>
                                <TableCell>{t('react.survey.translations.obsolets')}</TableCell>
                                <TableCell style={{width: 100}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allTranslations.sort((a, b) => b.primary - a.primary).map((tr, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div style={{display: 'flex'}}>
                                            <Typography>{renderLocaleNames(tr.locale)}</Typography>
                                            {!tr.primary && <Tooltip title={t('react.survey.translations.generate.translations')}>
                                                <IconButton onClick={() => clickOnGenerateTranslationAutomatic(tr)} size="small" style={{height: 20, marginLeft: 10}}><AutoAwesomeIcon/></IconButton>
                                            </Tooltip>}
                                        </div>
                                        {tr.primary && <span className={classes.primaryLanguage}>{t('react.survey.translations.main.language')}</span>}
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            value={tr.fallbackLocale || ''}
                                            onChange={(e) => handlefallbackChange(tr, e.target.value)}
                                            className={classes.selectFallback}
                                        >
                                            {allTranslations.filter(t => t.locale !== tr.locale).map((langOption) => (
                                                <MenuItem key={langOption.locale} value={langOption.locale}>
                                                    {renderLocaleNames(langOption.locale)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell>{tr.elements.filter(e => e.content && !isBlank(stripHtmlTags(e.content))).length}</TableCell>
                                    <TableCell>{tr.primary ? '-' : countMissingsTranslations(tr.locale)}</TableCell>
                                    <TableCell>{tr.primary ? '-' : countObsoletsTranslations(tr.locale)}</TableCell>
                                    <TableCell style={{width: 100}}>
                                        {!tr.primary && <Tooltip>
                                            <IconButton style={{transform: 'scale(0.9)'}} onClick={() => deleteLang(tr.locale)}><DeleteIcon/></IconButton>
                                        </Tooltip>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{display: 'flex', marginTop: 17}}>
                    <div style={{ position: 'relative', width: '200px' }}>
                        <TextField
                            size="small"
                            label={t('react.survey.translations.add.language')}
                            variant="outlined"
                            fullWidth
                            value={inputValue}
                            onChange={handleInputChange}
                            autoComplete="off"
                        />
                        {filteredLanguages.length > 0 && (
                            <Paper className={classes.paperAllLanguagesAvailable}>
                                <List>
                                    {filteredLanguages.map((lang) => (
                                        <ListItem button key={lang.code} onClick={() => handleSelectLanguage(lang)}>
                                            <ListItemText primary={renderLocaleNames(lang.code)} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}
                    </div>
                    <Button 
                        disabled={!hasSelectedLanguage}
                        startIcon={<AddIcon />} 
                        variant="contained" 
                        style={{marginLeft: 10}} 
                        onClick={() => handleAddLanguage()}>
                        {t('react.button.valid')}
                    </Button>
                </div>
                <div className={classes.containerCheckbox}>
                    <Typography style={{marginRight: 4, marginTop: 9}}>{t('react.survey.translations.display')}</Typography> <span style={{marginTop: 7}}>|</span>
                    <FormControlLabel style={{ marginLeft: -5 }} 
                        control={<Checkbox checked={checkboxFilter.all} onChange={() => handleChangeCheckboxFilters('all')}/>}
                        label={t('react.survey.translations.all')}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkboxFilter.missings} disabled={allTranslations.length < 2} onChange={() => handleChangeCheckboxFilters('missings')}/>}
                        label={t('react.survey.translations.missings')}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkboxFilter.obsolets} disabled={allTranslations.length < 2} onChange={() => handleChangeCheckboxFilters('obsolets')}/>}
                        label={t('react.survey.translations.obsolets')}
                    />
                </div>
                <TableContainer sx={{height: '800px'}}>
                    <Table size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '33%' }}>{t('react.survey.translations.labels')}</TableCell>
                            {allTranslations.find(t => t.primary) ? (
                                <TableCell style={{ width: '33%' }}>
                                    {t('react.survey.translations.main.language')} : {renderLocaleNames(allTranslations.find(t => t.primary)?.locale)}
                                </TableCell>
                            ) : (
                                <TableCell style={{ width: '33%' }} />
                            )}
                            {allTranslations.length > 1 ? (
                                <TableCell style={{ width: '33%' }}>
                                    {t('react.survey.translations.translation')} :
                                    <Select
                                        value={selectedTranslation}
                                        onChange={changeSelectedTranslation}
                                        className={classes.selectSelectedTranslation}
                                    >
                                        {allTranslations.filter(t => !t.primary).map((langOption) => (
                                            <MenuItem key={langOption.locale} value={langOption.locale}>
                                                {renderLocaleNames(langOption.locale)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                            ) : (
                                <TableCell style={{ width: '33%' }} />
                            )}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {getFilteredElements().map((element, index) => (
                                <TableRow key={index} sx={{backgroundColor: selectedTranslation && element.obsolet ? 'orange' : 'white'}}>
                                    <TableCell>{element.itemRef}</TableCell>
                                    {renderPrimaryTranslationTableCell(element)}
                                    {renderTranslationSecondaryTableCell(element)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    {t("react.button.cancel")}
                </Button>
                <Button onClick={() => handleClickSaveAll()} color="primary" disabled={allTranslations.length === 0}>
                    {t("react.button.save")}
                </Button>
            </DialogActions>
        </Dialog>

        {/* Popup to warn confirm the delete */}
        <PopupTwoButtons
            variant="warning"
            openState={deleteDialogOpen}
            callbackOnclose={handleDeleteDialogCancel}
            callbackOnclickLeftButton={handleDeleteDialogCancel}
            callbackOnclickRightButton={handleDeleteDialogConfirm}
            title={t("react.survey.confirm.delete.title")}
            content={t("react.survey.confirm.delete.language.content")}
            leftButton={t("react.button.cancel")}
            rightButton={t("react.button.delete")}
        />

        {/* Popup to edit a translation */}
        <TranslationEditDialog 
            {...props}
            open={openEditDialog}
            allTranslations={allTranslations}
            elementToEdit={currentEditTranslation}
            classes={classes}
            callbackOnSave={handleSaveTranslation}
            callbackOnCancel={() => setOpenEditDialog(false)}
        />

        {/* Popup to ask if the user wants to generate automaticaly */}
        <Dialog
            open={generateTranslationsDialogOpen}
            onClose={(event, reason) => {
                if (!showSpinnerButton && reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleGenerateTranslationsDialogCancel();
                }
            }}
            disableEscapeKeyDown={showSpinnerButton}
        >
            <DialogTitle>
                <InfoIcon color='secondary' className={classes.titleIcon} />
                {t("react.survey.confirm.generate.translation.title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={'div'}>
                    {t("react.survey.confirm.generate.translation.content")}
                </DialogContentText>
            </DialogContent>
            {!showSpinnerButton && <DialogActions style={{ marginTop: 47 }}>
                <Button onClick={handleGenerateTranslationsDialogCancel} color="primary">{t("react.button.cancel")}</Button>
                <Button onClick={handleGenerateTranslationsDialogConfirm} color="primary" autoFocus> {t("react.button.valid")}</Button>
            </DialogActions>}
            {showSpinnerButton && <div className={classes.circularProgress}>
                <CircularProgress size={24} />
            </div>}
        </Dialog>        
       </>
    );
}