import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import DialogTitleWithCloseIcon from "../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ThematizationService from "../../../services/ThematizationService";
import {LoadData} from "../../../Constants";
import Dropzone from "../../shared/Dropzone";

const thematizationService = new ThematizationService();

const useStyles = makeStyles()((theme) => ({
	dropzoneArea: {
		backgroundColor: '#F0F0F0',
		width: "100%",
	},
	container: {
		width: "800px",
	},
	flexBetween: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: theme.spacing(3),
	}
}));

export default function ImportDialog(props) {

	const { t, openState, callbackOnclose, openSnackbar, setLoadData, editThematization } = props;

	const { classes } = useStyles();

	const [verbatimsTabNumber, setVerbatimsTabNumber] = useState(1);
	const [verbatimsDataLineNumber, setVerbatimsDataLineNumber] = useState(1);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if(!openState) return;

		setFiles([]);
		setVerbatimsTabNumber(1);
		setVerbatimsDataLineNumber(1);
	}, [openState]);

	const handleConfirm = () => {
		setLoadData(LoadData.Loading);
		let formData = new FormData();
		formData.append("uploadedFile", files[0]);
		thematizationService.loadCodeframeInThematization(editThematization.id, formData, (verbatimsTabNumber - 1), (verbatimsDataLineNumber - 1))
			.then((response) => {
				openSnackbar("success", t("react.aitools.thematization.import.success"));
			})
			.catch((err) => {
				openSnackbar("error", t("react.aitools.thematization.import.failed"));
			})
			.finally(() => {
				callbackOnclose();
				setLoadData(LoadData.Load);
			});
	};

	if (!openState) return null;

	return (
		<Dialog open={openState} onClose={callbackOnclose}>
			<DialogTitleWithCloseIcon
				title={t("react.aitools.thematization.import.file")}
				callbackOnclose={callbackOnclose}
			/>
			<Grid container spacing={0} >
				<Grid item xs={12} className={classes.container}>
					<DialogContent>
						<Dropzone
							{...props}
							dropzoneText={t("react.thematization.edit.selectfiles")}
							onChange={(files) => setFiles(files)}
							className={classes.dropzoneArea}
							acceptedFiles={['sheet']}
							filesLimit={1}
						/>
						<Grid item xs={12} paddingBlock={2} className={classes.flexBetween}>
							<TextField
								id="outlined-number"
								label={t("react.aitools.thematization.tab.number")}
								type="number"
								fullWidth
								data-testid="verbatimsGenerateCodeFrameTabNumber"
								inputProps={{ min: 1, max: 20 }}
								value={verbatimsTabNumber}
								onChange={(e) => setVerbatimsTabNumber(e.target.value)}
							/>
							<TextField
								id="outlined-number"
								label={t("react.aitools.thematization.line.number")}
								type="number"
								fullWidth
								data-testid="verbatimsGenerateCodeFrameDataLineNumber"
								inputProps={{ min: 1, max: 50 }}
								value={verbatimsDataLineNumber}
								onChange={(e) => setVerbatimsDataLineNumber(e.target.value)}
							/>
						</Grid>
					</DialogContent>
					<DialogActions className={classes.flexBetween}>
						<Button
							variant="outlined"
							size="large"
							startIcon={<CloseIcon />}
							onClick={callbackOnclose}
							color="primary"
						>
							{t("react.button.cancel")}
						</Button>
						<Button
							variant="outlined"
							size="large"
							startIcon={<UploadFileIcon />}
							color="primary"
							disabled={files.length === 0}
							onClick={handleConfirm}
						>
							{t("react.translationtool.importexportdialog.button.import.title")}
						</Button>
					</DialogActions>
				</Grid>
			</Grid>
		</Dialog>
	);
}
