import React, { useState, useEffect } from "react";
import { toolbarStyles } from "../../common.js";
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LinearProgress from "@mui/material/LinearProgress";
import TryIcon from "@mui/icons-material/Try";
import TableSortLabel from "@mui/material/TableSortLabel";
import { LoadData } from "../../Constants.js";
import { AuthService } from "../../services/AuthService.js";
import { getItemAriaLabel } from "../../utils/pagination.js";
import { appendSuffixIfGreaterThan } from "../../utils/utils.js";
import PopupTwoButtons from "../shared/PopupTwoButtons/PopupTwoButtons.js";
import PromptsService from "../../services/PromptsService.js";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

const promptsService = new PromptsService();

function createData(id, lang, type, content) {
   return { id, lang, type, content };
}

const headCells = [
   { id: "type", label: "react.prompts.table.header.type", minWidth: 200 },
   {
      id: "content",
      label: "react.prompts.table.header.content",
      minWidth: 200,
      sortable: false,
   },
   { id: "lang", label: "react.prompts.table.header.lang", minWidth: 200 },
];

function PromptsListHead(props) {
   const { classes, t, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

   return (
      <TableHead>
         <TableRow>
            <TableCell padding="checkbox">
               <Checkbox
                  id="checkbox-all"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={onSelectAllClick}
               />
            </TableCell>
            {headCells
               .filter(function (headCell) {
                  if (headCell.adminView === true && !AuthService.isAdmin()) {
                     return false; // skip admin only to normal user
                  } else if (
                     headCell.adminView === false &&
                     AuthService.isAdmin()
                  ) {
                     return false; // skip useless columns to admin
                  }
                  return true;
               })
               .map((headCell) => (
                  <TableCell
                     key={headCell.id}
                     align={headCell.align}
                     padding={headCell.disablePadding ? "none" : "normal"}
                     sortDirection={
                        sortBy === headCell.id ? sortDirection : false
                     }
                  >
                     {headCell.sortable === false ? (
                        t(headCell.label)
                     ) : (
                        <TableSortLabel
                           active={sortBy === headCell.id}
                           direction={sortDirection}
                           onClick={(e) => handleChangeSort(e, headCell.id)}
                        >
                           {t(headCell.label)}
                           {sortBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                 {sortDirection === "desc" ? "sorted descending" : "sorted ascending"}
                              </span>
                           ) : null}
                        </TableSortLabel>
                     )}
                  </TableCell>
               ))}
         </TableRow>
      </TableHead>
   );
}

const PromptsListToolbar = (props) => {
   const { classes } = toolbarStyles();
   const { t, selected, countForPagination, handleDeletePrompt, keywords, handleKeywordsChange, 
    setLoadData, setOpenEditPromptDialog } = props;

   const numSelected = selected.length;

   const handleClickRefresh = (event) => {
      event.preventDefault();
      setLoadData(LoadData.Load);
   };

   const handleCreatePrompt = (event) => {
      event.preventDefault();
      setOpenEditPromptDialog({ show: true, id: 0 });
      setLoadData(LoadData.Load);
   };

   return (
      <Toolbar
         className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
         })}
      >
         <TryIcon className={classes.mainIcon} />
         {numSelected > 0 ? (
            <Typography
               className={classes.title}
               color="inherit"
               variant="subtitle1"
            >
               {t("react.prompts.list.number.of.selected", {
                  numSelected: numSelected,
               })}
            </Typography>
         ) : (
            <Typography className={classes.title} variant="h6" id="tableTitle">
               {t("react.prompts.list.title", {
                  countForPagination: countForPagination,
               })}
            </Typography>
         )}
         <Input
            id="search-filter-basic"
            value={keywords}
            className={classes.searchfield}
            placeholder={t("react.translationtool.search.title")}
            onChange={(e) => handleKeywordsChange(e.target.value)}
            endAdornment={
               <InputAdornment position="end">
                  <IconButton
                     onClick={(e) => handleKeywordsChange("")}
                     edge="end"
                     size="large"
                  >
                     <ClearIcon />
                  </IconButton>
               </InputAdornment>
            }
         />
         {numSelected > 0 && (
            <Tooltip title={t("react.button.delete")}>
               <IconButton size="large" onClick={handleDeletePrompt}>
                  <DeleteIcon />
               </IconButton>
            </Tooltip>
         )}
         <Tooltip title={t("react.button.add")}>
            <IconButton onClick={handleCreatePrompt} size="large">
               <ControlPointIcon />
            </IconButton>
         </Tooltip>
         <Tooltip title={t("react.button.refresh")}>
            <IconButton onClick={handleClickRefresh} size="large">
               <CachedIcon />
            </IconButton>
         </Tooltip>
      </Toolbar>
   );
};

const useStyles = makeStyles()((theme) => ({
   root: {
      width: "100%",
   },
   paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
   },
   table: {
      minWidth: 750,
   },
   tableWrapper: {
      overflowX: "auto",
   },
   visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
   },
   tableRowCursorPointer: {
      cursor: "pointer",
   },
}));

export default function PromptsList(props) {
   const { classes } = useStyles();

   const {t, openSnackbar, showSpinner, setOpenEditPromptDialog, loadData, setLoadData } = props;

   const [countForPagination, setCountForPagination] = useState(0);
   const [rows, setRows] = useState([]);
   const [selected, setSelected] = useState([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [sortBy, setSortBy] = useState("type");
   const [sortDirection, setSortDirection] = useState("asc");
   const [keywords, setKeywords] = useState("");

   const handleSelectAllClick = (event) => {
      if (event.target.checked) {
         const newSelecteds = rows.map((n) => n.id);
         setSelected(newSelecteds);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
         );
      }
      setSelected(newSelected);
   };

   const handleChangePage = (event, newPage) => {
      event.preventDefault();
      setPage(newPage);
      setLoadData(LoadData.Load);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setLoadData(LoadData.Load);
   };

   const handleChangeSort = (event, headCellId) => {
      event.preventDefault();
      if (sortBy !== headCellId) {
         setSortBy(headCellId);
         setSortDirection("asc");
      } else {
         setSortDirection(sortDirection === "desc" ? "asc" : "desc");
      }
      setLoadData(LoadData.Load);
   };

   const [loadKeywordsChange, setLoadKeywordsChange] = useState(false);
   const handleKeywordsChange = (newValue) => {
      setPage(0);
      setKeywords(newValue);
      if (newValue && newValue.length > 0) {
         setLoadKeywordsChange(true);
      }
      setLoadData(LoadData.Load);
   };

   useEffect(() => {
      if (loadData !== LoadData.Load) return;
      setLoadData(LoadData.Loading);

      promptsService.fetchPrompts(page, rowsPerPage, sortBy, sortDirection, keywords)
         .then(function (result) {
            // parse prompts
            const newRows = [];
            let hits = result.data.hits;
            for (let i = 0; i < result.data.hits.length; i++) {
               let hit = hits[i];
               let data = createData(
                  hit.id,
                  hit.lang,
                  hit.type,
                  appendSuffixIfGreaterThan(hit.content, " ...", "100")
               );
               newRows.push(data);
            }
            setRows(newRows);
            setCountForPagination(result.data.total);
         })
         .catch((err) => {
            setRows([]);
            setCountForPagination(0);
            openSnackbar("error", t("react.prompts.list.error.while.loading"));
         })
         .finally(() => {
            setLoadData(LoadData.Loaded);
            setLoadKeywordsChange(false);
         });
   }, [ loadData, page, rowsPerPage, sortBy, sortDirection, loadKeywordsChange, keywords ]);

   const isSelected = (id) => selected.indexOf(id) !== -1;
   const handleEditPrompt = (event, promptIdToEdit) => {
      event.preventDefault();
      setOpenEditPromptDialog({ show: true, id: promptIdToEdit });
   };

   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
   const handleDeletePrompt = (event, row) => {
      event.preventDefault();
      setDeleteDialogOpen(true);
   };

   const handleDeleteDialogConfirm = () => {
      var promises = [];
      for (var i = 0; i < selected.length; i++) {
         promises.push(promptsService.deletePrompt(selected[i]));
      }

      Promise.all(promises)
         .then(function (values) {
            setDeleteDialogOpen(false);
            setLoadData(LoadData.Load);
            setSelected([]);
         })
         .catch((err) => {
            openSnackbar("error", t("react.prompts.list.error.while.deleting"));
         });
   };

   const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
   };

   const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

   return (
      <Grid item xs={12} className={classes.root}>
         {loadData !== LoadData.Loaded && !loadKeywordsChange ? (
            <LinearProgress />
         ) : (
            <Paper className={classes.paper}>
               <PopupTwoButtons
                  variant="warning"
                  openState={deleteDialogOpen}
                  callbackOnclose={handleDeleteDialogCancel}
                  callbackOnclickLeftButton={handleDeleteDialogCancel}
                  callbackOnclickRightButton={handleDeleteDialogConfirm}
                  title={t("react.prompts.list.confirm.delete.title")}
                  content={t("react.prompts.list.confirm.delete.description")}
                  leftButton={t("react.button.cancel")}
                  rightButton={t("react.button.delete")}
               />
               <PromptsListToolbar
                  {...props}
                  keywords={keywords}
                  selected={selected}
                  countForPagination={countForPagination}
                  handleDeletePrompt={handleDeletePrompt}
                  handleKeywordsChange={handleKeywordsChange}
                  setLoadData={setLoadData}
               />
               <div className={classes.tableWrapper}>
                  <Table className={classes.table} size="medium">
                     <PromptsListHead
                        t={t}
                        classes={classes}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={rows.length}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        handleChangeSort={handleChangeSort}
                     />
                     <TableBody>
                        {rows.map((row, index) => {
                           const isItemSelected = isSelected(row.id);
                           return (
                              <TableRow
                                 hover
                                 role="checkbox"
                                 tabIndex={-1}
                                 key={row.id}
                                 selected={isItemSelected}
                                 className={classes.tableRowCursorPointer}
                              >
                                 <TableCell padding="checkbox">
                                    <Checkbox
                                       id={`checkbox-${row.id}`}
                                       onClick={(event) =>
                                          handleClick(event, row.id)
                                       }
                                       checked={isItemSelected}
                                    />
                                 </TableCell>
                                 {headCells.map((column) => {
                                    let value = "";
                                    value = row[column.id];
                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                          onClick={(event) =>
                                             handleEditPrompt(event, row.id)
                                          }
                                       >
                                          {column.format
                                             ? column.format(row)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              </TableRow>
                           );
                        })}
                        {emptyRows > 0 && (
                           <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                           </TableRow>
                        )}
                     </TableBody>
                  </Table>
               </div>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={countForPagination}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={t("react.list.number.lignes.per.page")}
                  labelDisplayedRows={({ from, to, count }) =>
                     t("react.list.number.from.to.count", {
                        from: from,
                        to: to,
                        count: count,
                     })
                  }
                  getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         )}
      </Grid>
   );
}
