import React, { useState, useEffect } from 'react';
import { tss } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import StimulusCardVertical from './StimulusCardVertical';
import AddictsReportingChart from './AddictsReportingChart';
import TopicReportingChart from './TopicReportingChart';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Chip from '@mui/material/Chip';
import { ImgIcon } from '../../common.js';
import { appendSuffixIfGreaterThan, findById } from '../../utils/utils.js';
import CompareIcon from '@mui/icons-material/Compare';

const useStyles = tss
  .withParams()
  .create(({ theme, fontSize }) => ({
    legend: {
      maxWidth: '150px',
      minWidth: '150px',
      height: '390px',
      margin: '10px',
      position: 'absolute',
      top: 0, left: 0,
    },
    container: {
      paddingLeft: '180px',
      position: 'relative',
      overflow: "hidden"
    },
    logo: {
      width: '50%',
      margin: '0 auto',
      display: 'block'
    },
    emoji: {
      textAlign: 'center'
    },
    paper: {
      marginBottom: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: `${fontSize}px`,
    },
    chip: {
      margin: '5px'
    },
    gridFilters: {
      textAlign:'center',
    },
    toolsbar: {
      position: 'absolute',
      bottom: '0', right: '0'
    },
    globalCompareWithName: {
      position: 'absolute',
      bottom: '30px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    globalCompareWithNameIcon: {
      verticalAlign: 'sub',
      marginRight: '5px'
    }
}));

export default function PanelReporting(props) {

  const {t, openSnackbar, project, configuration, stimuli, filters, generateImage, stimulusCardToImage,
    collectImageCallback, topics, compareWiths, sequences, dashboardService } = props;

  const { classes } = useStyles({fontSize: configuration.dashboardFontSize});

  const [imageTitle, setImageTitle] = useState(project.name);

  const [globalFilterValues, setGlobalFilterValues] = useState([]);
  const [ignoreFiltersOnExport, setIgnoreFiltersOnExport] = useState(true);
  const [ignoreCompareWithOnExport, setIgnoreCompareWithOnExport] = useState(true);

  const [syntheseCardId] = useState([ `card_synthese_${project.id}`.replace('-', '_')]);
  const [filterPanelId] = useState([`filterpanel_${project.id}`.replace('-', '_')]);
  const [compareWithPanelId] = useState([`comparewithpanel_${project.id}`.replace('-', '_')]);

  const [addictsCardId] = useState([ `card_addicts_${project.id}`.replace('-', '_')]);
  const [addictsFilterPanelId] = useState([`addictsfilterpanel_${project.id}`.replace('-', '_')]);
  const [addictsCompareWithPanelId] = useState([`addictscomparewithpanel_${project.id}`.replace('-', '_')]);

  const [topicsCardId] = useState([ `card_topics_${project.id}`.replace('-', '_')]);
  const [topicsFilterPanelId] = useState([`topicsfilterpanel_${project.id}`.replace('-', '_')]);
  const [topicsCompareWithPanelId] = useState([`topicscomparewithpanel_${project.id}`.replace('-', '_')]);

  const [globalCompareWithName, setGlobalCompareWithName] = useState();

  useEffect(() => {
    if (configuration) {
      var globalCompareWith = findById(compareWiths, configuration.globalCompareWith);
      if(globalCompareWith) {
        var globalCompareWithName = dashboardService.getStimulusName(globalCompareWith, configuration, stimuli, sequences, t);
        setGlobalCompareWithName(globalCompareWithName);
      }

      setIgnoreFiltersOnExport(!configuration.exportFilters || !filters || filters.length === 0);
      setIgnoreCompareWithOnExport(true);

      // check which filters are applied to all stimulus, to display on the left
      let usedfilterValues = new Map();
      let filterValuesAffectedCount = new Map();

      let titleParts = [project.name];

      if(configuration.filters) {
        stimuli.forEach((_stimulus_, i) => {
          if(configuration.filters[_stimulus_.id]) {
            filters.forEach((_filter_, i) => {
              if(configuration.filters[_stimulus_.id][_filter_.id]) {
                configuration.filters[_stimulus_.id][_filter_.id].forEach((filterValue, i) => {
                  let count = filterValuesAffectedCount.get(filterValue.id);
                  if(!count) count = 0;
                  filterValuesAffectedCount.set(filterValue.id, ++count);
                  usedfilterValues.set(filterValue.id, filterValue);
                });
              }
            });
          }

          let engagementFilters = configuration.engagementFilters[_stimulus_.id]||[];
          engagementFilters.forEach((_engagementFilter_, i) => {
            let engagementFilterVal = `engagementFilterValue-${_engagementFilter_}`
            let count = filterValuesAffectedCount.get(engagementFilterVal);
            if(!count) count = 0;
            filterValuesAffectedCount.set(engagementFilterVal, ++count);

            var ef = {id: _engagementFilter_, value: 'undefined'};
            if(_engagementFilter_ === -1) {
              ef.value = t('react.dashboard.filterdialog.select.engagementType.rejectors');
            } else if (_engagementFilter_ === 0) {
              ef.value = t('react.dashboard.filterdialog.select.engagementType.neutrals');
            } else if (_engagementFilter_ === 1) {
              ef.value = t('react.dashboard.filterdialog.select.engagementType.lovers');
            }

            usedfilterValues.set(engagementFilterVal, ef);
          });
        });

        filterValuesAffectedCount.forEach((value, key) => {
          if(value !== stimuli.length) {
            // this filter value is NOT affected to all
            usedfilterValues.delete(key);
          }
        });

        let gfv = Array.from(usedfilterValues.values());
        setGlobalFilterValues(gfv);
        Array.prototype.push.apply(titleParts, gfv.map(e => e.value));
        setImageTitle(titleParts.join('-'));
      }
    }
  }, [configuration]);

  useEffect(() => {
    if(!generateImage) return;

    stimulusCardToImage(
      syntheseCardId,
      filterPanelId,
      compareWithPanelId,
      ignoreFiltersOnExport,
      ignoreCompareWithOnExport,
      `${imageTitle}-synthese`,
    ).then((result)=>{
      collectImageCallback(result);
    });

    if(configuration.showAddicts ) {
      stimulusCardToImage(
        addictsCardId,
        addictsFilterPanelId,
        addictsCompareWithPanelId,
        ignoreFiltersOnExport,
        ignoreCompareWithOnExport,
        `${imageTitle}-lovers-rejectors`,
      ).then((result)=>{
        collectImageCallback(result);
      });
    }

    if(topics.length > 0) {
      stimulusCardToImage(
        topicsCardId,
        topicsFilterPanelId,
        topicsCompareWithPanelId,
        ignoreFiltersOnExport,
        ignoreCompareWithOnExport,
        `${imageTitle}-topics`,
      ).then((result)=>{
        collectImageCallback(result);
      });
    }
  }, [
    generateImage,
    syntheseCardId,
    filterPanelId,
    compareWithPanelId,

    addictsCardId,
    addictsFilterPanelId,
    addictsCompareWithPanelId,

    topicsCardId,
    topicsFilterPanelId,
    topicsCompareWithPanelId,

    ignoreFiltersOnExport,
    ignoreCompareWithOnExport,
    project
  ]);

  const handleImageToClipboardClick = (blockId, filterBlockId, compareBlockId, name) => {
    stimulusCardToImage(
      blockId,
      filterBlockId,
      compareBlockId,
      ignoreFiltersOnExport,
      ignoreCompareWithOnExport,
      name,
      'clipboard');

    new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
      openSnackbar('success', t('react.dashboard.card.button.copied'));
    });
  }

  const computeMinMaxAddictsHaters = (stimuli) => {
    let maxAddicts = 0;
    let maxHaters = 0;

    stimuli.forEach(function(element){
      let addictsNb = Math.round(element.addictsPercentil);
      if(addictsNb > maxAddicts){
        maxAddicts = addictsNb;
      }

      let hatersNb = Math.round(element.hatersPercentil);
      if(hatersNb > maxHaters){
        maxHaters = hatersNb;
      }
    })
    return [maxAddicts,maxHaters];
  };

  const minMaxAddictsHaters = computeMinMaxAddictsHaters(stimuli);

  return <div>
    <Paper className={classes.paper} style={{marginTop : '33px'}}>
      <Grid container className={classes.container} id={syntheseCardId}>
        <Paper className={classes.legend} >
          <img src='/img/logo.png' className={classes.logo} />
          {<Grid item className={classes.gridFilters} id={filterPanelId}>
            {globalFilterValues.map(globalFilterValue => {
              return <Tooltip title={globalFilterValue.value} key={globalFilterValue.id}><Chip size='small' label={appendSuffixIfGreaterThan(globalFilterValue.value, '...',15)} variant="outlined" color="primary" className={classes.chip} /></Tooltip>;
            })}
          </Grid>}
          {globalCompareWithName &&
            <h4 className={classes.globalCompareWithName}>
              <CompareIcon fontSize='small' className={classes.globalCompareWithNameIcon}/>
              {globalCompareWithName}
            </h4>
          }
          <div className={classes.toolsbar} data-html2canvas-ignore='true'>
            <Tooltip title={t('react.dashboard.card.button.copy')}>
              <IconButton size='small' onClick={e => handleImageToClipboardClick(syntheseCardId, filterPanelId, compareWithPanelId, `${imageTitle}-synthese`)}>
                <ImgIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('react.dashboard.card.button.download')}>
              <IconButton size='small' onClick={e => stimulusCardToImage(
                  syntheseCardId,
                  filterPanelId,
                  compareWithPanelId,
                  ignoreFiltersOnExport,
                  ignoreCompareWithOnExport,
                  `${imageTitle}-synthese`,
                  'download')}>
                <GetAppIcon size='small' />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
        {
          stimuli.filter(stimulus => (configuration.showHiddenCards || (configuration.hidden || [])
          .indexOf(stimulus.id) === -1) && stimulus.id >= (configuration.showTotalCard ? -100 : 0))
          .map((stimulus, idx) => {

            return <StimulusCardVertical key={stimulus.id}
              {...props}
              stimulus={stimulus}
            />
            })
        }
      </Grid>
    </Paper>
    {configuration.showAddicts &&
    <Paper id={addictsCardId} className={classes.paper}>
      <Grid container className={classes.container}>
        <Paper className={classes.legend}>
          <img src='/img/logo.png' className={classes.logo} />
          <div className={classes.emoji}>
            <Tooltip title='Rejectors'>
              <SentimentVeryDissatisfiedIcon style={{color:'#ee3330', fontSize: 27, marginLeft: '5px'}}/>
            </Tooltip>
            &nbsp;
            <Tooltip title='Lovers'>
              <SentimentVerySatisfiedIcon style={{color:"#ffcc3c", fontSize: 27, marginRight: '5px'}}/>
            </Tooltip>
          </div>
          {<Grid item className={classes.gridFilters} id={addictsFilterPanelId}>
            {globalFilterValues.map(globalFilterValue => {
              return <Tooltip title={globalFilterValue.value} key={globalFilterValue.id}><Chip size='small' label={appendSuffixIfGreaterThan(globalFilterValue.value, '...',15)} variant="outlined" color="primary" className={classes.chip} /></Tooltip>;
            })}
          </Grid>}
          {globalCompareWithName &&
            <h4 className={classes.globalCompareWithName}>
              <CompareIcon fontSize='small' className={classes.globalCompareWithNameIcon}/>
              {globalCompareWithName}
            </h4>
          }
          <div className={classes.toolsbar} data-html2canvas-ignore='true'>
            <Tooltip title={t('react.dashboard.card.button.copy')}>
              <IconButton size='small' onClick={e => handleImageToClipboardClick(addictsCardId, addictsFilterPanelId, addictsCompareWithPanelId, `${imageTitle}-lovers-rejectors`)}>
                <ImgIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('react.dashboard.card.button.download')}>
              <IconButton size='small' onClick={e => stimulusCardToImage(
                  addictsCardId,
                  addictsFilterPanelId,
                  addictsCompareWithPanelId,
                  ignoreFiltersOnExport,
                  ignoreCompareWithOnExport,
                  `${imageTitle}-lovers-rejectors`,
                  'download')}>
                <GetAppIcon size='small' />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
        {
          stimuli.filter(stimulus => (configuration.showHiddenCards || (configuration.hidden || [])
          .indexOf(stimulus.id) === -1) && stimulus.id >= (configuration.showTotalCard ? -100 : 0))
          .map((stimulus, idx) => {

            return <AddictsReportingChart key={stimulus.id}
              {...props}
              stimulus={stimulus}
              minMaxAddictsHaters={minMaxAddictsHaters}
            />
          })
        }
      </Grid>
    </Paper>}
    {topics.filter(t => !t.hidden).length > 0 &&
      <Paper id={topicsCardId} className={classes.paper}>
        <Grid container className={classes.container}>
          <Paper className={classes.legend} >
            <img src='/img/logo.png' className={classes.logo} />
            {<Grid item className={classes.gridFilters}>
              {globalFilterValues.map(globalFilterValue => {
                return <Tooltip title={globalFilterValue.value} key={globalFilterValue.id}><Chip size='small' label={appendSuffixIfGreaterThan(globalFilterValue.value, '...',15)} variant="outlined" color="primary" className={classes.chip} /></Tooltip>;
              })}
            </Grid>}
            {globalCompareWithName &&
              <h4 className={classes.globalCompareWithName}>
                <CompareIcon fontSize='small' className={classes.globalCompareWithNameIcon}/>
                {globalCompareWithName}
              </h4>
            }
            <div className={classes.toolsbar} data-html2canvas-ignore='true'>
              <Tooltip title={t('react.dashboard.card.button.copy')}>
                <IconButton size='small' onClick={e => handleImageToClipboardClick(topicsCardId, topicsFilterPanelId, topicsCompareWithPanelId, `${imageTitle}-topics`)}>
                  <ImgIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('react.dashboard.card.button.download')}>
                <IconButton size='small' onClick={e => stimulusCardToImage(
                    topicsCardId,
                    topicsFilterPanelId,
                    topicsCompareWithPanelId,
                    ignoreFiltersOnExport,
                    ignoreCompareWithOnExport,
                    `${imageTitle}-topics`,
                    'download')}>
                  <GetAppIcon size='small' />
                </IconButton>
              </Tooltip>
            </div>
          </Paper>
          {
            topics.filter(t => !t.hidden).map((topic, idx) => {
              return <TopicReportingChart key={topic.id}
                {...props}
                topic={topic}
                stimuli={stimuli}
            />
            })
          }
        </Grid>
    </Paper>
    }
  </div>
}
