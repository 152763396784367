import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "tss-react/mui";
import BackupIcon from "@mui/icons-material/Backup";
import {appendSuffixIfGreaterThan} from "../../../utils/utils";

const useStyles = makeStyles()(theme => ({
	dropzoneContainer: {
		minHeight: '200px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		border: `2px dashed ${theme.palette.primary.main}`,
		borderRadius: '8px',
		padding: '16px',
		backgroundColor: theme.palette.background.paper,
		cursor: 'pointer',
		position: 'relative',
		transition: 'background-color 0.3s',
		fontWeight: "500",
		svg: {
			transition: 'fill 0.3s, transform 0.3s, border 0.3s',
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			svg: {
				fill: theme.palette.primary.main,
				transform: 'scale(1.3)',
			},
		},
		'&.disabled': {
			cursor: 'not-allowed',
			opacity: 0.3,
		},
		'&.dragging': {
			background: 'linear-gradient(45deg, #ddd 12.5%, #fff 12.5%, #fff 37.5%, #ddd 37.5%, #ddd 62.5%, #fff 62.5%, #fff 87.5%, #ddd 87.5%)',
			backgroundSize: '100px 100px',
			backgroundPosition: '50px 50px',
			borderColor: theme.palette.primary.dark,
			border: `2px solid ${theme.palette.primary.main}`,
			svg: {
				fill: theme.palette.primary.main,
				transform: 'scale(1.3)',
			},
		},
	},
	filePreview: {
		marginTop: '16px',
		display: 'flex',
		flexWrap: 'wrap',
		gap: '16px',
	},
	fileImage: {
		width: '80px',
		height: '80px',
		objectFit: 'cover',
		borderRadius: '4px',
		marginBottom: '8px',
		backgroundColor: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '42px',
		boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
	},
	fileItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		wordBreak: 'break-word',
		maxWidth: '100px',
		textAlign: 'center',
		position: 'relative',
	},
	fileName: {
		marginTop: '5px',
		fontSize: '12px',
	},
	deleteButton: {
		position: 'absolute',
		top: '-5px',
		right: '-5px',
		backgroundColor: '#f44336',
		color: '#fff',
		border: 'none',
		borderRadius: '100%',
		width: '25px',
		height: '25px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		transition: 'background-color 0.3s, transform 0.3s',
		"&:hover": {
			backgroundColor: '#d32f2f',
			transform: 'scale(1.1)',
		},
		svg: {
			transform: 'scale(1) !important',
			fill: "white !important",
		}
	},
	text: {
		minHeight: '200px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	}
}));

const acceptedFileTypes = {
	/*
		You can add more types here.
		Or using extension inside acceptedFiles array.
		Or using MIME type inside acceptedFiles array.
		Example: acceptedFiles={['.png', 'image/jpeg', 'video']}
	 */
	images: "image/*",
	audio: "audio/*",
	video: "video/*",
	pdf: "application/pdf",
	sheet: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	word: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	powerpoint: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
	zip: "application/zip",
	json: "application/json",
	csv: "text/csv",
};

const mimeToName = Object.entries(acceptedFileTypes).reduce(
	(acc, [name, mime]) => {
		acc[mime] = name;
		return acc;
	},
	{}
);

const Dropzone = ({dropzoneText, onChange, maxFileSize = 10000000, filesLimit = 5, showFileNames = true, openSnackbar, t, className = '',
	                  disabled = false, showAlerts = true, acceptedFiles = ['all'], truncatedName = false}) => {
	if (!t) {
		throw new Error("The 't' parameter is required for translations.");
	}
	const { classes } = useStyles();

	const [files, setFiles] = useState([]);
	const [isDragging, setIsDragging] = useState(false);
	const fileInputRef = useRef(null);

	const resolvedMimeTypes = acceptedFiles.includes('all')
		? ['all']
		: acceptedFiles.map(type => {
			if (type in acceptedFileTypes) {
				return acceptedFileTypes[type];
			}
			return type;
		});

	const readableTypes = resolvedMimeTypes.map(type =>
		mimeToName[type] || type
	);

	const handleFiles = newFiles => {
		const validFiles = newFiles.filter(file => {
			const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`;
			const isAccepted =
				(resolvedMimeTypes.includes('all') || resolvedMimeTypes.includes(file.type) || resolvedMimeTypes.includes(fileExtension));

			if (!isAccepted) {
				openSnackbar("error", t("react.shared.dropzone.not.allowed.type", {"name": file.name, "types": readableTypes.join(', ')}));
				return false;
			}
			if (file.size > maxFileSize) {
				openSnackbar('error', t("react.shared.dropzone.exceed.size", {"name": file.name, "size": Math.round(maxFileSize / 1024 / 1024)}));
				return false;
			}
			file.preview = URL.createObjectURL(file);
			showAlerts && openSnackbar("info", t("react.shared.dropzone.file.uploaded", {"name": file.name}));
			return true;
		});

		if (files.length + validFiles.length > filesLimit) {
			openSnackbar('error', t("react.shared.dropzone.file.limit", {"limit": filesLimit}));
			return;
		}

		setFiles(prevFiles => {
			return [...prevFiles, ...validFiles].slice(0, filesLimit);
		});
	};

	useEffect(() => {
		onChange(files);
	}, [files, onChange]);

	const handleDrop = event => {
		event.preventDefault();
		setIsDragging(false);
		if (disabled) return;

		const items = event.dataTransfer.items;

		let isFolder = false;

		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			const entry = item.webkitGetAsEntry ? item.webkitGetAsEntry() : null;

			if (entry && entry.isDirectory) {
				isFolder = true;
				break;
			}
		}

		if (isFolder) {
			openSnackbar('error', t("react.shared.dropzone.directory.error"));
			setIsDragging(false);
			return;
		}

		const newFiles = Array.from(event.dataTransfer.files);
		handleFiles(newFiles);
	};

	const handleDragOver = e => {
		e.preventDefault();
		if (!disabled) {
			setIsDragging(true);
		}
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const handleBrowseFiles = event => {
		if (disabled || !event.target.files) return;
		const newFiles = Array.from(event.target.files);
		handleFiles(newFiles);
		event.target.value = "";
	};

	const handleDeleteFile = (fileToDelete, index) => {
		setFiles(prevFiles => {
			const updatedFiles = prevFiles.filter((_, i) => i !== index);
			URL.revokeObjectURL(fileToDelete.preview);
			return updatedFiles;
		});
		showAlerts && openSnackbar("info", t("react.shared.dropzone.file.deleted", {"name": fileToDelete.name}));
	};

	return (
		<div
			className={`${classes.dropzoneContainer} ${className} ${disabled ? 'disabled' : ''} ${isDragging ? 'dragging' : ''}`}
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onClick={() => !disabled && fileInputRef.current.click()}
		>
			<input
				type="file"
				multiple
				style={{ display: 'none' }}
				id="fileInput"
				ref={fileInputRef}
				onChange={handleBrowseFiles}
				disabled={disabled}
				accept={resolvedMimeTypes
					.map(type => (type.startsWith('.') ? type : `${type}`))
					.join(',')}
			/>
			<div className={classes.text}>
				<BackupIcon fontSize="large" />
				<span>{dropzoneText}</span>
			</div>
			<div className={classes.filePreview}>
				{files.map((file, index) => (
					<div key={index} className={classes.fileItem}>
						{file.type.startsWith('image') ? (
							<img src={file.preview} alt={file.name} className={classes.fileImage} />
						) : (<div className={classes.fileImage}>📄</div>)
						}
						{showFileNames && <span className={classes.fileName}>{truncatedName ? appendSuffixIfGreaterThan(file.name, "...", 20) : file.name}</span>}
						<button
							type="button"
							className={classes.deleteButton}
							onClick={e => { e.stopPropagation(); handleDeleteFile(file, index); }}
						>
							<CloseIcon fontSize="small" />
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default Dropzone;
