import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import TextField from '@mui/material/TextField';
import { SwatchesPicker } from 'react-color';
import { makeStyles } from 'tss-react/mui';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles()(theme => ({
    paper: {
        minWidth: '700px',
        maxWidth: '700px',
        height: '630px',
    },
}));


export default function CreateEditTagDialog(props) {

    const {
        t, groups, tag, open, handleClose, handleApply
    } = props;

    const { classes } = useStyles();

    const [id, setId] = useState();
    const [label, setLabel] = useState('');
    const [color, setColor] = useState('');
    const [checked, setChecked] = useState([]);

    const save = (e) => {
        e.preventDefault();
        if (label && color) {
            handleApply(id, label, color, checked);
        }
    }

    const handleColorChange = (color, e) => {
        setColor(color.hex);
    }

    useEffect(() => {
        if (!open) return;

        setLabel(tag ? tag.label : '');
        setColor(tag ? tag.color : '#ffcdd2');
        setId(tag ? tag.id : null);
        setChecked(groups.filter(g => g.tag && tag && g.tag.id === tag.id).map(g => g.id));
    }, [open]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const getGroupBackgroundColor = (group, index) => {
        if(checked.indexOf(group.id) !== -1) {
            // if the element is checked we want to have this color on the group
            return color;
        }

        // let's go for unchecked groups

        // if the group has no tag, returns undefined
        if(!group.tag || !group.tag.color) return undefined;

        // if the group's tag is the same thant the current one
        // we are removing the tag from the group (cause unchecked!)
        if(tag && group.tag.id === tag.id) return undefined;

        // else display the group color
        return group.tag.color;
    };

    return (
        <form noValidate autoComplete="off">
            <Dialog
                classes={{ paper: classes.paper}}
                open={open}
                onClose={handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t(tag ? 'react.grouping.edittagdialog.title' : 'react.grouping.createtagdialog.title')}
                    callbackOnclose={handleClose}
                />
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid xs={6} item style={{ width: '450px' }}>
                            <div style={{ display: 'inline-flex', marginBottom: '15px' }}>
                                <TextField fullWidth value={label} onChange={e => { setLabel(e.target.value) }} label="Label" autoFocus/>
                            </div>
                            <SwatchesPicker height={386} onChange={handleColorChange} color={color} />
                        </Grid>
                        <Grid xs={6} item>
                            <List>
                                <div style={{ height: '445px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    {(groups || []).filter(g => !g.trashbin).map((group, index) => {

                                        return (
                                            <ListItem 
                                                key={index} 
                                                role={undefined} 
                                                dense 
                                                button 
                                                onClick={handleToggle(group.id)} 
                                                style={{ backgroundColor: getGroupBackgroundColor(group, index) }}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        id={`checkbox-${group.id}`}
                                                        style={{ height: '20px' }}
                                                        size='small'
                                                        edge="start"
                                                        checked={checked.indexOf(group.id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={group.title.toUpperCase()} />
                                                <ListItemSecondaryAction>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </div>
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button onClick={save} autoFocus disabled={!(label && color)}>
                        {t('react.button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    )
};
