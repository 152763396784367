import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

const useStyles = makeStyles()({
    container: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 3,
        paddingBottom: 3,
        height: 400,
        overflowY: 'auto'
    },
    titleCard: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        color: 'rgb(51,51,51)',
        marginBottom: 5
    },
    tableContainer: {
        height: 400,
    },
    typo: {
        marginLeft: 16,
        marginTop: 20,
        fontWeight: 500
    },
    percentage: {
        color: 'gray',
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
    },
});

export default function CellsTable(props) {

    const { classes } = useStyles();

    const {
        t,
        thisQuestion,
        cells,
        index
    } = props;

    const totalAnswersZoneAndBattery = (answers, subindex) => {
        return answers.reduce((total, a) => total + a.perCell[subindex].nbAnswers, 0);
    }

    return (
        thisQuestion.answers !== undefined ?
            // Table for profile questions
            <TableContainer>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            {cells.map((cell, indexCell) => (
                                <TableCell key={indexCell}>{cell.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>N={thisQuestion.base}</TableCell>
                            {cells.map((cell, indexCell) => (
                                <TableCell key={indexCell}>N={cell.base}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {thisQuestion.answers !== undefined && thisQuestion.answers.map((answer, indexAnswer) => (
                            <TableRow key={indexAnswer}>
                                <TableCell>{answer.answer}</TableCell>
                                <TableCell>{answer.nbAnswers} <span className={classes.percentage}>({thisQuestion.base !== 0 ? `${Math.round(answer.nbAnswers/thisQuestion.base*100)}%`: '0%'})</span></TableCell>
                                {answer.perCell.map((cell, indexCell) => {
                                    const totalCellAnswers = thisQuestion.answers.reduce((sum, ans) => sum + ans.perCell[indexCell].nbAnswers, 0);
                                    const percentage = totalCellAnswers !== 0 ? Math.round(cell.nbAnswers / totalCellAnswers * 100) : 0;
                                    return (
                                        <TableCell key={indexCell}>
                                            {cell.nbAnswers} <span className={classes.percentage}>({percentage}%)</span>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> : thisQuestion.zones !== undefined ?
            // Table for hotspot questions
                thisQuestion.zones.map((zone, indexZone) => (
                    <div key={indexZone}>
                        <Typography className={classes.typo}>{`${thisQuestion.ref}.${zone.ref} : ${zone.name}`}</Typography>
                        <TableContainer>
                            <Table size="small" className={classes.table}>
                                {indexZone === 0 && <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}></TableCell>
                                        {cells.map((cell, indexCell) => (
                                            <TableCell key={indexCell}>{cell.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>}
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total</TableCell>
                                        <TableCell>N={zone.base}</TableCell>
                                        {zone.answers[0].perCell.map((a, subindex) => (
                                            <TableCell key={subindex}>N={totalAnswersZoneAndBattery(zone.answers, subindex)}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {zone.answers.map((answer, indexAnswer) => (
                                        <TableRow key={indexAnswer}>
                                            <TableCell>{answer.text}</TableCell>
                                            <TableCell>{answer.nbAnswers} <span className={classes.percentage}>({zone.base !== 0 ? `${Math.round(answer.nbAnswers / zone.base * 100)}%` : '0%'})</span></TableCell>
                                            {answer.perCell.map((cell, indexCell) => {
                                                const totalCellAnswers = zone.answers.reduce((sum, ans) => sum + ans.perCell[indexCell].nbAnswers, 0);
                                                const percentage = totalCellAnswers !== 0 ? Math.round(cell.nbAnswers / totalCellAnswers * 100) : 0;
                                                return (
                                                    <TableCell key={indexCell}>
                                                        {cell.nbAnswers} <span className={classes.percentage}>({percentage}%)</span>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )) : thisQuestion.items !== undefined ?
                thisQuestion.items.map((item, indexItem) => (
                // Table for battery of items
                    <div key={indexItem}>
                        <Typography className={classes.typo}>{`${thisQuestion.ref}.${item.ref} : ${item.name}`}</Typography>
                        <TableContainer>
                            <Table size="small" className={classes.table}>
                                {indexItem === 0 && <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}></TableCell>
                                        {cells.map((cell, indexCell) => (
                                            <TableCell key={indexCell}>{cell.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>}
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total</TableCell>
                                        <TableCell>N={item.base}</TableCell>
                                        {item.answers[0].perCell.map((i, subindex) => (
                                            <TableCell key={subindex}>N={totalAnswersZoneAndBattery(item.answers, subindex)}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.answers.map((answer, indexAnswer) => (
                                        <TableRow key={indexAnswer}>
                                            <TableCell>{answer.text}</TableCell>
                                            <TableCell>{answer.nbAnswers} <span className={classes.percentage}>({item.base !== 0 ? `${Math.round(answer.nbAnswers/item.base*100)}%`: '0%'})</span></TableCell>
                                            {answer.perCell.map((cell, indexCell) => {
                                                const totalCellAnswers = item.answers.reduce((sum, ans) => sum + ans.perCell[indexCell].nbAnswers, 0);
                                                const percentage = totalCellAnswers !== 0 ? Math.round(cell.nbAnswers / totalCellAnswers * 100) : 0;
                                                return (
                                                    <TableCell key={indexCell}>
                                                        {cell.nbAnswers} <span className={classes.percentage}>({percentage}%)</span>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )) : thisQuestion.queryParameters !== undefined &&
                    thisQuestion.queryParameters.map((param, indexParam) => (
                            <div key={indexParam}>
                                <Typography className={classes.typo}>{`PARAM_${param.name.toUpperCase()}`}</Typography>
                                <TableContainer>
                                    <Table size="small" className={classes.table}>
                                        {indexParam === 0 && <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2}></TableCell>
                                                {cells.map((cell, indexCell) => (
                                                    <TableCell className={classes.tableCell} key={indexCell}>{cell.name}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>}
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Total</TableCell>
                                                <TableCell>N={param.count}</TableCell>
                                                {param.values[0]?.perCell.map((i, subindex) => (
                                                    <TableCell key={subindex}>N={totalAnswersZoneAndBattery(param.values, subindex)}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {param.values.map((val, indexAnswer) => (
                                                <TableRow key={indexAnswer}>
                                                    <TableCell>{val.val}</TableCell>
                                                    <TableCell>{val.count} <span className={classes.percentage}>({param.count !== 0 ? `${Math.round((val.count / param.count) * 100)}%` : "0%"})</span></TableCell>
                                                    {val.perCell.map((cell, indexCell) => {
                                                        const totalCellAnswers = param.values.reduce((sum, ans) => sum + ans.perCell[indexCell].nbAnswers, 0);
                                                        const percentage = totalCellAnswers !== 0 ? Math.round((cell.nbAnswers / totalCellAnswers) * 100) : 0;
                                                        return (
                                                            <TableCell key={indexCell}>
                                                                {cell.nbAnswers} <span className={classes.percentage}>({percentage}%)</span>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )
                    )
    )
};