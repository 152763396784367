import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function PieChartQueryParameters(props) {
    const { t, index, param } = props;

    const pieChartQueryParameters = (index) => {
        if (!param || !param.values) {
            return {};
        }

        const values = param.values.map(value => ({
            name: value.val,
            y: value.count
        }));

        const countTotalAnswers = param.count;

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: `${param.name}: ${countTotalAnswers} ${t("react.reporting.survey.answers")}`
            },
            tooltip: {
                formatter: function () {
                    return `${this.point.name}: <b>${Math.round((this.y / this.total) * 100)}%</b>`;
                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}',
                        distance: 30,
                        style: {
                            fontWeight: 300
                        }
                    },
                    size: 240
                }
            },
            series: [{
                name: t('react.reporting.survey.answers'),
                colorByPoint: true,
                data: values
            }],
            credits: {
                enabled: false
            },
        };
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={pieChartQueryParameters(index)}/>
    );
};
