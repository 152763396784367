import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { SurveyContext } from './context';
import { TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import {isBlank, getMimeType, stripHtmlTags } from '../../utils/utils';
import SurveysService from '../../services/SurveysService';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import QuillEditor from './QuillEditor.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const surveysService = new SurveysService();

const useStyles = makeStyles()(theme => ({
    textFieldEditAnswer: {
        marginBottom: "10px",
        marginLeft: "17px",
        marginTop: '15px',
        width: "360px"
    },
    textFieldEditItem: {
        marginTop: '15px',
        width: '100%', 
        marginBottom: '10px'
    },
    editIcon: {
        marginRight: '10px',
        verticalAlign: 'middle'
    },
    previewRoot: {
        marginBottom: '25px',
        margin: '0',
        padding: '5px',
        width: `250px`,
        height: `150px`,
        display: 'inline-block',
        borderStyle: 'dotted',
        borderRadius: '7px',
        marginLeft: 70,
    },
    previewImg: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '140px',
        position: 'relative',
        top: '-50px'
    },
    previewBtns: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        height: '50px',
        position: 'relative', top: '25%', zIndex: '100'
    },
    alignCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '200px'
    },
    dialogComponent: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    typoQuillEditor: {
        marginLeft: 5,
        marginBottom: 5
    },
    formControlFreeField: {
        marginBottom: 10
    }
}));

export default function ParamsQuestionsBatteryOfItems(props) {

    const { classes } = useStyles();

    const { t, uuid, setOpenDialogQuestions, openDialogQuestions, answerIndex, itemIndex, typeOfEditing } = props;

    const { setNeedToSave, getComponentConfiguration, formIdParameter, mediaFiles, setMediaFiles } = useContext(SurveyContext);
        
    const [valueLabel, setValueLabel] = useState('');
    const [valueQuestion, setValueQuestion] = useState('');
    const [textLeft, setTextLeft] = useState('');
    const [textRight, setTextRight] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
    const [hasImage, setHasImage] = useState(-1); // -1 = img is not supported / 0 = no img loaded / xxx > 0 id of the image
    const [itemThisOrThat, setItemThisOrThat] = useState(false);
    const [freeField, setFreeField] = useState(false);
    const [valueFixedPosition, setValueFixedPosition] = useState(false);
    const [valueScreenOut, setValueScreenOut] = useState(false);

    const quillRef = useRef();
    const quillLeftRef = useRef();
    const quillRightRef = useRef();

    useEffect(() => {
        if (!openDialogQuestions) return;

        // get current component we are configuring
        let thisComponent = getComponentConfiguration(uuid);

        if (typeOfEditing === "answer") {
            setValueLabel(thisComponent.battery.answers[answerIndex].text);
            setValueQuestion('');
            if (thisComponent.battery.answers[answerIndex].imageId > 0) {
                setHasImage(thisComponent.battery.answers[answerIndex].imageId);
            } else {
                setHasImage(0);
            }
            setFreeField(thisComponent.battery.answers[answerIndex].freeField);
            setValueFixedPosition(thisComponent.battery.answers[answerIndex].fixedPosition);
            setValueScreenOut(thisComponent.battery.answers[answerIndex].screenOut);
        } else {
            setItemThisOrThat(thisComponent.battery.itemsStyle === "thisOrThat");
            setValueLabel(thisComponent.battery.items[itemIndex].name);
            setValueQuestion(thisComponent.battery.items[itemIndex].text);
            setTextLeft(thisComponent.battery.items[itemIndex].textLeft || '');
            setTextRight(thisComponent.battery.items[itemIndex].textRight || '');

            if (thisComponent.battery.items[itemIndex].imageId > 0) {
                setHasImage(thisComponent.battery.items[itemIndex].imageId);
            } else {
                setHasImage(0);
            }
        }

        setUploading(false);
        setUploadButtonVisibility(false);
    }, [openDialogQuestions]);

    const saveParamsAnswer = () => {
        let thisComponent = getComponentConfiguration(uuid);
        setOpenDialogQuestions(false); 
        if (typeOfEditing === "answer") {
            thisComponent.battery.answers[answerIndex].text = valueLabel;
            thisComponent.battery.answers[answerIndex].freeField = freeField;
            thisComponent.battery.answers[answerIndex].fixedPosition = valueFixedPosition;
            thisComponent.battery.answers[answerIndex].screenOut = valueScreenOut;
        } else {
            thisComponent.battery.items[itemIndex].name = valueLabel;
            thisComponent.battery.items[itemIndex].text = valueQuestion;
            thisComponent.battery.items[itemIndex].textLeft = textLeft;
            thisComponent.battery.items[itemIndex].textRight = textRight;
        }
        setNeedToSave(true);
    };

    const editItemText = (value) => {
        if (valueLabel === stripHtmlTags(valueQuestion)) {
            setValueLabel(stripHtmlTags(value));
        }
        setValueQuestion(value);
    }

    const uploadFile = (file) => {
        return surveysService.uploadFormFile(formIdParameter, file)
            .then(response => {
                surveysService.getFormFileUrl(formIdParameter, response.data.id).then(res => {
                    const newfiles = { ...mediaFiles };
                    newfiles[res.id] = res.url;
                    setMediaFiles(newfiles);
                });
                return response;
            });
    }

    const generateSetImageButton = (uuid, setUploading, type) => {
        let title = t("react.project.collectforms.button.selectfile.tooltip");
        return (
            <span>
                <IconButton variant="outlined" color="primary" component="label" size="large" title={title}>
                    <ImageIcon />
                    <input type="file" accept="image/*" onChange={(event) => handleUploadMediaFile(event, uuid, setUploading, type)} style={{ display: "none" }} />
                </IconButton>
            </span>
        );
    };

    const generateDeleteImageButton = (uuid, type) => {
        const config = getComponentConfiguration(uuid);
        var imageId;

        if (type === 'item') {
            imageId = config.battery.items[itemIndex].imageId;
        } else if (type === 'answer') {
            imageId = config.battery.answers[answerIndex].imageId;
        }
    
        if (imageId > 0) {
            const title = t("react.project.collectforms.button.deletefile.tooltip");
    
            return (
                <IconButton
                    variant="outlined"
                    color="primary"
                    component="label"
                    onClick={() => handleDeleteMediaFile(uuid, imageId, type)}
                    size="large"
                    title={title}
                >
                    <DeleteIcon />
                </IconButton>
            );
        }
    
        return null; // Retourne null si aucune image n'est associée
    };

    const handleUploadMediaFile = (event, uuid, setUploading, type) => {
        // read the incoming file and detect its type
        const file = event.target.files[0];
        const mimeType = getMimeType(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            setUploading(true);

            // read asked ArrayBuffer
            const arrayBufferView = e.target.result;
                
            // create blob and image
            const blob = new Blob([arrayBufferView], {type: mimeType});
            const destfile = new File([blob], file.name, {
                    lastModified: new Date(),
                type: mimeType
            });

            // upload the file using our api
            uploadFile(destfile).then(response => {
                if (type === 'item') {
                    getComponentConfiguration(uuid).battery.items[itemIndex].imageId = response.data.id;
                } else {
                    getComponentConfiguration(uuid).battery.answers[answerIndex].imageId = response.data.id;
                }
                    setNeedToSave(true);
                }).catch((err) => {
                    console.error(err);
                }).finally(() => {
                    setUploading(false);
                });
        };

        // ask content as ArrayBuffer
        reader.readAsArrayBuffer(file);
    };

    const handleDeleteMediaFile = (uuid, mediaFileId, type) => {
        if (type === 'item') {
            getComponentConfiguration(uuid).battery.items[itemIndex].imageId = 0;
        } else {
            getComponentConfiguration(uuid).battery.answers[answerIndex].imageId = 0;
        }
        delete mediaFiles[mediaFileId];
        setNeedToSave(true);
    }

    const cancelPopup = () => {
        // remove an added image on cancel (to do not store it if cancelled)
        if (typeOfEditing === "item") {
            if (hasImage <= 0 /* no image at load */ && getComponentConfiguration(uuid).battery.items[itemIndex].imageId > 0 /* an image has been added */) {
                let mediaFileId = getComponentConfiguration(uuid).battery.items[itemIndex].imageId;
                getComponentConfiguration(uuid).battery.items[itemIndex].imageId = 0;
                delete mediaFiles[mediaFileId];
            } 
        } else if (typeOfEditing === "answer") {
            if (hasImage <= 0 /* no image at load */ && getComponentConfiguration(uuid).battery.answers[answerIndex].imageId > 0 /* an image has been added */) {
                let mediaFileId = getComponentConfiguration(uuid).battery.answers[answerIndex].imageId;
                getComponentConfiguration(uuid).battery.answers[answerIndex].imageId = 0;
                delete mediaFiles[mediaFileId];
            } 
        }
        setOpenDialogQuestions(false);
    }

    const isFreeFieldSwitchReachable = () => {
        const howManyOtherAnswersHaveTheFreeFieldChecked = getComponentConfiguration(uuid).battery.answers
            // except the current answer
            .filter((a, idx) => idx !== answerIndex && a.freeField)
            .length;

        // YES if no other free field
        return howManyOtherAnswersHaveTheFreeFieldChecked === 0;
    }

    const disabledSave = useMemo(() => {
        if (typeOfEditing === "item") {
            if (itemThisOrThat) {
                return isBlankHtml(textLeft) || isBlankHtml(textRight) || isBlank(valueLabel) || textLeft.length > 255 || textRight.length > 255 || valueLabel.length > 255;
            } else {
                return isBlankHtml(valueQuestion) || isBlank(valueLabel) || valueQuestion.length > 255 || valueLabel.length > 255;
            }
        }
        return isBlank(valueLabel);
    }, [typeOfEditing, valueQuestion, valueLabel, itemThisOrThat, textLeft, textRight]);

    if(!openDialogQuestions) return null;

    return (
        <Dialog
            open={openDialogQuestions}
            maxWidth={typeOfEditing === "item" ? getComponentConfiguration(uuid).battery.itemsStyle === "thisOrThat" ? 'md' : 'sm' : 'xs'}
            fullWidth={true}
        >
            <DialogTitleWithCloseIcon
                startIcon={<EditIcon color='primary' className={classes.editIcon} />}
                title={typeOfEditing === "item" ? t("react.project.collectforms.params.items") : t("react.project.collectforms.params.answer")}
                callbackOnclose={() => cancelPopup()}
            />
            <DialogContent component="div" >
                <DialogContentText component="div" className={classes.dialogComponent}>
                    {typeOfEditing === 'item' ?
                        <>
                            <TextField
                                value={valueLabel}
                                onChange={(e) => setValueLabel(e.target.value)}
                                className={classes.textFieldEditItem}
                                label={t("react.project.collectforms.params.edit.items.label")}
                                variant="outlined"
                                error={isBlank(valueLabel)}
                                InputLabelProps={{shrink: true}}
                            />
                            {itemThisOrThat ? 
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '49%', marginRight: '1%'}}>
                                        <Typography className={classes.typoQuillEditor}>{t("react.surveys.thisOrThat.left.label")}</Typography>
                                        <QuillEditor
                                            {...props}
                                            ref={quillLeftRef}
                                            value={textLeft}
                                            onChange={(value) => setTextLeft(value)}
                                            isError={isBlankHtml(textLeft)}
                                            style={{height: 300, paddingBottom: 42}}
                                        />
                                    </div>
                                    <div style={{width: '49%', marginLeft: '1%'}}>
                                        <Typography className={classes.typoQuillEditor}>{t("react.surveys.thisOrThat.right.label")}</Typography>
                                        <QuillEditor
                                            {...props}
                                            ref={quillRightRef}
                                            value={textRight}
                                            onChange={(value) => setTextRight(value)}
                                            isError={isBlankHtml(textRight)}
                                            style={{height: 300, paddingBottom: 42}}
                                        />
                                    </div>
                                </div> : 
                                <> 
                                    <QuillEditor
                                        {...props}
                                        ref={quillRef}
                                        value={valueQuestion}
                                        onChange={(value) => editItemText(value)}
                                        isError={isBlankHtml(valueQuestion)}
                                        style={{height: 300, paddingBottom: 42}}
                                    />
                                    <div style={{marginLeft: 80, marginTop: 20}}>
                                        <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                                            onMouseOver={() => setUploadButtonVisibility(true)}
                                            onMouseOut={() => setUploadButtonVisibility(false)}
                                        >
                                            {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                                                <div style={{ display: (formIdParameter > 0 && (uploading || true === true || uploadButtonVisibility)) ? 'block' : 'none' }}>
                                                    {!uploading && generateSetImageButton(uuid, setUploading, 'item')}
                                                    {!uploading && generateDeleteImageButton(uuid, 'item')}
                                                    {uploading && <CircularProgress />}
                                                </div>
                                            </div>}
                                            {getComponentConfiguration(uuid).battery.items[itemIndex].imageId > 0 && <div className={classes.alignCenter}>
                                                <img
                                                    id={`img_${uuid}`}
                                                    className={classes.previewImg}
                                                    src={mediaFiles[getComponentConfiguration(uuid).battery.items[itemIndex].imageId]}
                                                    style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                                            </div>}
                                        </div>
                                    </div>
                                </>
                            }
                        </> : 
                        <>
                            <TextField
                                value={valueLabel}
                                multiline={true}
                                onChange={(e) => setValueLabel(e.target.value)}
                                className={classes.textFieldEditAnswer}
                                label={typeOfEditing === "item" ? t("react.project.collectforms.params.edit.items") : t('react.project.collectforms.params.edit.answer')}
                                variant="outlined"
                                error={isBlank(valueLabel)}
                                fullWidth
                            />
                            <br />
                            {getComponentConfiguration(uuid).battery.shuffleAnswers &&
                                <>
                                    <FormControlLabel
                                        disabled={getComponentConfiguration(uuid).battery.shuffleAnswers === false}
                                        control={<Switch checked={valueFixedPosition === true}
                                            color="secondary"
                                            onChange={() => setValueFixedPosition(!valueFixedPosition)} />}
                                        label={t("react.project.collectforms.answer.fixed")}
                                        labelPlacement="start"
                                    />
                                    <br />
                                </>
                            }
                            <FormControlLabel
                                control={<Switch checked={valueScreenOut === true}
                                    color="secondary"
                                    onChange={() => setValueScreenOut(!valueScreenOut)} />}
                                label={t("react.project.collectforms.answer.screenout")}
                                labelPlacement="start"
                            />
                            <br />
                            <FormControlLabel
                                disabled={!isFreeFieldSwitchReachable()}
                                control={<Switch checked={freeField === true}
                                    color="secondary"
                                    onChange={() => setFreeField(!freeField)} />}
                                label={t("react.project.collectforms.answer.freefield")}
                                labelPlacement="start"
                                className={classes.formControlFreeField}
                            />
                            
                            <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                                onMouseOver={() => setUploadButtonVisibility(true)}
                                onMouseOut={() => setUploadButtonVisibility(false)}
                            >
                                {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                                    <div style={{ display: (formIdParameter > 0 && (uploading || true === true || uploadButtonVisibility)) ? 'block' : 'none' }}>
                                        {!uploading && generateSetImageButton(uuid, setUploading, 'answer')}
                                        {!uploading && generateDeleteImageButton(uuid, 'answer')}
                                        {uploading && <CircularProgress />}
                                    </div>
                                </div>}
                                {getComponentConfiguration(uuid).battery.answers[answerIndex].imageId > 0 && <div className={classes.alignCenter}>
                                    <img
                                        id={`img_${uuid}`}
                                        className={classes.previewImg}
                                        src={mediaFiles[getComponentConfiguration(uuid).battery.answers[answerIndex].imageId]}
                                        style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                                </div>}
                            </div>
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CloseIcon />}
                    onClick={() => cancelPopup()}
                >
                    {t('react.button.cancel')}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<SaveIcon />}
                    color="primary"
                    disabled={disabledSave}
                    onClick={() => saveParamsAnswer()}
                >
                    {t('react.button.save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
