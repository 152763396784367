import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import ProjectService from '../../../services/ProjectService'
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PopupTwoButtons from '../../shared/PopupTwoButtons/PopupTwoButtons';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { LoadData } from '../../../Constants.js';

const projectService = new ProjectService();

const useStyles = makeStyles()((theme) => ({
  projectList: {
    width: '100%',
    minHeight: '380px',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: '15px'
  },
}));

export default function CopyGroupingDialog(props) {

  // expected parameters
  const {
    t,
    openState,
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar
  } = props;

  const { classes } = useStyles();

  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [from, setFrom] = useState(0);
  const [size] = useState(10);
  const [keywords, setKeywords] = useState('');
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [displayConfirmCopy, setDisplayConfirmCopy] = useState(false);

  const handleClickApply = (event) => {
    event.preventDefault();
    setDisplayConfirmCopy(true);
  }

  const handleClickConfirm = () => {
    setDisplayConfirmCopy(false);
    callbackAfterConfirm(selectedProjectIds);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (!openState) {
      // reset all
      setFrom(0);
      setKeywords('');
      setProjects([]);
      setTotal(0);
      setSelectedProjectIds([]);
      setLoadData(LoadData.Load);
      return;
    }

    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    projectService.fetchProjects(from, size, "name", "asc", keywords)
      .then(result => {
        setProjects(result.data.hits);
        setTotal(result.data.total);
      })
      .catch(err => {
        openSnackbar('error', t("react.generic.error.while.loading"));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
      });
  }, [openState, loadData]);

  const handleClickCancel = (event) => {
    event.preventDefault();
    callbackAfterCancel();
  };

  const handleClickPrevious = (event) => {
    event.preventDefault();
    setFrom(from - size);
    setLoadData(LoadData.Load);
  };

  const handleClickNext = (event) => {
    event.preventDefault();
    setFrom(from + size);
    setLoadData(LoadData.Load);
  };

  const handleKeywordsChange = (newValue) => {
    setFrom(0);
    setKeywords(newValue);
    setLoadData(LoadData.Load);
  };

  const handleToggle = (newValue) => {
    let newselectedProjectIds = [...selectedProjectIds];
    let idx = newselectedProjectIds.indexOf(newValue);
    if(idx >= 0) newselectedProjectIds.splice(idx, 1);
    else newselectedProjectIds.push(newValue);
    setSelectedProjectIds(newselectedProjectIds);
  };

  return (
    <div>
      <PopupTwoButtons
        variant='warning'
        openState={displayConfirmCopy}
        callbackOnclose={() => setDisplayConfirmCopy(false)}
        callbackOnclickLeftButton={() => setDisplayConfirmCopy(false)}
        callbackOnclickRightButton={handleClickConfirm}
        title={t('react.grouping.copygroupingdialog.confirm.title')}
        content={t('react.grouping.copygroupingdialog.confirm.body')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.confirm')}
      />

      <Dialog open={openState} onClose={handleClickCancel}>
        <DialogTitleWithCloseIcon
          title={t('react.grouping.copygroupingdialog.title')}
          callbackOnclose={handleClickCancel}
        />
        <DialogContent>
          <DialogContentText>
            {t("react.grouping.copygroupingdialog.content")}
          </DialogContentText>
          <Input
            id="search-filter-basic"
            value={keywords}
            autoFocus
            className={classes.searchfield}
            placeholder={t('react.translationtool.search.title')}
            onChange={e => handleKeywordsChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => handleKeywordsChange('')} edge="end" size="large">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <List className={classes.projectList} >
            {
              projects.map((row) => (
                <ListItem selected={selectedProjectIds.indexOf(row.id) !== -1} key={`ListItem-${row.id}`} dense button>
                  <ListItemText id={`ListItemText-${row.id}`} primary={row.name} onClick={() => handleToggle(row.id)}/>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      id={`checkbox-${row.id}`}
                      onChange={() => handleToggle(row.id)}
                      checked={selectedProjectIds.indexOf(row.id) !== -1}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
          <Tooltip title={t('react.list.button.previous.page')}>
            <span>
              <IconButton
                disabled={from === 0}
                onClick={handleClickPrevious}
                size="large">
                <KeyboardArrowLeftIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('react.list.button.next.page')}>
            <span>
              <IconButton
                disabled={(from + size) >= total}
                onClick={handleClickNext}
                size="large">
                <KeyboardArrowRightIcon />
              </IconButton>
            </span>
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancel} color="primary">
            {t("react.button.cancel")}
          </Button>
          <Button onClick={handleClickApply} color="primary" disabled={selectedProjectIds.length === 0}>
            {t('react.button.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};