import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import ProjectService from '../../services/ProjectService';
import DashboardService from '../../services/DashboardService';
import { AuthService } from '../../services/AuthService';
import LinearProgress from '@mui/material/LinearProgress';
import { findById, indexOf, sleep, cloneDeep, removeValueFromArray } from '../../utils/utils';
import DashboardToolbar from './DashboardToolbar'
import PanelDashboard from './PanelDashboard';
import ImportImageStimulusDialog from './ImportImageStimulusDialog';
import { getDefaultConfiguration, LoadData } from '../../Constants';
import { saveAs } from 'file-saver';
import JSZip from 'jszip'
import PanelReporting from './PanelReporting';
import domtoimage from 'dom-to-image';
import { configIdParamAtom } from "../../recoil/ProjectDashboard/atom";
import { useRecoilState } from "recoil";
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import {Helmet} from "react-helmet";

const projectService = new ProjectService();
const dashboardService = new DashboardService();

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  imgUpload: {
    position: 'absolute',
    left: '50%',
    top: '10vh',
    transform: 'translate(-50%)',
    zIndex: 1000,
    backgroundColor: 'white',
    padding: '40px',
    minHeight: '400px',
    minWidth: '400px'
},
closeIconImg: {
    position: 'absolute',
    right: '-5px',
    top: '-5px',
}
}));

export default function ProjectDashboard(props) {
  const { t, openSnackbar, showSpinner } = props;

  const { classes } = useStyles();
  const [project, setProject] = useState({});
  const [stimuli, setStimuli] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [compareWiths, setCompareWiths] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [tags, setTags] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const { projectIdParameter } = useParams();
  const [configuration, setConfiguration] = useState();
  const [allimages, setAllImages] = useState([]);
  const [generateImage, setGenerateImage] = useState(false);
  const [stimulusVisuals, setStimulusVisuals] = useState([]);
  const [showImg, setShowImg] = useState({ show: false, src: '' });
  const [showAiAnalysis, setShowAiAnalysis] = useState(0);
  const [aiAnalysisLoading, setAiAnalysisLoading] = useState(false)

  const handleApplyConfiguration = (newConfiguration) => {
    // hide the AI Analysis when the configuration changes
    setShowAiAnalysis(0);
    setAiAnalysisLoading(false);
    setConfiguration(newConfiguration);
    setLoadData(LoadData.Load);
  }

  const handleSaveAll = () => {
    if (generateImage) return;
    showSpinner(true);
    setGenerateImage(true);
    sleep(stimuli.length * 1000).then(() => {
      var zip = new JSZip();
      allimages.forEach(img => {
        zip.file(img.name + ".jpg", img.data, { base64: true });
      });
      zip.generateAsync({ type: "blob" })
        .then(function (content) {
          showSpinner(false);
          const fname = project.name.replace(/[^a-z0-9]/gi, '_');
          saveAs(content, fname + ".zip");
        });

      setAllImages([]);
      setGenerateImage(false);
    });
  }

  const collectImageCallback = (result) => {
    allimages.push({ name: result[0], data: result[1] });
  }

  function filterNodes(node) {
    let hasIgnoreHtmlAttribute =
      node.attributes !== undefined &&
      node.attributes.getNamedItem('data-html2canvas-ignore') !== null &&
      node.attributes.getNamedItem('data-html2canvas-ignore').value === 'true';

    return !hasIgnoreHtmlAttribute;
  }

  const stimulusCardToImage = (cardId, filterPanelId, compareWithPanelId, ignoreFilters, ignoreCompareWith, title, actionType) => {
    const input = document.getElementById(cardId);
    const filterPanel = document.getElementById(filterPanelId);
    const compareWithPanel = document.getElementById(compareWithPanelId);

    if (compareWithPanel) {
      if (ignoreCompareWith) {
        compareWithPanel.setAttribute("data-html2canvas-ignore", "true");
      } else {
        compareWithPanel.removeAttribute("data-html2canvas-ignore");
      }
    }

    if (filterPanel) {
      if (ignoreFilters && filterPanel) {
        filterPanel.setAttribute("data-html2canvas-ignore", "true");
      } else {
        filterPanel.removeAttribute("data-html2canvas-ignore");
      }
    }

    var fname = title.replace(/[^a-z0-9]/gi, '_');

    return domtoimage
      .toJpeg(input, { bgcolor: '#fff', filter: filterNodes })
      .then(function (imgData) {
        switch (actionType) {
          case 'download':
            window.saveAs(imgData, fname + '.jpg');
            break;
          case 'clipboard':
            var img = document.createElement('img');
            img.src = imgData;
            document.body.appendChild(img);
            var r = document.createRange();
            r.setStartBefore(img);
            r.setEndAfter(img);
            r.selectNode(img);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(r);
            document.execCommand('Copy');
            document.body.removeChild(img);
            break;
          default:
            return [fname, imgData.split(',')[1]];
        }
      });
  }

  const getCompareWithOrdering = (stimuli, e) => {
    const result = stimuli.filter(s => s.id > 0).map(s => {
      const name = e.names[s.id] || s.name;
      return { id: s.id, name: name }
    });
    // NOTE: XF20200420 The compare with full total is now disabled
    // result.splice(0, 0, { id: -100, name: 'total' });
    result.splice(0, 0, { id: -200, name: 'totalExcept' });
    return result;
  }

  const handleSaveConfiguration = (e) => {
    showSpinner(false);
    dashboardService.saveConfiguration(projectIdParameter, (e.id > 0) ? e.id : null, stimuli, e).then(result => {
      dashboardService.fetchConfigurations(projectIdParameter, stimuli).then(configs => {
        configs.sort((a, b) => a.name.localeCompare(b.name))       
               .splice(0, 0, getDefaultConfiguration());
        setConfigurations(configs);
        handleApplyConfiguration(findById(configs, result.id));
      }).catch(() => {
        showSpinner(false);
        openSnackbar('error', t("react.dashboard.error.saveconfiguration"));
      });
    }).catch((e) => {
      showSpinner(false);
      openSnackbar('error', t("react.dashboard.error.saveconfiguration"));
    });
  }

  const handleResetConfiguration = (e) => {
    const newConfigurations = [...configurations];
    if (e.id === -1) {
      const newConfiguration = getDefaultConfiguration();
      newConfiguration.id = -1;
      for (let i = 0; i < newConfigurations.length; i++) {
        if (newConfigurations[i].id === newConfiguration.id) {
          newConfigurations[i] = newConfiguration;
          break;
        }
      }
      setConfigurations(newConfigurations);
      handleApplyConfiguration(newConfiguration);
    }
    else {
      showSpinner(true);
      dashboardService.fetchConfigurations(projectIdParameter, stimuli).then(configs => {
        configs.sort((a, b) => a.name.localeCompare(b.name))       
               .splice(0, 0, getDefaultConfiguration());
        setConfigurations(configs);
        handleApplyConfiguration(getDefaultConfiguration());
      }).catch(() => {
        showSpinner(false);
        openSnackbar('error', t("react.dashboard.error.resetconfiguration"));
      });
    }
  }

  const handleChangeConfigurationSettings = (result) => {
    const newConfiguration = { ...result };
    const newConfigurations = [...configurations];
    for (let i = 0; i < newConfigurations.length; i++) {
      if (newConfigurations[i].id === result.id) {
        newConfigurations[i] = newConfiguration;
        break;
      }
    }
    setConfigurations(newConfigurations);
    handleApplyConfiguration(newConfiguration);
  }

  const handleDeleteConfiguration = (e) => {
    if (e.id == null || e.id < 0) return;
    showSpinner(true);
    dashboardService.removeConfiguration(projectIdParameter, e.id).then(() => {
      dashboardService.fetchConfigurations(projectIdParameter, stimuli).then(configs => {
        configs.sort((a, b) => a.name.localeCompare(b.name))       
               .splice(0, 0, getDefaultConfiguration());
        setConfigurations(configs);
        handleApplyConfiguration(getDefaultConfiguration());
      }).catch(() => {
        showSpinner(false);
        openSnackbar('error', t("react.dashboard.error.deleteconfiguration"));
      });
    });
  }

  const handleClickDownloadDashboardDataFile = () => {
    openSnackbar('info', t("react.dashboard.toolbar.button.export.starting"));
    const config = configuration ? { ...configuration } : getDefaultConfiguration();
    dashboardService.downloadDashboardDataFile(projectIdParameter, config)
      .then(response => {
        var regExp = new RegExp('filename="([^"]+)"', 'i');
        let filename = regExp.exec(response.headers['content-disposition'])[1];
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        openSnackbar('success', t("react.dashboard.toolbar.button.export.finished"));
      })
      .catch(err => {
        openSnackbar('error', t("react.dashboard.toolbar.button.export.error"));
      });
  };

  const handleHideCard = (stimulus) => {
    if (stimulus.id > 0) {
      let conf = { ...configuration };
      let index = conf.hidden.indexOf(stimulus.id);
      if (index >= 0) return;
      conf.hidden.push(stimulus.id);
      conf.updated = true;
      setConfiguration(conf);
    }
  };

  const handleShowCard = (stimulus) => {
    const result = { ...configuration };
    if (result && result.hidden) {
      const index = result.hidden.indexOf(stimulus.id);
      if (index === -1) return;
      result.hidden.splice(index, 1);
      result.updated = true;
    }
    setConfiguration(result);
  };

  const handleClone = (stimulus) => {
    // if there is no virtual card define / create empty array
    const newconfiguration = { ...configuration };
    if (!newconfiguration.virtual) newconfiguration.virtual = [];

    // compute next virtualId
    var virtualId = 1;
    if (newconfiguration.virtual.length > 0) {
      // get all existing virtualId in the list
      var virtualIds = newconfiguration.virtual.reduce(function (keys, element) {
        keys.push(element.virtualId);
        return keys;
      }, []);

      // find the biggest virtualId and add 1
      virtualId = Math.max(...virtualIds) + 1;
    }

    // user can create up to 100 virtual cards by configuration
    if (isNaN(virtualId) || virtualId <= 0 || virtualId > 100) {
      openSnackbar('error', t("react.dashboard.toolbar.button.clone.error"));
      return;
    }

    newconfiguration.virtual.push({ virtualId, 'stimulusId': stimulus.id });
    newconfiguration.names[virtualId] = stimulus.name + ' - clone ' + virtualId;
    newconfiguration.updated = true;

    handleApplyConfiguration(newconfiguration);
  };

  const handleRemoveCard = (stimulus) => {
    const newconfiguration = cloneDeep(configuration);

    // remove its specific name
    delete newconfiguration.names[stimulus.id];

    if (stimulus.id === -100) {
      // just hide the total card
      newconfiguration.showTotalCard = false;
      newconfiguration.updated = true;

      // only set the conf, NO NEED TO RELOAD ALL
      setConfiguration(newconfiguration);
      return;
    }

    // delete config entries for this stimulus id
    delete newconfiguration.hidden[stimulus.id];
    delete newconfiguration.filters[stimulus.id];
    delete newconfiguration.engagementFilters[stimulus.id];

    // remove id from the list of hidden cards
    newconfiguration.hidden = removeValueFromArray(newconfiguration.hidden, stimulus.id);

    // find compare with mapping for or with this stimulus.id
    let keysToRemove = Object.entries(newconfiguration.compareWith)
      .filter(([key, value]) => key === String(stimulus.id) || value.id === stimulus.id)
      .map(([key, value]) => key);
    // remove what have matched
    keysToRemove.forEach(e => delete newconfiguration.compareWith[e]);

    // remove this stimulus from virtual if it's or from the list
    if (!stimulus.virtual) {
      newconfiguration.stimulusIds = removeValueFromArray(newconfiguration.stimulusIds, stimulus.id);

      // NOTE: special case is: if there is nos specifed stimulus in the config
      // we'll have all default stimulus, so we have to generate a list without this one.
      if (newconfiguration.stimulusIds.length === 0) {
        newconfiguration.stimulusIds = stimuli.map(s => s.id).filter(sid => sid !== stimulus.id);
      }
    } else {
      let idx = indexOf(newconfiguration.virtual, 'virtualId', stimulus.id);
      if (idx >= 0) newconfiguration.virtual.splice(idx, 1);
    }

    newconfiguration.updated = true;

    handleApplyConfiguration(newconfiguration);
  };

  const handleClickClearProjectCaches = () => {
    showSpinner(true);
    dashboardService.clearProjectCaches(project.id)
      .then(response => {
        openSnackbar('success', 'Les caches ont bien été supprimés');
      })
      .catch(err => {
        openSnackbar('error', 'Une erreur est survenue lors de la demande de nettoyage des caches');
      }).finally(() => {
        showSpinner(false);
      });
  }

  const [openImportVisual, setOpenImportVisual] = useState({ open: false, stimulus: undefined });
  const handleUploadStimulusVisualCallback = (imageToUpload, stimulus) => {
    showSpinner(true);

    projectService.uploadProjectFileInDefaultSequence(project.id, 'miscellaneous', imageToUpload, false)
        .then(res => {
            let temporaryArray = [...stimulusVisuals]
            temporaryArray.push(res.data);
            setStimulusVisuals(temporaryArray);

            // Update stimulus imageId
            projectService.updateStimulusImageId(stimulus.id, res.data.id)
            .then(result => {
              stimuli.filter(s => s.id === stimulus.id)[0].imageId = res.data.id;
              openSnackbar('success', t("react.dashboard.import.image.popup.success"));
            })
            .catch(err => {
              openSnackbar('error', t("react.project.error.import.file"));
            })
            .finally(() => {
              showSpinner(false);
            })
        })
        .catch(err => {
            openSnackbar('error', t("react.project.error.download.file"));
        })
        .finally(() => {
          showSpinner(false);
        })
  };

  const handleSelectStimulusVisualCallback = (imageToUploadId, stimulus) => {
    showSpinner(true);
    projectService.updateStimulusImageId(stimulus.id, imageToUploadId)
    .then(result => {
      stimuli.filter(s => s.id === stimulus.id)[0].imageId = imageToUploadId;
      openSnackbar('success', t("react.dashboard.select.image.popup.success"));
    })
    .catch(err => {
      openSnackbar('error', t("react.project.error.select.file"));
    })
    .finally(() => {
      showSpinner(false);
    })
  }

  const [openConfirmDeleteStimulusVisual, setOpenConfirmDeleteStimulusVisual] = useState(0);
  const handleDeleteStimulusVisual = () => {
    const stimulus = stimuli.find(stimulus => stimulus.id === openConfirmDeleteStimulusVisual);
    setOpenConfirmDeleteStimulusVisual(0);

    showSpinner(true);

    projectService.deleteStimulusImageId(stimulus.id)
      .then(result => {
        openSnackbar('success', t("react.dashboard.dissociate.image.popup.success"));
        stimuli.filter(s => s.id === stimulus.id)[0].imageId = 0;
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.update.file"));
      })
      .finally(() => {
        showSpinner(false);
      })
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    var config = configuration ? { ...configuration } : getDefaultConfiguration();

    var promises = [
      dashboardService.fetchStimuli(projectIdParameter, config),
      projectService.fetchProject(projectIdParameter)
    ];

    if(!AuthService.isVisitor()) {
      promises.push(projectService.fetchImagesInDefaultSequence('miscellaneous', projectIdParameter, 0, 50));
    }

    Promise.all(promises)
      .then(results => {
        setProject(results[1].data);

        if(!AuthService.isVisitor()) {
          setStimulusVisuals(results[2].data.hits);
        }

        // @see DashboardService.convertStimuliResponse()
        let stimuli = results[0][0];
        let filters = results[0][1];
        let tags = results[0][2];
        let sequences = results[0][3];
        let specifications = results[0][4];

        // finally set values
        setTags(tags);
        setFilters(filters);
        setStimuli(stimuli);
        setSequences(sequences);
        setSpecifications(specifications);

        // [R3MSCORE-79] Set display sequence names by default for multi sequence projects
        if (config.id <= 0 /* only new config */ && !config.updated /* never updated */) {
          config.showSequenceName = results[1].data.sequenceMode === 'multi';
        }

        if (!configurations || configurations.length === 0) {
          dashboardService.fetchConfigurations(projectIdParameter, stimuli).then(configs => {
            configs.sort((a, b) => a.name.localeCompare(b.name))       
                   .splice(0, 0, config);
            setConfigurations(configs);
            setConfiguration(config);
            setCompareWiths(getCompareWithOrdering(stimuli, config));
          });
        } else {
          setConfiguration(config);
          setCompareWiths(getCompareWithOrdering(stimuli, config));
        }
      })
      .catch(err => {
        openSnackbar('error', t("react.dashboard.error.while.loading"));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
        showSpinner(false);
      });
  }, [loadData, projectIdParameter, configuration, t, openSnackbar, configurations, showSpinner]);

  /**
   * This useEffect is used to auto load a configuration given as parameter using recoil (e.g. after an automatic generation)
   */
  const [configIdParam, setConfigIdParam] = useRecoilState(configIdParamAtom);
  useEffect(() => {
    // nothing to do while: all data are not loaded, if there is no configurations, if configIdParam is unsed or if already loaded (reset to 0)
    if (loadData !== LoadData.Loaded || configurations.length === 0 || configIdParam === 0) {
      return;
    }

    // search the given config id in the list
    let configurationToLoad = findById(configurations, configIdParam);
    if (configurationToLoad !== undefined) {
      // set the config to load and request new load
      setConfiguration(configurationToLoad);
      setLoadData(LoadData.Load);
    }

    // clear the param
    setConfigIdParam(0);
  }, [loadData, configurations]);

  if (loadData !== LoadData.Loaded || !configuration) return <LinearProgress />;

  return (
    <div className={classes.root}>
      <Helmet title={project.name} />
      <DashboardToolbar
        {...props}
        project={project}
        configurations={configurations}
        configuration={configuration}
        dashboardService={dashboardService}
        handleApplyConfiguration={handleApplyConfiguration}
        handleSaveConfiguration={handleSaveConfiguration}
        handleResetConfiguration={handleResetConfiguration}
        handleDeleteConfiguration={handleDeleteConfiguration}
        handleChangeConfigurationSettings={handleChangeConfigurationSettings}
        setConfiguration={(e) => handleApplyConfiguration(e)}
        handleClickDownloadDashboardDataFile={handleClickDownloadDashboardDataFile}
        filters={filters}
        stimuli={stimuli}
        sequences={sequences}
        compareWiths={compareWiths}
        handleSaveAll={handleSaveAll}
        handleClickClearProjectCaches={handleClickClearProjectCaches}
        setShowAiAnalysis={setShowAiAnalysis}
        showAiAnalysis={showAiAnalysis}
        aiAnalysisLoading={aiAnalysisLoading}
      />
      {
        !configuration.showReportingMode ? <PanelDashboard
          {...props}
          openSnackbar={openSnackbar}
          project={project}
          compareWiths={compareWiths}
          filters={filters}
          stimuli={stimuli}
          tags={tags}
          sequences={sequences}
          specifications={specifications}
          setConfiguration={(e) => setConfiguration(e)}
          applyConfiguration={handleApplyConfiguration}
          configuration={configuration}
          dashboardService={dashboardService}
          handleHideCard={handleHideCard}
          handleShowCard={handleShowCard}
          handleClone={handleClone}
          handleRemoveCard={handleRemoveCard}
          generateImage={generateImage}
          stimulusCardToImage={stimulusCardToImage}
          collectImageCallback={collectImageCallback}
          stimulusVisuals={stimulusVisuals}
          setShowImg={setShowImg}
          setOpenImportVisual={setOpenImportVisual}
          setOpenConfirmDeleteStimulusVisual={setOpenConfirmDeleteStimulusVisual}
          showAiAnalysis={showAiAnalysis}
          setAiAnalysisLoading={setAiAnalysisLoading}
        />
          : <PanelReporting
            {...props}
            openSnackbar={openSnackbar}
            project={project}
            topics={filters}
            compareWiths={compareWiths}
            filters={filters}
            stimuli={stimuli}
            tags={tags}
            sequences={sequences}
            specifications={specifications}
            setConfiguration={(e) => setConfiguration(e)}
            applyConfiguration={handleApplyConfiguration}
            configuration={configuration}
            configurations={configurations}
            dashboardService={dashboardService}
            handleHideCard={handleHideCard}
            handleShowCard={handleShowCard}
            handleClone={handleClone}
            handleRemoveCard={handleRemoveCard}
            generateImage={generateImage}
            stimulusCardToImage={stimulusCardToImage}
            collectImageCallback={collectImageCallback}
          />
      }
      <ImportImageStimulusDialog 
        {...props}
        project={project}
        sequences={sequences}
        stimulusVisuals={stimulusVisuals}
        openImportVisual={openImportVisual}
        setOpenImportVisual={setOpenImportVisual}
        handleSelectStimulusVisualCallback={handleSelectStimulusVisualCallback}
        handleUploadStimulusVisualCallback={handleUploadStimulusVisualCallback}
      />
      <PopupTwoButtons
        variant='question'
        openState={openConfirmDeleteStimulusVisual !== 0}
        callbackOnclose={() => setOpenConfirmDeleteStimulusVisual(0)}
        callbackOnclickLeftButton={() => setOpenConfirmDeleteStimulusVisual(0)}
        callbackOnclickRightButton={handleDeleteStimulusVisual}
        title={t('react.confirm.update.file.title')}
        content={t('react.confirm.update.file.body')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.dissociate')}
      />
      {showImg.show &&
        <Paper className={classes.imgUpload}>
          <Tooltip title={t('react.project.edit.filestab.close.image')} className={classes.closeIconImg}>
            <IconButton onClick={() => setShowImg({show: false, src: ''})} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <img src={showImg.src} style={{ maxWidth: '1024px' }} />
        </Paper>
      }
    </div>
  );
}
