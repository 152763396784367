import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TranslationService from '../../services/TranslationService'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Dropzone from "../shared/Dropzone";

const translationService = new TranslationService();

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dropzoneArea: {
    backgroundColor: '#F0F0F0',
    minWidth: '500px'
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    float: 'right'
  },
}));

export default function ImportExportTranslationsDialog(props) {

  const { t, openSnackbar, onClose, supportedLanguages } = props;

  const { classes } = useStyles();

  const [selected, setSelected] = React.useState('all');
  const [file, setFile] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);

  const handleClickUpload = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("uploadedFile", file);
    setUploading(true);
    translationService.import(formData).then(response => {
      openSnackbar("success", t('react.translationtool.importexportdialog.successuploading', { count: response.data }));
      setUploading(false);
    }).catch(err => {
      setUploading(false);
      openSnackbar("error", t('react.translationtool.importexportdialog.erroruploading'));
      setUploading(false);
    });
  };

  const handleOnChangeInDropzoneArea = (files) => {
    setFile(files[0]);
  };

  const handlechangeLanguage = (event) => {
    event.preventDefault();
    setSelected(event.target.value);
  };

  const handleClickDownload = (event) => {
    event.preventDefault();
    setDownloading(true);
    translationService.export(selected).then(() => {
      setDownloading(false);
    });
  };

  return (
    <Dialog
      open={props.openState}
      onClose={onClose}
    >
      <DialogTitleWithCloseIcon
        title={t('react.translationtool.importexportdialog.title')}
        callbackOnclose={onClose}
      />
      <DialogContent>
        <h2>{t('react.translationtool.importexportdialog.exportbody')}</h2>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Select
              autoFocus
              fullWidth
              value={selected}
              onChange={handlechangeLanguage}
              inputProps={{
                name: 'locale',
                id: 'locale',
              }}
            >
              <MenuItem value="all">all</MenuItem>
              {supportedLanguages.map((language, idx) => (
                <MenuItem key={idx} value={language}>{language}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={9}>
              <Button
                id='downloadbutton'
                title=""
                onClick={handleClickDownload}
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                className={classes.button}
                disabled={downloading}
              >
                {t('react.translationtool.importexportdialog.button.export.title')}
                {downloading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
          </Grid>
        </Grid>
        <br />
        <Divider />
        <h2>{t('react.translationtool.importexportdialog.importbody')}</h2>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Dropzone
              {...props}
              dropzoneText={t('react.translationtool.importexportdialog.droparea.title')}
              acceptedFiles={['sheet']}
              onChange={handleOnChangeInDropzoneArea}
              filesLimit={1}
              showFileNames={false}
              showAlerts={false}
              className={classes.dropzoneArea}
            />
            <Button
              id='uploadbutton'
              title=""
              onClick={handleClickUpload}
              variant="outlined"
              size="large"
              color="primary"
              startIcon={<CloudUploadIcon />}
              className={classes.uploadButton}
              disabled={file === undefined || file.length === 0 || uploading}
            >
              {t('react.translationtool.importexportdialog.button.import.title')}
              {uploading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('react.button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
