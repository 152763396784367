import React, { useState, useEffect } from 'react';
import CollectorsService from '../../services/CollectorsService';
import i18n from 'i18next';
import { isBlank } from '../../utils/utils.js';
import QuestionR3m from './QuestionR3m';
import ProfileQuestion from './ProfileQuestion';
import Introduction from './Introduction';
import ThankYou from './ThankYou';
import Text from './Text';
import HotSpot from './HotSpot';
import BatteryOfItems from './BatteryOfItems';
import OpenQuestion from './OpenQuestion';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {LoadData} from '../../Constants.js';
import { v4 as uuidv4 } from 'uuid';
import { CollectorContextProvider } from './context';
import { replaceDynamicElements, defaultTheme } from '../../utils/surveysUtils';
import CollectorsTestPanel from './CollectorsTestPanel.js';
import { GlobalStyles } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { defaultsDeep } from "lodash";

/*
 * NOTE: This is what the userHistory looks like
 * 
 * export const userHistoryBlockTemplate = {
 *   blockId: 0,               // block.id
 *   elements: []              // array of userHistoryElementTemplate
 * };
 * 
 * export const userHistoryElementTemplate = { 
 *   objectRef: undefined,     // e.g. block.ref, item.ref
 *   answers: [],              // array of userHistoryAnswerTemplate
 *   enteredValue: undefined   // in case of freeField
 * };
 * 
 * export const userHistoryAnswerTemplate = {
 *   id: 0,                    // answer.id
 *   ref: undefined,           // answer.ref
 *   text: undefined,          // answer.text
 *   freeField: false          // answer.freeField
 * };
 * 
 */

const collectorsService = new CollectorsService();

export default function Collectors(props) {

    const {
        t,
        openSnackbar,
        showSpinner,
        providedSurveyId,
        providedParticipantUuid,
        providedQueryParameters,
        testMode
    } = props;

    const [surveyId, setSurveyId] = useState(0);
    const [participantId, setParticipantId] = useState(undefined);
    const [actualBlock, setActualBlock] = useState(-1);
    const [blocksToDisplay, setBlocksToDisplay] = useState([]);

    const [canParticipate, setCanParticipate] = useState(false);
    const [cantParticipateReason, setCantParticipateReason] = useState(undefined);

    const [quotafullExternalUrl, setQuotafullExternalUrl] = useState(undefined);
    const [screenoutExternalUrl, setScreenoutExternalUrl] = useState(undefined);
    const [completedExternalUrl, setCompletedExternalUrl] = useState(undefined);

    const [displayProgressBar, setDisplayProgressBar] = useState(false);

    const [imagesOfForm, setImagesOfForm] = useState(new Map());

    const [conditions, setConditions] = useState([]);

    // NOTE: setUserHistory has specific format and can be retrieved by the API to resume a participation
    const [userHistory, setUserHistory] = useState([]);
    const [resumeParticipationAtStep, setResumeParticipationAtStep] = useState(-1);

    const [globalVariables, setGlobalVariables] = useState([]);

    const [collectorTheme, setCollectorTheme] = useState(defaultTheme);

    const [loadData, setLoadData] = useState(LoadData.Load);
    useEffect(() => {
        if(loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        showSpinner(true);

        collectorsService.getClientForm(providedSurveyId, providedParticipantUuid, providedQueryParameters)
            .then(result => {
                setSurveyId(providedSurveyId);

                if (result.data.lang !== undefined) {
                    i18n.changeLanguage(result.data.lang.toLowerCase());
                }

                if(!result.data.canParticipate) {
                  setCanParticipate(false);
                  setCantParticipateReason(result.data.reason);

                  showSpinner(false);
                  setLoadData(LoadData.Loaded);

                  openSnackbar('error', t('react.error.collectform.forbidden'));

                  return;
                }

                setCanParticipate(true);
                setParticipantId(result.data.participantId);

                setQuotafullExternalUrl(collectorsService.getParameterizedExternalUrl(providedQueryParameters, result.data.quotafullExternalUrl));
                setScreenoutExternalUrl(collectorsService.getParameterizedExternalUrl(providedQueryParameters, result.data.screenoutExternalUrl));
                setCompletedExternalUrl(collectorsService.getParameterizedExternalUrl(providedQueryParameters, result.data.completedExternalUrl));

                setDisplayProgressBar(result.data.displayProgressBar);

                setBlocksToDisplay(result.data.steps);

                // this will push special translations into the defaut system.
                // this way it's possible to use t('my.special.key')
                i18n.addResourceBundle(result.data.lang, 'translation', result.data.translations, true, true);

                setConditions(result.data.conditions);
                setGlobalVariables(result.data.variables);

                setCollectorTheme(result.data.theme ? defaultsDeep(result.data.theme, defaultTheme) : defaultTheme);

                // This code is to determine if the participant is coming back to finish
                if(result.data.lastAnsweredBlockId > 0) {
                  // in this case we have to resume at the appropriated step
                  const lastStepIdx = result.data.steps.findIndex(step => step.id === result.data.lastAnsweredBlockId);
                  if(lastStepIdx >= 0 /* he can have answered to the only first question bloc */) {
                    // NOTE: be carefull it's very hot/important piece of code
                    setActualBlock(lastStepIdx);
                    setResumeParticipationAtStep(lastStepIdx);
                    setUserHistory(result.data.ongoingHistory);

                    // NOTE: in this case, do not stop the spinner nor set LoadData.Loaded, defer to another useEffect below
                    return;
                  } else {
                    // the last participated block was not found - we reject the participant
                    // reason can be: I had been assigned by a pairing block and it's not possible to regenerate the frame
                    setCanParticipate(false);
                    setCantParticipateReason(undefined);
                  }
                }

                setActualBlock(0);
                showSpinner(false);
                setLoadData(LoadData.Loaded);
            }).catch(e => {
                setSurveyId(0);
                setCanParticipate(false);
                setCantParticipateReason(undefined);
                showSpinner(false);
                setLoadData(LoadData.Loaded);

                if (e.response && e.response.status && e.response.status === 403) {
                  openSnackbar('error', t('react.error.collectform.forbidden'));
                } else {
                  openSnackbar('error', t('react.error.fetch.message'));
                }
            });
    }, [providedSurveyId, providedParticipantUuid, providedQueryParameters]);

    // -- /!\ Function to deal with the history ---------------------

    useEffect(() => {
      if(actualBlock < 0 || actualBlock >= blocksToDisplay.length) return;

      if(resumeParticipationAtStep >= 0) {
        // we are restauring the history, don't touch it
        return;
      }

      // cleanup positions that are after the actualBlock (caused by navigation)
      for(var i = userHistory.length - 1; i >= 0; i--) {
        let blockIdx = blocksToDisplay.findIndex(b => b.id === userHistory[i].blockId);
        if(blockIdx === -1 || blockIdx > actualBlock) {
          userHistory.splice(i, 1);
        }
      }

      // this is used fill the user history
      // so that answers can be directly written into the history (@see pushAnswersToUserHistory())
      var blockId = blocksToDisplay[actualBlock].id;
      var blockHistoryIdx = userHistory.findIndex(it => it.blockId === blockId);
      if(blockHistoryIdx === -1) {
        let userHistoryEntry = {
          blockId: blockId,
          elements: []
        };
        userHistory.push(userHistoryEntry);
      } else {
        // reset previously given data
        userHistory[blockHistoryIdx].elements = [];
      }
    }, [actualBlock /* everytime the actualBlock change */]);

    // ---------------------------------------------------------------

    // -- /!\ Function to resume a participation ---------------------

    useEffect(() => {
      if(resumeParticipationAtStep === -1) return;

      // resume only one time
      setResumeParticipationAtStep(-1);

      // need to be call here to verify conditions, etc...
      async function waitResumeLoad() {
        await handleNextBlock();
        showSpinner(false);
        setLoadData(LoadData.Loaded);
      }
      waitResumeLoad();
    }, [resumeParticipationAtStep]);

    // ---------------------------------------------------------------

    const hasConditions = (block) => {
      // check if if any of the conditionedUuids (overall conditions) belongs to the block
      const allConditionedUuids = conditions
        .map(c => c.conditionedUuids)
        .flat();

      if(allConditionedUuids.includes(block.uuid)) return true;

      var isConditionned = false;
      if(block.type === 'question') {
        isConditionned = block.profile.answers.findIndex(a => allConditionedUuids.includes(a.uuid)) >= 0;
      } else if(block.type === 'hotspot') {
        isConditionned = block.hotspot.zones.findIndex(z => allConditionedUuids.includes(z.uuid)) >= 0 ||
          block.hotspot.answers.findIndex(a => allConditionedUuids.includes(a.uuid)) >= 0;
      }else if(block.type === 'battery') {
        isConditionned = block.battery.items.findIndex(i => allConditionedUuids.includes(i.uuid)) >= 0 ||
          block.battery.answers.findIndex(a => allConditionedUuids.includes(a.uuid)) >= 0;
      }

      return isConditionned;
    };

    /**
     * Call this function after each save(), to deal with nextBlock to display
     * and to trigger the good state of the answer.
     *
     **/
    const handleNextBlock = async () => {
      showSpinner(true);
      window.scrollTo({top: 0});

      // default next block is the following one
      var nextBlockIndex = actualBlock + 1;

      if(blocksToDisplay[nextBlockIndex] !== undefined) {
        var loopSecurity = 500; // if 500 blocks are tested, there is a problem

        // check if the next block is controled by conditions
        // is there some conditions to check ?
        var nextBlockHasConditions = hasConditions(blocksToDisplay[nextBlockIndex]);
        while(nextBlockHasConditions) {
          if(--loopSecurity === 0) break;

          // check if this block is accessible
          // call the backend to control because we need to have all user answers vs conditions

          var isAllowedByConditions = false;
          await collectorsService.checkBlockConditionsAgainstParticipant(participantId, surveyId, blocksToDisplay[nextBlockIndex].uuid)
            .then(result => {
              isAllowedByConditions = result.data.isAllowed;
              if(isAllowedByConditions) {
                if(blocksToDisplay[nextBlockIndex].type === 'question') {
                  // when a block has conditions and if it's a 'Question' (profiling), we have to verify avaibility of answers
                  let allowedAnswers = blocksToDisplay[nextBlockIndex].profile.answers.filter(a => result.data.allowedItemsUuids.indexOf(a.uuid) >= 0);
                  blocksToDisplay[nextBlockIndex].profile.answers = allowedAnswers;
                } else if(blocksToDisplay[nextBlockIndex].type === 'hotspot') {
                  // when a block has conditions and if it's a 'hotspot', we have to verify avaibility of zones & answers
                  let allowedZones = blocksToDisplay[nextBlockIndex].hotspot.zones.filter(z => result.data.allowedItemsUuids.indexOf(z.uuid) >= 0);
                  let allowedAnswers = blocksToDisplay[nextBlockIndex].hotspot.answers.filter(a => result.data.allowedItemsUuids.indexOf(a.uuid) >= 0);
                  blocksToDisplay[nextBlockIndex].hotspot.zones = allowedZones;
                  blocksToDisplay[nextBlockIndex].hotspot.answers = allowedAnswers;
                } else if(blocksToDisplay[nextBlockIndex].type === 'battery') {
                  // when a block has conditions and if it's a 'battery', we have to verify avaibility of items & answers
                  let allowedItems = blocksToDisplay[nextBlockIndex].battery.items.filter(i => result.data.allowedItemsUuids.indexOf(i.uuid) >= 0);
                  let allowedAnswers = blocksToDisplay[nextBlockIndex].battery.answers.filter(a => result.data.allowedItemsUuids.indexOf(a.uuid) >= 0);
                  blocksToDisplay[nextBlockIndex].battery.items = allowedItems;
                  blocksToDisplay[nextBlockIndex].battery.answers = allowedAnswers;
                }
              }
            });

          if(isAllowedByConditions) {
            // it's ok, he can access to the next block
            break;
          }

          // else try with the next one
          nextBlockIndex++;

          if(nextBlockIndex >= blocksToDisplay.length) {
            // we are after the end of the form
            break;
          }

          // get conditions of the following block
          nextBlockHasConditions = hasConditions(blocksToDisplay[nextBlockIndex]);
        }
      }
      // ------------------------------------------------------------------

      // we can mark the form as completed for this participant when:
      // - the provided (or computed) nextBlockIndex doesn't exist
      // - the next block is a 'thankyou' block

      if (nextBlockIndex < 0 || nextBlockIndex >= blocksToDisplay.length || blocksToDisplay[nextBlockIndex].type === 'thankyou') {
        let finalState = 'completed';
        await collectorsService.updateCompletionState(participantId, surveyId, finalState);

        // if the project has partner urls, redirect to URL
        // completedExternalUrl: "https://s.cint.com/Survey/Complete?ProjectToken=1a55f5a0-8e1c-4c69-9a88-6bb0d4bf7b31"
        if(!isBlank(completedExternalUrl)) {
          showSpinner(false);
          window.location.replace(completedExternalUrl);
          return;
        }
      } else if(blocksToDisplay[nextBlockIndex].type === 'pairing') {
        // if next block is a 'pairing', we have to get the assignment
        await collectorsService.getParticipantAssignment(participantId, surveyId)
          .then(result => {
            if(result.data.canParticipate) {
              setCanParticipate(true);

              let newBlocksToDisplay = blocksToDisplay
                  // take blocks before the pairing block
                  .slice(0, nextBlockIndex)
                  // add incoming blocks
                  .concat(result.data.steps)
                  // add blocks that where after the pairing block
                  .concat(blocksToDisplay.slice(nextBlockIndex + 1));
              setBlocksToDisplay(newBlocksToDisplay);

              // it's OK, let's continue to line below: setActualBlock(nextBlockIndex);
            } else {
              // stop here
              showSpinner(false);
              handleScreenOut();
              return;
            }
          }).catch(error => {
            showSpinner(false);

            if (error.response?.status === 302) {
              handleQuotaFull();
            } else if (error.response?.status === 307) {
              handleScreenOut();
            } else {
              openSnackbar('error', t('react.error.save.message'));
              handleScreenOut();
            }

            // stop here
            return;
          });
      }

      setActualBlock(nextBlockIndex);
      showSpinner(false);
    };

    const handleQuotaFull = async () => {
      window.scrollTo({top: 0});

      // default is: go to nowhere
      let nextBlockIndex = -1;

      let quotafullMessage = i18n.exists('LBL.QUOTAFULL') ? t('LBL.QUOTAFULL') : '';
      if(!isBlank(quotafullMessage)) {
        // create a fake block with the quotafullMessage
        const qfBlock = {id: 0, text: `<p>${quotafullMessage}</p>`, type: 'thankyou', imageId: 0, openImageFullscreen: false, groupId: 0, uuid: uuidv4()};

        let newBlocksToDisplay = [...blocksToDisplay];
        newBlocksToDisplay.push(qfBlock);
        setBlocksToDisplay(newBlocksToDisplay);

        nextBlockIndex = newBlocksToDisplay.length - 1;
      } else {
        // when QuotaFull, jump to the end (if it's a ThankYou block)
        let lastBlock = blocksToDisplay[blocksToDisplay.length - 1];
        if (lastBlock.type === 'thankyou') {
          // check conditions of the last block (even if it's a ThankYou block)
          var lastBlockHasConditions = hasConditions(lastBlock);

          if(!lastBlockHasConditions) {
            // there is no condition, we can display the thankyou block
            nextBlockIndex = blocksToDisplay.length - 1;
          } else {
            var isAllowedByConditions = false;
            await collectorsService.checkBlockConditionsAgainstParticipant(participantId, surveyId, lastBlock.uuid)
              .then(result => {
                isAllowedByConditions = result.data.isAllowed;
              });
    
            if(isAllowedByConditions) {
              // it's ok, he can access to the next block
              nextBlockIndex = blocksToDisplay.length - 1;
            }
          }
        }
      }

      // notify the state of the end
      await collectorsService.updateCompletionState(participantId, surveyId, 'quotaFull');

      // if the project has partner urls, redirect to URL
      // quotafullExternalUrl: "https://s.cint.com/Survey/QuotaFull?ProjectToken=591e5493-af45-b6f9-db45-e7643b33d0e4"
      if(!isBlank(quotafullExternalUrl)) {
        window.location.replace(quotafullExternalUrl);
        return;
      }

      setActualBlock(nextBlockIndex);
    };

    const handleScreenOut = async () => {
      window.scrollTo({top: 0});

      // default is: go to nowhere
      let nextBlockIndex = -1;

      let screenoutMessage = i18n.exists('LBL.SCREENOUT') ? t('LBL.SCREENOUT') : '';
      if(!isBlank(screenoutMessage)) {
        // create a fake block with the screenoutMessage
        const soBlock = {id: 0, text: `<p>${screenoutMessage}</p>`, type: 'thankyou', imageId: 0, openImageFullscreen: false, groupId: 0, uuid: uuidv4()};

        let newBlocksToDisplay = [...blocksToDisplay];
        newBlocksToDisplay.push(soBlock);
        setBlocksToDisplay(newBlocksToDisplay);

        nextBlockIndex = newBlocksToDisplay.length - 1;
      } else {
        // when ScreenOut, jump to the end (if it's a ThankYou block)
        let lastBlock = blocksToDisplay[blocksToDisplay.length - 1];
        if (lastBlock.type === 'thankyou') {
          // check conditions of the last block (even if it's a ThankYou block)
          var lastBlockHasConditions = hasConditions(lastBlock);

          if(!lastBlockHasConditions) {
            // there is no condition, we can display the thankyou block
            nextBlockIndex = blocksToDisplay.length - 1;
          } else {
            var isAllowedByConditions = false;
            await collectorsService.checkBlockConditionsAgainstParticipant(participantId, surveyId, lastBlock.uuid)
              .then(result => {
                isAllowedByConditions = result.data.isAllowed;
              });
    
            if(isAllowedByConditions) {
              // it's ok, he can access to the next block
              nextBlockIndex = blocksToDisplay.length - 1;
            }
          }
        }
      }

      // notify the state of the end
      await collectorsService.updateCompletionState(participantId, surveyId, 'screenOut');

      // if the project has partner urls, redirect to URL
      // screenoutExternalUrl: "https://s.cint.com/Survey/EarlyScreenOut?ProjectToken=591e5493-af45-b6f9-db45-e7643b33d0e4"
      if(!isBlank(screenoutExternalUrl)) {
        window.location.replace(screenoutExternalUrl);
        return;
      }

      setActualBlock(nextBlockIndex);
    };

    // wait for data to be loaded
    if(loadData !== LoadData.Loaded) {
      return null;
    }

    // data are loaded but user is not allowed to participate
    if(!canParticipate) {
      return (<Box>{!isBlank(cantParticipateReason) ? cantParticipateReason : t('react.error.collectform.forbidden.message')}</Box>);
    }

    // no way to the expected block
    if(actualBlock < 0 || actualBlock >= blocksToDisplay.length) {
      return null;
    }

    return (<>
      <GlobalStyles
        styles={() => ({
          body: { backgroundColor: collectorTheme.backgroundColor },
          /* CSS properties for the MainPaper */
          "#mainPaper01": {
            backgroundColor: collectorTheme.paper.backgroundColor,
            paddingTop: collectorTheme.paper.paddingTop,
            paddingBottom: collectorTheme.paper.paddingBottom,
            minHeight: collectorTheme.paper.minHeight,
          },
          /* CSS properties for the CustomNextButton */
          "#mainCustomNextButton01": {
            backgroundColor: collectorTheme.button.backgroundColor,
            color: collectorTheme.button.color,
            "&:hover, &:focus": {
              backgroundColor: collectorTheme.button.hoverBackgroundColor,
            },
            "&.Mui-disabled": {
              backgroundColor: collectorTheme.button.disabledBackgroundColor,
            }
          },
          /* CSS properties for the button styled with outlined */
          ".MuiButton-outlined": {
            backgroundColor: collectorTheme.button.backgroundColor,
            color: collectorTheme.button.color,
            "&:hover, &:focus": {
              backgroundColor: collectorTheme.button.hoverBackgroundColor,
            },
            "&.Mui-disabled": {
              backgroundColor: collectorTheme.button.disabledBackgroundColor,
            }
          },
          /* CSS properties for the button styled with contained */
          ".MuiButton-contained": {
            backgroundColor: collectorTheme.button.hoverBackgroundColor,
            color: collectorTheme.button.color,
            "&.Mui-disabled": {
              backgroundColor: collectorTheme.button.disabledBackgroundColor,
            }
          },
          ".MuiRadio-root": {
            color: collectorTheme.radio.color
          },
          ".MuiCheckbox-root": {
            color: collectorTheme.checkbox.color
          },
          ".MuiStepLabel-iconContainer.Mui-active > .MuiSvgIcon-root": {
            color: collectorTheme.stepper.color
          },
          ".MuiLinearProgress-root": {
            backgroundColor: collectorTheme.progress.backgroundColor,
          },
          ".MuiLinearProgress-root > .MuiLinearProgress-bar": {
            backgroundColor: collectorTheme.progress.color,
          },
        })}
      />
      <CollectorContextProvider values={{
        collectParameter:surveyId, collectorsService, participantId, imagesOfForm, blocksToDisplay, actualBlock,
        handleNextBlock, handleScreenOut, handleQuotaFull, userHistory, 
        embeddedReplaceDynamicElements:(txt) => { return replaceDynamicElements(txt, userHistory, globalVariables, blocksToDisplay, providedQueryParameters); }
      }}>
      <Paper id='mainPaper01' elevation={collectorTheme.paper.elevation}>

        {displayProgressBar && 
          <LinearProgress color={"primary"} style={{margin: '0px 24px 10px 24px'}} variant="determinate" value={Math.round(actualBlock * 100 / (blocksToDisplay.length - 1))} />
        }

        {blocksToDisplay[actualBlock].type === "introduction" &&
            <Introduction
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "question" &&
            <ProfileQuestion
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "experience" &&
            <QuestionR3m
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "openQuestion" &&
            <OpenQuestion
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "thankyou" &&
            <ThankYou
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "text" &&
            <Text
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "hotspot" &&
            <HotSpot
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {blocksToDisplay[actualBlock].type === "battery" &&
            <BatteryOfItems
                {...props}
                block={blocksToDisplay[actualBlock]}
            />
        }
        {testMode && 
          <CollectorsTestPanel
            {...props}
            setActualBlock={setActualBlock}
            setBlocksToDisplay={setBlocksToDisplay}
            block={blocksToDisplay[actualBlock]}
          />
        }
      </Paper>
      </CollectorContextProvider>
      </>
    );
};