import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import dateFormat from "dateformat";
import { isBlank } from "../../../utils/utils";
import DialogTitleWithCloseIcon from "../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon";
import PromptsService from "../../../services/PromptsService";
import MenuItem from "@mui/material/MenuItem";
import ISO6391 from "iso-639-1";
import ThematizationService from "../../../services/ThematizationService";
import {LoadData} from "../../../Constants";
import {AuthService} from "../../../services/AuthService";
import CircularProgress from "@mui/material/CircularProgress";

const promptsService = new PromptsService();
const thematizationService = new ThematizationService();

export default function EditThematizationDialog(props) {
	const {t, openState, editThematization, callbackOnclose, openSnackbar, setLoadData} = props;

	const [availableLanguages, setAvailableLanguages] = useState([]);

	const [name, setName] = useState("");
	const [lang, setLang] = useState("");
	const [description, setDescription] = useState("");
	const [creationDate, setCreationDate] = useState(new Date());
	const [themes, setThemes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!openState) return;

		setIsLoading(true);

		setAvailableLanguages([]);

		setName("");
		setLang("");
		setDescription("");
		setCreationDate(new Date());
		setThemes([]);

		let promises = [
			promptsService.fetchAllPromptsByType('review/to-thematization'),
		];

		if (editThematization?.id > 0) {
			promises.push(thematizationService.getThematization(editThematization.id));
		}

		Promise.all(promises)
			.then(results => {
				setAvailableLanguages(results[0].data.hits.map(p => p.lang).sort());

				if (editThematization?.id > 0) {
					setName(results[1].data.name);
					setLang(results[1].data.lang);
					setDescription(results[1].data.description || "");
					setCreationDate(results[1].data.creationDate);
					setThemes(results[1].data.themes || []);
				}
			})
			.catch(err => {
				setAvailableLanguages([]);
				openSnackbar("error", t("react.codification.error.while.loading"));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [editThematization, openState]);

	const handleConfirm = () => {
		let payload = {
			id: editThematization?.id || 0,
			name: name,
			userId: editThematization?.userId || AuthService.getUser().id,
			lang: lang,
			description: description,
			themes: themes || [],
			creationDate: creationDate,
			updateDate: new Date(),
		}

		setLoadData(LoadData.Loading);
		thematizationService.saveThematization(payload)
			.then(response => {
			})
			.catch(err => {
				openSnackbar('error', t("react.aitools.thematization.load.error"));
			}).finally(() => {
			setLoadData(LoadData.Load);
		});

		callbackOnclose();
	};

	if (!openState) return null;

	return (
		<Dialog open={openState} onClose={callbackOnclose}>
			{isLoading ? (
				<DialogContent>
					<CircularProgress />
				</DialogContent>
			) : (
				<>
					{editThematization?.id > 0 ? (
						<DialogTitleWithCloseIcon
							title={t("react.aitools.thematization.edit.title")}
							callbackOnclose={callbackOnclose}
						/>
					) : (
						<DialogTitleWithCloseIcon
							title={t("react.aitools.thematization.add.title")}
							callbackOnclose={callbackOnclose}
						/>
					)}
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<DialogContent>
								<TextField
									autoFocus
									margin="none"
									id="name"
									name="name"
									label={t("react.customeredit.name")}
									type="text"
									value={name}
									autoComplete="off"
									onChange={(e) => setName(e.target.value)}
									fullWidth
								/>
								<TextField
									margin="normal"
									id="description"
									label={t("react.customeredit.description")}
									type="text"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									fullWidth
									InputLabelProps={{shrink: true}}
								/>
								<TextField
									select
									fullWidth
									margin="normal"
									label={t("react.aitools.thematization.lang")}
									value={lang}
									onChange={(e) => setLang(e.target.value)}
									helperText={t("react.aitools.thematization.edit.selectlanguage")}
								>
									{availableLanguages.map((language) => (
										<MenuItem key={language} value={language}>
											{ISO6391.getNativeName(language.toLowerCase())}
										</MenuItem>
									))}
								</TextField>
								<TextField
									fullWidth
									margin="normal"
									id="creationDate"
									label={t("react.customeredit.creationdate")}
									type="datetime-local"
									value={dateFormat(creationDate, "UTC:yyyy-mm-dd'T'HH:MM")}
									disabled={true}
									InputLabelProps={{shrink: true}}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									variant="outlined"
									size="large"
									startIcon={<CloseIcon/>}
									onClick={callbackOnclose}
									color="primary"
								>
									{t("react.button.cancel")}
								</Button>
								<Button
									variant="outlined"
									size="large"
									startIcon={<SaveIcon/>}
									disabled={isBlank(name) || isBlank(lang)}
									color="primary"
									onClick={handleConfirm}
								>
									{t("react.button.save")}
								</Button>
							</DialogActions>
						</Grid>
					</Grid>
				</>
			)}
		</Dialog>
	);
}
