import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import Image from './Image';
import CollectorsTestRef from './CollectorsTestRef';
import CustomNextButton from './CustomNextButton';
import { isBlank } from '../../utils/utils';
import { CollectorContext, CollectorTestContext } from './context';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { trim } from 'lodash';

const useStyles = makeStyles()(theme => ({
    positionRelative: {
        position: 'relative'
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
    },
    flexBaseline: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '10px'
    },
    typographyTextHelper: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '400',
        marginLeft: '14px',
        position: 'absolute'
    },
    textFieldAnswers: {
        marginTop: '20px'
    }
}));

export default function OpenQuestion(props) {

    const { classes } = useStyles();

    const { 
        t, showSpinner, openSnackbar, block
    } = props;

    const { 
        collectParameter, collectorsService, handleNextBlock, embeddedReplaceDynamicElements,
        handleScreenOut, participantId, imagesOfForm, handleQuotaFull, userHistory
    } = useContext(CollectorContext);

    // get the TEST context
    const _testContext_ = useContext(CollectorTestContext);

    const [answer, setAnswer] = useState(Array(block.openQuestion.nbTextFields).fill(''));

    const [openQuestionImage, setOpenQuestionImage] = useState(undefined);

    const [dataReady, setDataReady] = useState(false);
    const [imageReady, setImageReady] = useState(false);

    useEffect(() => {
        setDataReady(false);
        if (!block) return;

        // always reset all fields when a new bloc comes
        setAnswer(Array(block.openQuestion.nbTextFields).fill(''));

        setDataReady(true);
    }, [block])

    /*
     * This useEffect() is only used to control the display of the image
     */
    useEffect(() => {
        setImageReady(false);

        if (!block || block.imageId === 0) {
            setOpenQuestionImage(undefined);
            setImageReady(true);
            return;
        }

        let img = imagesOfForm.get(block.imageId);
        if(img !== undefined) {
            setOpenQuestionImage(img);
            setImageReady(true);
            return;
        }

        showSpinner(true);

        collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
            .then(result => {
                imagesOfForm.set(block.imageId, result.url);
                setOpenQuestionImage(result.url);
                setImageReady(true);
                showSpinner(false);
            }).catch(e => {
                setOpenQuestionImage(undefined);
                setImageReady(false);
                showSpinner(false);
                openSnackbar('error', t('react.error.fetch.message'));
                handleScreenOut();
            });
    }, [block]);

    const saveBlock = async () => {
        showSpinner(true);

        await collectorsService.collectOpenQuestionAnswer(participantId, block.id, answer.join(' '))
            .then(response => {
                setDataReady(false);
                showSpinner(false);
                pushAnswersToUserHistory();
                handleNextBlock();
            }).catch(error => {
                setDataReady(false);
                showSpinner(false);
                if (error.response?.status === 302) {
                    handleQuotaFull();
                } else if (error.response?.status === 307) {
                    handleScreenOut();
                } else {
                    openSnackbar('error', t('react.error.save.message'));
                    handleScreenOut();
                }
            });
    };

    const pushAnswersToUserHistory = () => {
        // append user answers of the openQuestion to the user answers list for text replacement
        const answers = [{ id: 0, ref: undefined, text: answer.join(' '), freeField: false }];
        const userAnswerToAdd = { objectRef: block.ref, answers: answers, enteredValue: undefined };
        const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);
        userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
    };

    /**
     * IMPORTANT NOTE: IT IS IMPORTANT TO UPDATE SUR setAutomaticAnsweringCallback() BECAUSE IF YOU NEED
     * TO ACCESS OTHER STATES OF THE COMPONENT, THE CALLBACK MUST BE UPDATED TOO (EVEN IF IT'S A FUNCTION!)
     */
    useEffect(() => {
        if(_testContext_) {
        // if we are in test mode, we have a testContext - replace the automatic answering system
        _testContext_.setAutomaticAnsweringCallback(() => _test_automaticallyAnswerToQuestion_);
        }
    }, [block /* add more states you need to monitor in _test_automaticallyAnswerToQuestion_() */]);

    const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.';
    const _test_automaticallyAnswerToQuestion_ = () => {
        setAnswer(Array(block.openQuestion.nbTextFields).fill(loremIpsum));
    };

    const getTextFieldsAnswer = (index, text) => {
        let temporaryArray = [...answer]
        temporaryArray[index] = text
        setAnswer(temporaryArray)
    };

    const hasMinimumNumberOfCharacters = () => {
        if (block.openQuestion.minimumNumberOfCharacters <= 0) {
            return true;
        }

        if (block.openQuestion.minimumCharactersType === "firstZone") {
            return trim(answer[0]).length >= block.openQuestion.minimumNumberOfCharacters;
        } else if (block.openQuestion.minimumCharactersType === "eachZone") {
            return answer.every(a => trim(a).length >= block.openQuestion.minimumNumberOfCharacters);
        }

        let filledArea = answer.filter(a => !isBlank(a));
        if (filledArea.length === 0) return false;

        let numberOfCharacters = filledArea.map(a => trim(a).length).reduce((acc, cur) => acc + cur);
        return numberOfCharacters >= block.openQuestion.minimumNumberOfCharacters;
    };

    const defaultLabelKeyByIndex = [
        'LBL.OQ.FIRST.ANSWER', 
        'LBL.OQ.SECOND.ANSWER', 
        'LBL.OQ.THIRD.ANSWER', 
        'LBL.OQ.FOURTH.ANSWER', 
        'LBL.OQ.FIFTH.ANSWER'
    ];

    const getLabel = (index) => {
        if (block.openQuestion.zonesLabels && block.openQuestion.zonesLabels[index]) {
            return block.openQuestion.zonesLabels[index];
        } else if(block.nbTextFields === 1) {
            return t('LBL.OQ.ANSWER');
        } else {
            return t(defaultLabelKeyByIndex[index]);
        }
    };

    const isTextFieldRequired = (indexAnswer) => {
        const state = block.openQuestion.minimumCharactersType;
        const minChars = block.openQuestion.minimumNumberOfCharacters;
        if (minChars <= 0) return false;

        if (state === "firstZone") {
            return indexAnswer === 0;
        } else if (state === "eachZone") {
            return true;
        }
    };

    const displayTextFieldTextHelper = (indexAnswer) => {
        const state = block.openQuestion.minimumCharactersType;
        const minChars = block.openQuestion.minimumNumberOfCharacters;
        if (minChars <= 0) return undefined;

        const conditionsToDisplayTextHelper = (state === "firstZone" && indexAnswer === 0) || state === "eachZone";
        return conditionsToDisplayTextHelper ? t('LBL.OQ.MINIMUM.CHARACTERS', { number: minChars }) : undefined;
    };

    const displayGeneralTextHelper = () => {
        return block.openQuestion.minimumCharactersType === "total" && block.openQuestion.minimumNumberOfCharacters > 0;
    };

    if(!dataReady || !imageReady) return null;

    return (
        <Container className={classes.positionRelative}>
            <Grid container spacing={4}>
                {openQuestionImage !== undefined &&
                    <Grid item xs={12}>
                        <Image
                            imageUrl={openQuestionImage}
                            position={block.imagePosition}
                            width={block.imageWidth}
                            openFullscreen={block.openImageFullscreen}
                        />
                    </Grid>
                }
                <Grid item sm={12} md={block.image !== undefined ? 8 : 12}>
                    {block.openQuestion.question && <Box className={classes.flexBaseline}>
                        <CollectorsTestRef {...props} displayType={"typography"} refToDisplay={block.ref}/>
                        <div className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.openQuestion.question) }} />
                    </Box>}
                    {answer.map((answer, index) =>
                        <TextField
                            autoFocus={index === 0}
                            value={answer}
                            onChangeCapture={(e) => getTextFieldsAnswer(index, e.target.value)}
                            inputProps={{ maxLength: '1024' }}
                            required={isTextFieldRequired(index)}
                            multiline
                            minRows={block.openQuestion.nbTextFieldRows}
                            margin="dense"
                            label={getLabel(index)}
                            autoComplete='off'
                            className={classes.textFieldAnswers}
                            type='text'
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.input,
                                }
                            }}
                            key={index}
                            helperText={displayTextFieldTextHelper(index)}
                        />
                    )}
                </Grid>
            </Grid>
            {displayGeneralTextHelper() && <Typography className={classes.typographyTextHelper}>{t('LBL.OQ.MINIMUM.CHARACTERS.GLOBAL', { number: block.openQuestion.minimumNumberOfCharacters })}</Typography>}
            <CustomNextButton
                {...props}
                disabled={!hasMinimumNumberOfCharacters()}
                handleNextBlock={saveBlock}
            />
        </Container>
    )
};
